import https from "https";

const getData = async (url, params) => {
  try {
    const response = await fetch(url, {
      params: { ...params },
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });
    const { data } = response;
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getDataTimeout = async (url, params, timeout = 5000) => {
  const response = await fetch(url, {
    params: { ...params },
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    signal: AbortSignal.timeout(timeout),
  });
  return response.json();
};

export default getData;
