import React from "react";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../change-language/ChangeLanguage";
import { SNS_URL } from "../LandingLayout";
import classNames from "classnames/bind";
import style from "./CommonFooter.module.scss";

import { ReactComponent as IconFooterLogo } from "../../assets/images/core/40wonders.svg";
import { ReactComponent as IconWemix } from "../../assets/images/core/wemix_text.svg";
import { ReactComponent as IconMedium } from "../../assets/images/core/medium.svg";
import { ReactComponent as IconTelegram } from "../../assets/images/core/telegram.svg";
import { ReactComponent as IconX } from "../../assets/images/core/x.svg";
import { ReactComponent as IconYoutube } from "../../assets/images/core/youtube.svg";
import { ReactComponent as IconFacebook } from "../../assets/images/core/facebook.svg";

const cn = classNames.bind(style);

const CommonFooter = ({ theme = "dark", language = true }) => {
  const { t, i18n } = useTranslation();
  // SNS 바로가기 링크
  const SNS_LINKS = [
    {
      name: "wemix_text",
      link: SNS_URL.WEMIX,
      title: "WEMIX",
      logo: <IconWemix />,
    },
    {
      name: "medium",
      link: SNS_URL.MEDIUM,
      title: "medium",
      logo: <IconMedium />,
    },
    {
      name: "telegram",
      link: SNS_URL.TELEGRAM,
      title: "telegram",
      logo: <IconTelegram />,
    },
    {
      name: "x",
      link: SNS_URL.X,
      title: "x (twitter)",
      logo: <IconX />,
    },
    {
      name: "youtube",
      link: SNS_URL.YOUTUBE,
      title: "youtube",
      logo: <IconYoutube />,
    },
    {
      name: "facebook",
      link: SNS_URL.FACEBOOK,
      title: "facebook",
      logo: <IconFacebook />,
    },
  ];

  // 상단 우측 (PC 기준) 바로가기 링크
  const QUICK_LINKS = [
    {
      name: t("core.footer.menu.contactUs"),
      link: "mailto:hi_there@wemix.com",
      target: "_blank",
    },
    {
      name: i18n.language === "ko" ? "WONDERS 참여" : "Become a WONDER",
      link: "https://docs.wemix.com/v/ko/design/governance#off-chain-governance",
      target: "_blank",
    },
  ];

  // 약관 바로가기 링크
  const TERMS_LINKS = [
    {
      name: t("core.footer.link.terms"),
      link: "/policy/terms",
    },
    {
      name: t("core.footer.link.privacy"),
      link: "/policy/privacy",
    },
    {
      name: t("core.footer.link.cookie"),
      link: "/policy/cookie-policy",
    },
    {
      name: t("core.footer.link.california"),
      link: "/policy/california-privacy",
    },
  ];
  return (
    <footer className={cn("footer", theme)}>
      <div className={cn("footer-inner")}>
        <div className={cn("title-area")}>
          {/* 플렛폼 로고 */}
          <a href="/" className={cn("home")}>
            <IconFooterLogo />
            {/* <img
              src={`${process.env.REACT_APP_URL}/assets/core/images/icon/40wonders.svg`}
            /> */}
            <span className={cn("a11y")}>40 WONDERS</span>
          </a>
          <div className={cn("navigation-wrap")}>
            {/* 상단 우측 (PC 기준) 바로가기 링크 */}
            <ul className={cn("quick-link-wrap")}>
              {QUICK_LINKS.map(({ name, link, target }) => (
                <li key={name} className={cn("quick-link")}>
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={target}
                    className={cn("footer-text-link")}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
            {/* 다국어 변경 */}
            {language && (
              <ChangeLanguage selected={i18n.language} theme={theme} />
            )}
          </div>
        </div>
        {/* SNS 바로가기 링크 */}
        <ul className={cn("sns-wrap")}>
          {SNS_LINKS.map(({ name, link, title, logo }) => (
            <li
              key={name}
              className={cn("sns-link", {
                "official-site": name === "wemix_text",
              })}
            >
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                title={i18n.language === "ko" ? "새창 열기" : "Open new window"}
              >
                <span className={cn("footer-icon")}>{logo}</span>
                <span className={cn("a11y")}>{title}</span>
              </a>
            </li>
          ))}
        </ul>
        <div className={cn("util-wrap")}>
          {/* 약관 바로가기 링크 */}
          <ul className={cn("terms-link-wrap")}>
            {TERMS_LINKS.map(({ name, link }) => (
              <li key={name} className={cn("terms-link")}>
                <a href={link} className={cn("footer-text-link")}>
                  <span>{name}</span>
                </a>
              </li>
            ))}
          </ul>
          <div className={cn("copyright-wrap")}>
            {/* COPYRIGHT */}
            <p className={cn("copyright")}>
              © WEMIX PTE. LTD. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CommonFooter;
