import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames/bind";
import VotingTopRewards from "../../components/voting/VotingTopRewards.jsx";
import VotingTitle from "../../components/voting/VotingTitle.jsx";
import { message, Table, Radio } from "antd"; // [24.04.11] 기획 변경에 따른 수정 반영
import { VotingModal, LoadingModal } from "../../components/Modal.js";
import VotingInputArea from "../../components/voting/VotingInputArea";
import { Link } from "react-router-dom";
import {
  checkAddress,
  checkUndefined,
  convertWeiToEther,
  timeConverter,
} from "../../util.js";
import {
  decodeLogsFromReceipt,
  encodeABIValueInMethod,
  web3Instance,
} from "../../web3.js";
import { CHAIN_INFO, NETWORK } from "../../constants.js";
import { useSendTransaction } from "wagmi";
import { GovInitCtx } from "../../contexts/GovernanceInitContext.jsx";

const Rewards = ({ defaultAccount, ncpInfo }) => {
  const [isMobile, setIsMobile] = useState();
  let isMobileType = useRef(true);

  const [rewardsItems, setRewardsItems] = useState([]);
  const { sendTransactionAsync } = useSendTransaction();
  const { getRewardHistoryFromEvents } = useContext(GovInitCtx);

  const handleWindowSizeChange = useCallback(() => {
    isMobileType.current = window.innerWidth < 1120 ? true : false;

    isMobileType.current ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // 로그인 안 했을 경우,  ncp 정보가 없는 경우 (일반 address) 튕김 처리
  const navigate = useNavigate();
  useEffect(() => {
    if (defaultAccount === undefined) {
      message.destroy();
      message.open({
        type: "warning",
        content: "Please log in first.",
      });
      navigate("/voting/list");
    }
    if (ncpInfo.contract === null) {
      message.destroy();
      message.open({
        type: "warning",
        content: "You do not have access.",
      });
      navigate("/voting/list");
    }

    if (ncpInfo.contract) {
      // 계정 변경 시 히스토리 새로 가져오기
      getClaimHistory();
      setRewardAmount(ncpInfo.reward);
    }
  }, [ncpInfo, defaultAccount, navigate]);

  const columnsData = [
    {
      title: "Claim Amount",
      dataIndex: "claimAmount",
      width: 260,
      render: (_, { claimAmount }) => {
        return (
          <span className={cn("claim-amount")}>
            {claimAmount} <span className={cn("unit")}>WEMIX</span>
          </span>
        );
      },
    },
    {
      title: "Tx Hash",
      dataIndex: "hash",
      render: (_, { hash }) => {
        return (
          <Link to={`${CHAIN_INFO.blockExplorerUrls}/tx/${hash}`}>
            <span className={cn("tx-hash", "underline")}>{hash}</span>
          </Link>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      width: 174,
    },
  ];

  // 지갑 주소 앞 뒤 4자리 강조 처리 함수
  const walletAddressFix = (address) => {
    const prefix = address.slice(0, 6);
    const suffix = address.slice(address.length - 4, address.length);
    const middle = address.slice(6, address.length - 4);
    return (
      <>
        <b>{prefix}</b>
        {middle}
        <b>{suffix}</b>
      </>
    );
  };
  // 지갑 주소 앞 뒤 4자리만 보이게 처리 함수
  const walletAddressEllipsis = (address) => {
    if (typeof address !== "string") return address;
    const walletAddressLength = 4;
    if (address.length <= walletAddressLength * 2 + 2) return address;
    const prefix = address.substring(0, 2);
    const suffix = address.substring(address.length - walletAddressLength);
    const walletAddress =
      prefix + address.substring(2, walletAddressLength + 2) + "..." + suffix;
    return walletAddress;
  };

  // 리워드 받은 내역 조회하는 메소드
  const getClaimHistory = async () => {
    try {
      let data;
      // 위믹스 파이 api 호출
      const response = await fetch(
        `${CHAIN_INFO.apiUrl}/tx/list?user=${defaultAccount}&status=false&unit=false&type=ncp_claim&amount=20&skip=0&order=DESC`,
      );
      const result = await response.json();
      data = result.data;

      let history = [];
      data.map((item) => {
        const { timestamp, hash, data } = item;
        const { user_claim_amount } = data;
        history.push({
          timestamp: timeConverter(new Date(timestamp).getTime() / 1000, true),
          hash,
          claimAmount: convertWeiToEther(user_claim_amount),
        });

        return null;
      });

      setRewardsItems(history);
    } catch (e) {
      console.error(e);
    }
  };

  // 리워드를 받는 메소드 (클레임 함수)
  const handleClaimReward = async () => {
    try {
      setIsOpenLoadingModal(true);

      const { pid, ncp } = ncpInfo;
      const data = await encodeABIValueInMethod(
        web3Instance,
        "NCPStaking",
        "claim",
        pid,
        ncp,
      );

      const callback = (receipt) => {
        if (receipt.status) {
          const [{ rewardAmount }] = decodeLogsFromReceipt(
            web3Instance.web3,
            receipt,
          );

          setSuccessHash(receipt.transactionHash); // hash 값을 보여줌
          setSuccessRewardAmount(rewardAmount); // 실제 받은 수량 세팅
          setRewardAmount(""); // 리워드 값 초기화
          setIsOpenClaimSuccessModal(true);

          setIsOpenLoadingModal(false);
        } else {
          throw receipt;
        }
      };

      await sendTransaction(data, callback);
    } catch (e) {
      setErrMsg("Reward claim has failed.");
      setIsOpenClaimFailedModal(true);

      setIsOpenLoadingModal(false);
    }
  };

  // 리워드 받기 성공 후 익스플로러 새창 띄우기
  const handleClickSuccessHash = () => {
    window.location.href = `${CHAIN_INFO.blockExplorerUrls}/tx/${successHash}`;
  };

  // address valid 체크, 에러 핸들링 및 state에 값 넣기
  const checkClaimAddress = (e) => {
    const { value } = e.target;
    setClaimAddr(value);
    setErrClaimAddress(!checkAddress(value));
  };

  // 리워드를 받는 address를 변경하는 메소드
  const handleClaimAddress = async () => {
    try {
      setIsLoading(true); // 버튼 disabled
      setIsOpenLoadingModal(true); // 로딩 모달

      if (!claimAddr.length) {
        setErrClaimAddress(true);
        setIsLoading(false);
        setIsOpenLoadingModal(false);
        return;
      }

      // checksum check
      const checksum = web3Instance.web3.utils.checkAddressChecksum(claimAddr);
      if (!checksum) {
        throw Object.assign(new Error(), {
          msg: "Please double-check the address you entered.",
        });
      }
      // add: 이미 claim address로 등록된 address인지, remove: 등록되지 않은 address인지 확인
      const isRewarder = await ncpInfo.contract.methods
        .isRewarder(claimAddr)
        .call();
      const checkAddressAdd = radioValue === "added" && Number(isRewarder);
      if (checkAddressAdd)
        throw Object.assign(new Error(), { msg: "Existing wallet address." });
      const checkAddressRemove =
        radioValue === "removed" && !Number(isRewarder);
      if (checkAddressRemove)
        throw Object.assign(new Error(), {
          msg: "Entered wallet address has not been added.",
        });

      const encodeABI = await ncpInfo.contract.methods[
        `${radioValue === "added" ? "addRewarder" : "removeRewarder"}`
      ](claimAddr).encodeABI();
      const trx = {
        to: ncpInfo.rewarder,
        data: encodeABI,
      };

      const callback = (receipt) => {
        if (receipt.status) {
          setIsOpenChangeAddressSuccessModal(true);

          setIsLoading(false);
          setIsOpenLoadingModal(false);
        } else {
          throw receipt;
        }
      };

      await sendTransaction(trx, callback);
    } catch (e) {
      setIsLoading(false);
      setIsOpenLoadingModal(false);

      setErrMsg(
        e.msg
          ? e.msg // address check 할 때 에러 메시지 띄움
          : radioValue === "added" // add/remove 할 때 에러 메시지 분기처리
          ? "Reward claim address change has failed."
          : "We're having trouble processing your transaction. Please try again.",
      );
      setIsOpenClaimFailedModal(true);
    }
  };

  // 트랜잭션을 보내는 메소드
  const sendTransaction = async (data, callback) => {
    try {
      const trx = {
        ...data,
        from: defaultAccount,
        gasPrice: 101000000000,
        value: "0x0",
      };

      // 해시 값 리턴
      await sendTransactionAsync(trx).then(({ hash }) => {
        waitForReceipt(hash, (receipt) => {
          if (receipt.status) {
            callback(receipt);
          }
        });
      });
    } catch (e) {
      throw e;
    }
  };

  const waitForReceipt = (hash, cb) => {
    web3Instance.web3.eth.getTransactionReceipt(hash, (err, receipt) => {
      if (err) console.log("err: ", err);
      if (checkUndefined(receipt) || receipt === null) {
        // Try again in 1 second
        window.setTimeout(() => {
          waitForReceipt(hash, cb);
        }, 1000);
      } else {
        // Transaction went through
        if (cb) cb(receipt);
      }
    });
  };

  // modal 관련 옵션들
  const [isLoading, setIsLoading] = useState(false); // change address input disabled 처리
  const [errClaimAddress, setErrClaimAddress] = useState(false); // change address 입력 에러 처리 및 apply 버튼 disabled
  const [errMsg, setErrMsg] = useState("");
  // claim address
  const [claimAddr, setClaimAddr] = useState("");
  const [successHash, setSuccessHash] = useState("");
  const [isOpenClaimAddressModal, setIsOpenClaimAddressModal] = useState(false);
  const [isOpenChangeAddressSuccessModal, setIsOpenChangeAddressSuccessModal] =
    useState(false); // claim address 변경 성공
  // claim reward
  const [rewardAmount, setRewardAmount] = useState(""); // 페이지 헤더에 보이는 수량
  const [successRewardAmount, setSuccessRewardAmount] = useState(""); // 실제 클레임 받은 수량
  const [isOpenLoadingModal, setIsOpenLoadingModal] = useState(false); // 리워드 받기 눌렀을 때
  const [isOpenClaimSuccessModal, setIsOpenClaimSuccessModal] = useState(false); // 리워드 받기 성공
  const [isOpenClaimFailedModal, setIsOpenClaimFailedModal] = useState(false); // 리워드 받기 실패

  // [24.04.11] 기획 변경에 따른 수정 반영 : add, remove 선택 라디오 추가
  const [radioValue, setRadioValue] = useState("added");
  const onChange = (e) => {
    setClaimAddr("");
    setErrClaimAddress(false);
    setRadioValue(e.target.value);
  };

  // manage claim address 모달 닫히면 입력 및 선택했던 값 초기화
  useEffect(() => {
    if (!isOpenClaimAddressModal) {
      setClaimAddr("");
      setErrClaimAddress(false);
      setRadioValue("added");
    }
  }, [isOpenClaimAddressModal]);

  return (
    <>
      <VotingTopRewards
        rewardAmount={rewardAmount}
        addressType={ncpInfo.addressType}
        setIsOpenClaimAddressModal={setIsOpenClaimAddressModal}
        handleClaimReward={handleClaimReward}
        disabledClaim={isOpenLoadingModal}
      />
      <main>
        <div className={cn("inner")}>
          {/* {rewardsItems.length > 0 && ( */}
          <div className={cn("voting-rewards-list-section", "rewards-item")}>
            <VotingTitle
              type="md"
              title={"Claim History"}
              count={rewardsItems.length}
              exp={
                <>
                  The table shows only the 20 most recent claims for the
                  connected wallet.
                </>
              }
            />
            <div className={cn("rewards-list")}>
              <Table
                rowKey={(record) => record.hash}
                pagination={false}
                columns={columnsData}
                dataSource={rewardsItems}
                locale={{
                  emptyText: (
                    <div style={{ textAlign: "center", padding: "107px 0" }}>
                      No claim history found.
                    </div>
                  ),
                }}
                scroll={{ x: 1000 }}
              />
            </div>
          </div>
          {/* )} */}
        </div>
        {/* modal case */}
        {/* [24.04.11] 기획 변경에 따른 수정 반영 */}
        {isOpenClaimAddressModal && (
          <VotingModal
            visible={isOpenClaimAddressModal}
            isVotingModal={() => setIsOpenClaimAddressModal(false)}
            btn={{ btnName: "Apply", cancel: true }}
            onOk={() => handleClaimAddress()}
            disabledOk={errClaimAddress || isLoading}
            scrollType={false}
            title="Manage Reward Claim Address"
          >
            <div className={cn("claim-address-wrap")}>
              {/* [24.04.11] 기획 변경에 따른 수정 반영 : add, remove 선택 라디오 추가 */}
              <p className={cn("title")}>Select Action</p>
              <Radio.Group onChange={onChange} value={radioValue}>
                <Radio value={"added"}>Add reward claim address</Radio>
                <Radio value={"removed"}>Remove reward claim address</Radio>
              </Radio.Group>
              <p className={cn("title")}>Enter Address</p>
              <VotingInputArea
                value={claimAddr}
                placeholder={"Enter address starting with 0x"}
                type={"default"}
                onChange={(e) => checkClaimAddress(e)}
                className={errClaimAddress ? "errInput" : ""}
                disabled={isLoading}
                errType={errClaimAddress}
                errText={
                  "Please enter a valid wallet address starting with 0x."
                }
              />
            </div>
          </VotingModal>
        )}
        {isOpenClaimSuccessModal && (
          <VotingModal
            visible={isOpenClaimSuccessModal}
            isVotingModal={() => setIsOpenClaimSuccessModal(false)}
            btn={{ btnName: "Close", cancel: false }}
            scrollType={false}
            onOk={() => navigate(0)}
            notHeader={true}
            closable={false}
          >
            <div className={cn("modal-info-wrap", "check")}>
              {/* error-detail : ! 아이콘 (Red color) 
            check-detail : 체크 아이콘 (Green color) */}
              <span className={cn("check-detail")}>
                Reward amount have been successfully claimed.
              </span>

              <div className={cn("gray-info-list-wrap")}>
                <ul className={cn("info-list")}>
                  <li>
                    <span className={cn("title")}>Claim Amount</span>
                    <span className={cn("result")}>
                      <span className={cn("svg-icon")}>
                        <img
                          src={`${process.env.REACT_APP_URL}/assets/images/logo_wemix.svg`}
                          alt="logo_wemix"
                        />
                      </span>
                      <strong>
                        {convertWeiToEther(successRewardAmount)} WEMIX
                      </strong>
                    </span>
                  </li>
                  <li>
                    <span className={cn("title")}>Tx Hash</span>
                    <span
                      className={cn("result", "underline")}
                      onClick={() => handleClickSuccessHash()}
                    >
                      {walletAddressEllipsis(successHash)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </VotingModal>
        )}
        {/* [24.04.11] 기획 변경에 따른 수정 반영 : add, remove 에 따른 워딩 변경 */}
        {isOpenChangeAddressSuccessModal && (
          <VotingModal
            visible={isOpenChangeAddressSuccessModal}
            isVotingModal={() => setIsOpenChangeAddressSuccessModal(false)}
            btn={{ btnName: "Close", cancel: false }}
            scrollType={false}
            onOk={() => {
              setIsOpenChangeAddressSuccessModal(false);
              setIsOpenClaimAddressModal(false);
            }}
            notHeader={true}
            closable={false}
          >
            <div className={cn("modal-info-wrap", "check")}>
              <span className={cn("check-detail")}>
                {`Reward claim address have been successfully ${radioValue}.`}
              </span>

              <div className={cn("gray-info-list-wrap")}>
                <ul className={cn("info-list")}>
                  <li className={cn("row")}>
                    <span className={cn("title")}>Reward Claim Address</span>
                    <span className={cn("result")}>
                      {walletAddressFix(claimAddr)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </VotingModal>
        )}
        {/* [24.04.11] 기획 변경에 따른 수정 반영 : 실패 시 메시지 변경 */}
        {isOpenClaimFailedModal && (
          <VotingModal
            visible={isOpenClaimFailedModal}
            isVotingModal={() => setIsOpenClaimFailedModal(false)}
            btn={{ btnName: "Close", cancel: false }}
            scrollType={false}
            onOk={() => setIsOpenClaimFailedModal(false)}
            notHeader={true}
            closable={false}
          >
            <div className={cn("modal-info-wrap")}>
              <span className={cn("error-detail")}>{errMsg}</span>
            </div>
          </VotingModal>
        )}
        {isOpenLoadingModal && (
          <LoadingModal
            width={isMobile ? 320 : 360}
            visible={isOpenLoadingModal}
            loadingMsg={"Waiting for approval"}
            msg={
              "Complete the approval in your wallet to proceed with the transaction."
            }
          />
        )}
      </main>
    </>
  );
};

export default Rewards;
