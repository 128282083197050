import Web3 from "web3";
import { walletTypes, CHAIN_INFO, CONTRACT_INFO, NETWORK } from "./constants";
import * as abis from "./abis/index";
import { asyncExecuteBatch } from "./util";

const network = process.env.REACT_APP_NETWORK_TYPE;

// contract 객체 생성
const localInitContracts = async (web3) => {
  let contractInstance = {};
  // take contract addresses and abi files, set each contract object
  CONTRACT_INFO.map(async (item) => {
    const name = item.name;
    contractInstance = {
      ...contractInstance,
      [item.name]: new web3.eth.Contract(abis[name].abi, item.address),
    };
  });
  return contractInstance;
};
// local에 address를 저장하고 있지 않은 address로 contract 객체를 만들기 위한 함수
const initContracts = async (web3, name, address) => {
  const contract = new web3.eth.Contract(abis[name].abi, address);
  return contract;
};

let web3Instance;
const getWeb3Instance = () => {
  return new Promise(async (resolve, reject) => {
    // set default web3 instance
    const { serviceRpcUrls } = CHAIN_INFO;
    const web3 = new Web3(serviceRpcUrls);

    if (!web3) {
      // web3 is not found
      reject(new Error("No web3 instance injected."));
    }

    // registry contract init
    const registry = CONTRACT_INFO.filter((c) => c.name === "Registry")[0]
      .address;
    const contract = new web3.eth.Contract(abis["Registry"].abi, registry);

    // registry에 저장된 각 contract name을 hex로 바꿈
    const staking = web3.utils.stringToHex("Staking");
    const ballotStorage = web3.utils.stringToHex("BallotStorage");
    const envStorage = web3.utils.stringToHex("EnvStorage");
    const governance = web3.utils.stringToHex("GovernanceContract");

    // registry에서 contract address를 가져옴
    const br = new web3.BatchRequest();
    br.add(contract.methods.getContractAddress(staking).call.request({}));
    br.add(contract.methods.getContractAddress(ballotStorage).call.request({}));
    br.add(contract.methods.getContractAddress(envStorage).call.request({}));
    br.add(contract.methods.getContractAddress(governance).call.request({}));

    const [stakingAddr, ballotStorageAddr, envStorageAddr, governanceAddr] =
      await asyncExecuteBatch(br);

    // contract init
    const web3Contracts = {
      Registry: contract,
      Staking: await initContracts(web3, "Staking", stakingAddr),
      BallotStorageImp: await initContracts(
        web3,
        "BallotStorageImp",
        ballotStorageAddr,
      ),
      EnvStorageImp: await initContracts(web3, "EnvStorageImp", envStorageAddr),
      GovImp: await initContracts(web3, "GovImp", governanceAddr),
      ...(await localInitContracts(web3)),
    };

    web3Instance = {
      web3,
      web3Contracts,
      netName: network === "mainnet" ? "MAINNET" : "TESTNET",
    };
    resolve(web3Instance);
  });
};

// batch method (only call)
export const onlyCallBatchMethod = (web3, contract, method) => {
  const data = web3.web3Contracts[contract].methods[method]().call.request({});
  return data;
};

// batch method (with value)
export const callBatchMethod = (web3, contract, method, ...value) => {
  let data;
  if (method === "hasAlreadyVoted") {
    const { id, voter } = value[0];
    data = web3.web3Contracts.BallotStorageImp.methods
      .hasAlreadyVoted(id, voter)
      .call.request({});
  } else {
    // ballotMember를 가져올 때 사용하는데, change env 의 경우에만 사용 => list title에 띄우기 위함
    if (value.length === 2 && typeof value[1] === "object") {
      data = web3.web3Contracts[contract].methods[method](
        value[0],
      ).call.request(value[1]); // request 값에 ballotId를 넣어서 batch 실행
    } else {
      data = web3.web3Contracts[contract].methods[method](
        value[0],
      ).call.request({});
    }
  }
  return data;
};

// call contract method (no value)
export const onlyCallContractMethod = async (web3, contract, method) => {
  const data = await web3.web3Contracts[contract].methods[method]().call();
  return data;
};

// call contract method (with value)
export const callContractMethod = async (web3, contract, method, ...value) => {
  let data;
  if (method === "hasAlreadyVoted") {
    const { id, voter } = value[0];
    data = await web3.web3Contracts.BallotStorageImp.methods
      .hasAlreadyVoted(id, voter)
      .call();
  } else {
    if (value.length >= 2) {
      data = await web3.web3Contracts[contract].methods[method](
        ...value,
      ).call();
    } else
      data = await web3.web3Contracts[contract].methods[method](
        value[0],
      ).call();
  }
  return data;
};

// encodeABI (value in method)
export const encodeABIValueInMethod = (web3, contract, method, ...value) => {
  try {
    let trxData = {
      to: web3.web3Contracts[contract]._address,
    };
    const {
      staker,
      voter,
      reward,
      name,
      enode,
      ip,
      port,
      lockAmount,
      memo,
      duration,
      oldStaker,
      newGovAddr,
      newWonderStakingImplAddr,
      envName,
      envType,
      envVal,
      companyName,
      companyAddress,
      investmentAmount,
      description,
      link,
      id,
      txHashArr,
      slashedAmount,
      unlockAmount,
    } = value[0];
    switch (method) {
      case "addProposalToAddMember":
        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToAddMember([
            staker,
            voter,
            reward,
            name,
            enode,
            ip,
            port,
            lockAmount,
            memo,
            duration,
          ])
          .encodeABI();
        break;
      case "addProposalToChangeMember":
        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToChangeMember(
            [
              staker,
              voter,
              reward,
              name,
              enode,
              ip,
              port,
              lockAmount,
              memo,
              duration,
            ],
            oldStaker,
            unlockAmount,
            slashedAmount,
          )
          .encodeABI();
        break;
      case "addProposalToRemoveMember":
        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToRemoveMember(
            staker,
            lockAmount,
            memo,
            duration,
            unlockAmount,
            slashedAmount,
          )
          .encodeABI();
        break;
      case "addProposalToChangeGov":
        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToChangeGov(newGovAddr, memo, duration)
          .encodeABI();
        break;
      case "addProposalToExecute": {
        const targetAddress =
          network === "mainnet"
            ? "0x2965FEF46990d369d90Ac7F3a678778aeD84f90b"
            : "0xDb94F8593273b767392E64FD6d810c0eFF977936";

        const targetABI = [
          {
            constant: false,
            inputs: [
              {
                name: "proxy",
                type: "address",
              },
              {
                name: "implementation",
                type: "address",
              },
            ],
            name: "upgrade",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
        ];

        const targetContract = new web3Instance.web3.eth.Contract(
          targetABI,
          targetAddress,
        );

        const proxyAddress =
          network === "mainnet"
            ? "0x6Af09e1A3c886dd8560bf4Cabd65dB16Ea2724D8"
            : "0x64d2ccd2C4c7aC869b9f776CbC7b4d6c6fdc6022";

        const callData = targetContract.methods
          .upgrade(proxyAddress, newWonderStakingImplAddr)
          .encodeABI();

        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToExecute(targetAddress, callData, memo, duration)
          .encodeABI();
        break;
      }
      case "addProposalToChangeEnv":
        trxData.data = web3.web3Contracts.GovImp.methods
          .addProposalToChangeEnv(envName, envType, envVal, memo, duration)
          .encodeABI();
        break;
      case "addProposal":
        trxData.data = web3.web3Contracts.WaitGovernance.methods
          .addProposal([
            companyName,
            companyAddress,
            investmentAmount,
            description,
            link,
            [],
          ])
          .encodeABI();
        break;
      case "setTransactionHashes":
        trxData.data = web3.web3Contracts.WaitGovernance.methods
          .setTransactionHashes(id, txHashArr)
          .encodeABI();
        break;
      default:
        trxData.data = web3.web3Contracts[contract].methods[method](
          ...value,
        ).encodeABI();
        break;
    }
    return trxData;
  } catch (err) {
    return err;
  }
};

// encodeABI (value in trxData)
export const encodeABIValueInTrx = (web3, contract, method, value) => {
  try {
    let trxData = {
      to: web3.web3Contracts[contract]._address,
      value,
      data: web3.web3Contracts[contract].methods[method]().encodeABI(),
    };

    return trxData;
  } catch (err) {
    return err;
  }
};

// receipt 보고 토스트 뿌리는 거
export const decodeLogsFromReceipt = (web3, receipt) => {
  try {
    const resultLog = [];
    // 현재는 NCPStaking.claim 만 사용
    const abi = {
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "user",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "pid",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "rewardAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "to",
          type: "address",
        },
      ],
      name: "Claim",
      type: "event",
    };
    const eventSignature = web3.eth.abi.encodeEventSignature(abi);

    receipt.logs.map((log) => {
      if (log.topics[0] === eventSignature) {
        const result = web3.eth.abi.decodeLog(
          abi.inputs,
          log.data,
          log.topics.slice(1),
        );
        resultLog.push(result);
      }
      return null;
    });
    return resultLog;
  } catch (e) {
    throw e;
  }
};

// get accounts
export const getAccounts = async (walletType) => {
  const { META_MASK, WALLET_CONNECT, COIN_BASE } = walletTypes;
  let account;
  switch (walletType) {
    case META_MASK:
      account = await web3Instance.web3.eth.requestAccounts();
      return account[0];
    case WALLET_CONNECT:
    case COIN_BASE:
      account = await web3Instance.web3.eth.getAccounts();
      return account[0];
    default:
  }
};

export { web3Instance };
export default getWeb3Instance;
