import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  lazy,
  Suspense,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames/bind";
import LandingLayout, {
  familySites,
  snsList,
} from "./components/LandingLayout.jsx";
/* 23.06.20 수정: Link 추가 */
import { useLocation, Link } from "react-router-dom";
import { throttle } from "lodash";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

/* 22.11.04 수정 end: 이미지 추가 */

import OGHeader from "./components/wait/OGHeader";

import AOS from "aos";
import "aos/dist/aos.css";

/* 22.11.05 수정 start: 40 wonders 추가 */
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.scss";

import getWeb3Instance, { web3Instance } from "./web3.js";
import { CHAIN_INFO } from "./constants.js";
import Web3 from "web3";
import { timeConverter } from "./util.js";
import { GovInitCtx } from "./contexts/GovernanceInitContext.jsx";
import { LandingDataStateContext } from "./contexts/LandingDataContext.jsx";
const LottieComponent = lazy(() => import("./components/LottieComponent.jsx"));

const Landing = () => {
  const { isLandingDataReady, isLandingDataError, wondersList, partnerList } =
    useContext(LandingDataStateContext);
  const { t, i18n } = useTranslation();
  const [toggleClassPhase1, setToggleClassPhase1] = useState(false);
  const [toggleClassPhase2, setToggleClassPhase2] = useState(false);
  const [toggleClassPhase3, setToggleClassPhase3] = useState(false);
  const [partnerListData, setPartnerListData] = useState([]);
  const location = useLocation();
  const [offset, setOffset] = useState({
    width: 0,
    height: 0,
  });
  const togglePhase1 = useRef();
  const togglePhase2 = useRef();
  const togglePhase3 = useRef();
  const [toggleHeight, setToggleHeight] = useState({
    phase1: 0,
    phase2: 0,
    phase3: 0,
  });
  const [apr, setApr] = useState(0);
  // 퇴출 NCP 안건이 가결되었는지 확인하기 위함 (data.isWithdrawal)
  const { data } = useContext(GovInitCtx);

  useEffect(() => {
    setPartnerListData(partnerList);
  }, [isLandingDataReady]);

  const roleLists = [
    {
      name: t("wonders.role.subtitle1"),
      logo: (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_integrity.svg`}
          alt="ico_integrity"
        />
      ),
      desc: t("wonders.role.desc1"),
      footnote: t("wonders.role.note1"),
    },
    {
      name: t("wonders.role.subtitle2"),
      logo: (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_consensus.svg`}
          alt="ico_consensus"
        />
      ),
      desc: t("wonders.role.desc2"),
    },
    {
      name: t("wonders.role.subtitle3"),
      logo: (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_governance.svg`}
          alt="ico_governance"
        />
      ),
      desc: t("wonders.role.desc3"),
    },
    {
      name: t("wonders.role.subtitle4"),
      logo: (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_validation.svg`}
          alt="ico_validation"
        />
      ),
      desc: t("wonders.role.desc4"),
    },
  ];

  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      if (offset.width < 1120) {
        setToggleHeight({
          phase1: togglePhase1.current.scrollHeight,
          phase2: togglePhase2.current.scrollHeight,
          phase3: togglePhase3.current.scrollHeight,
        });
      }
    });
  }, [offset.width, location.search]);

  useEffect(() => {
    AOS.init();
  }, []);

  // 메인넷 기준
  const getBalance = async () => {
    const { serviceRpcUrls } = CHAIN_INFO;

    const web3 = new Web3(serviceRpcUrls);
    const address = web3Instance.web3Contracts.Staking._address;
    try {
      // Staking 주소의 balance 를 가지고 옴
      let balance = await web3.eth.getBalance(address);
      // wei -> eth
      balance = balance / 10 ** 18;
      // 계산식 -> Staking 주소의 ((86400 * 365 * 0.5) / balance) * 100
      const apr = ((86400 * 365 * 0.25) / balance) * 100;
      setApr(apr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWeb3Instance().then(() => {
      // 연이율 표기
      getBalance();
    });
  }, []);

  const partnerOnClick = (event, idx) => {
    setPartnerListData(
      partnerListData.map((item, index) => {
        index === idx ? (item.state = !item.state) : (item.state = false);
        return item;
      }),
    );
    event.currentTarget.nextSibling.style = `transform: translateX(-${
      event.currentTarget.getBoundingClientRect().left
    }px);`;
  };

  return (
    <LandingLayout>
      {/* 23.04.28 수정: OG 추가 */}
      {/* TODO: process.env.PUBLIC_URL가 배포 URL이 맞는지 꼭 확인해주세요~! */}
      <OGHeader />
      <h1 className={cn("a11y")}>WEMIX 40 WONDERS</h1>
      <main className={cn("landing-container")}>
        <section
          className={cn("section-wrap visual-wrap bg-black column-type")}
        >
          <div className={cn("visual-desc inner-column")}>
            <h2 data-aos="fade-up" data-aos-duration="500">
              {t("wonders.top.name")}
              <br />
              {t("wonders.top.title")}
            </h2>
            <div
              className={cn("desc")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {t("wonders.top.desc")}
            </div>
          </div>
          <Suspense fallback={<></>}>
            <LottieComponent />
          </Suspense>
          <span className={cn("ico-scroll")}>
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_scroll.svg`}
              alt="ico_scroll"
            />
            SCROLL
          </span>
        </section>
        <section className={cn("section-wrap bg-purple section-wonder-about")}>
          <div
            className={cn("inner-row title-section")}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div className={cn("title-wrap")}>
              <h2
                className={cn("h4")}
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <Trans i18nKey="wonders.ncp.title">
                  <sup />
                  <sup />
                </Trans>
              </h2>
            </div>
            <div
              className={cn("desc desc-sm")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <span>
                <sup>1</sup> {t("wonders.ncp.text1")}
              </span>
              <span>
                <sup>2</sup> {t("wonders.ncp.text2")}
              </span>
            </div>
          </div>
          <div className={cn("inner-row")}>
            <ul className={cn("list-wrap", "governance")}>
              <li>
                <div
                  data-aos="fade-up"
                  data-aos-duration="500"
                  className={cn("list-title-wrap")}
                >
                  <strong className={cn("list-title")}>
                    {t("wonders.metric.core.title")}
                  </strong>
                  <span className={cn("list-title-desc")}>
                    {t("wonders.metric.core.subtitle")}
                  </span>
                  <div
                    className={cn("list-content")}
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div className={cn("bi-wrap")}>
                      <img
                        src={`${process.env.REACT_APP_URL}/assets/images/ico_bi.svg`}
                        alt="ico_bi"
                      />
                      <strong>
                        <CountUp
                          start={0.0}
                          end={1.5}
                          decimals={1}
                          duration={1}
                        >
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start}>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        M
                      </strong>
                    </div>
                  </div>
                </div>
                <div
                  className={cn("desc")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  {t("wonders.metric.core.text")}
                </div>
              </li>
              <li>
                <div
                  data-aos="fade-up"
                  data-aos-duration="500"
                  className={cn("list-title-wrap")}
                >
                  <strong className={cn("list-title")}>
                    {t("wonders.metric.expand.title")}
                  </strong>
                  <span className={cn("list-title-desc")}>
                    {t("wonders.metric.expand.subtitle")}
                  </span>
                  <div
                    className={cn("list-content")}
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <strong>
                      <CountUp start={0.0} end={apr} duration={2} decimals={3}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      %
                    </strong>
                    <span>
                      {timeConverter(new Date() / 1000, true, false, true)}
                    </span>
                  </div>
                </div>
                <div
                  className={cn("desc")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <p>{t("wonders.metric.expand.text")}</p>
                  <p>
                    <a
                      href={
                        i18n.language === "en"
                          ? "https://wemix.fi/en/staking"
                          : "https://wemix.fi/staking"
                      }
                      className={cn("link")}
                      target="_blank"
                      title={
                        i18n.language === "en" ? "Open new window" : "새창 열기"
                      }
                      rel="noopener noreferrer"
                    >
                      {t("wonders.metric.expand.btn")}
                      <img
                        src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_diagonal.svg`}
                        alt="ico_arrow_diagonal"
                      />
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div
                  data-aos="fade-up"
                  data-aos-duration="500"
                  className={cn("list-title-wrap")}
                >
                  <strong
                    data-aos="fade-up"
                    data-aos-duration="500"
                    className={cn("list-title")}
                  >
                    {t("wonders.metric.reward.title")}
                  </strong>
                  <ul
                    className={cn("list-inner-list")}
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <li>
                      <div className={cn("inner-list-tit-wrap")}>
                        <span>{t("wonders.metric.reward.subtitle1")}</span>
                      </div>
                      <strong className={cn("data")}>
                        <CountUp start={0} end={50} duration={2}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start}>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        %
                      </strong>
                    </li>
                    <li>
                      <div className={cn("inner-list-tit-wrap")}>
                        <span>{t("wonders.metric.reward.subtitle2")}</span>
                      </div>
                      <strong className={cn("data")}>
                        <CountUp start={0} end={25} duration={2}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start}>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        %
                      </strong>
                    </li>
                    <li>
                      <div className={cn("inner-list-tit-wrap")}>
                        <span>{t("wonders.metric.reward.subtitle3")}</span>
                      </div>
                      <strong className={cn("data")}>
                        <CountUp start={0} end={25} duration={2}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start}>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        %
                      </strong>
                    </li>
                  </ul>
                </div>
                <div
                  className={cn("desc")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <p>{t("wonders.metric.reward.text")}</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className={cn("section-wrap bg-black section-role")}>
          <div className={cn("inner-row title-section")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h2>{t("wonders.role.title")}</h2>
            </div>
          </div>
          <div className={cn("visual-list")}>
            <ul className={cn("column-type")}>
              {roleLists.map((roleList, idx) => {
                return (
                  <li key={roleList.name} className={cn("ncp-roles-container")}>
                    <div className={cn("ncp-logo-box")}>{roleList.logo}</div>
                    <div className={cn("ncp-list-info")}>
                      <strong data-aos="fade-up" data-aos-duration="500">
                        {roleList.name}
                      </strong>
                      <div
                        className={cn("desc")}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Trans
                          i18nKey={roleList.desc}
                          components={[<sup></sup>, <sub></sub>]}
                        />
                      </div>
                      {roleList.footnote && (
                        <div
                          className={cn("desc desc-sm")}
                          data-aos="fade-up"
                          data-aos-duration="500"
                        >
                          <sup>1</sup> {roleList.footnote}
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className={cn("section-wrap bg-skyblue section-roadmap")}>
          <div className={cn("inner-row title-section")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <span>{t("wonders.roadmap.name")}</span>
              <h3>{t("wonders.roadmap.title")}</h3>
            </div>
            <div
              className={cn("desc")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {t("wonders.roadmap.desc")}
            </div>
          </div>
          <div className={cn("inner-row")}>
            <ol className={cn("list-wrap")}>
              <li className={cn(toggleClassPhase1 && "active")}>
                <div
                  className={cn("list-content")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className={cn("ol-title")}>
                    <strong className={cn("h4")}>
                      {t("wonders.phase1.title")}
                    </strong>
                  </div>
                  <button
                    type="button"
                    className={cn(
                      "btn btn-toggle-arrow",
                      toggleClassPhase1 && "active",
                    )}
                    onClick={() => setToggleClassPhase1(!toggleClassPhase1)}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_drop_down.svg`}
                      alt="ico_arrow_drop_down"
                    />
                    <span className={cn("a11y")}>
                      {i18n.language === "en" ? "more" : "더보기"}
                    </span>
                  </button>
                  <div
                    className={cn("desc")}
                    style={
                      offset.width < 1120
                        ? { height: toggleHeight.phase1 + "px" }
                        : {}
                    }
                  >
                    <div ref={togglePhase1}>
                      <p>{t("wonders.phase1.desc1")}</p>
                      <p>{t("wonders.phase1.desc2")}</p>
                      <p>{t("wonders.phase1.desc3")}</p>
                      <p>{t("wonders.phase1.desc4")}</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className={cn(toggleClassPhase2 && "active")}>
                <div
                  className={cn("list-content")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className={cn("ol-title")}>
                    <strong className={cn("h4")}>
                      {t("wonders.phase2.title")}
                    </strong>
                    <span className={cn("tag")}>{t("wonders.phase.tag")}</span>
                  </div>
                  <button
                    type="button"
                    className={cn(
                      "btn btn-toggle-arrow",
                      toggleClassPhase2 && "active",
                    )}
                    onClick={() => setToggleClassPhase2(!toggleClassPhase2)}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_drop_down.svg`}
                      alt="ico_arrow_drop_down"
                    />
                    <span className={cn("a11y")}>
                      {i18n.language === "en" ? "more" : "더보기"}
                    </span>
                  </button>
                  <div
                    className={cn("desc")}
                    style={
                      offset.width < 1120
                        ? { height: toggleHeight.phase2 + "px" }
                        : {}
                    }
                  >
                    <div ref={togglePhase2}>
                      <p>{t("wonders.phase2.desc1")}</p>
                      <p>{t("wonders.phase2.desc2")}</p>
                      <p>{t("wonders.phase2.desc3")}</p>
                      {i18n.language === "en" && (
                        <p>{t("wonders.phase2.desc4")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li className={cn(toggleClassPhase3 && "active")}>
                <div
                  className={cn("list-content")}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className={cn("ol-title")}>
                    <strong className={cn("h4")}>
                      {t("wonders.phase3.title")}
                    </strong>
                  </div>
                  <button
                    type="button"
                    className={cn(
                      "btn btn-toggle-arrow",
                      toggleClassPhase3 && "active",
                    )}
                    onClick={() => setToggleClassPhase3(!toggleClassPhase3)}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_drop_down.svg`}
                      alt="ico_arrow_drop_down"
                    />
                    <span className={cn("a11y")}>
                      {i18n.language === "en" ? "more" : "더보기"}
                    </span>
                  </button>
                  <div
                    className={cn("desc")}
                    style={
                      offset.width < 1120
                        ? { height: toggleHeight.phase3 + "px" }
                        : {}
                    }
                  >
                    <div ref={togglePhase3}>
                      <p>{t("wonders.phase3.desc1")}</p>
                      <p>{t("wonders.phase3.desc2")}</p>
                      <p>{t("wonders.phase3.desc3")}</p>
                      <p>{t("wonders.phase3.desc4")}</p>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </section>
        <section className={cn("section-wrap bg-black section-40wonders")}>
          <div className={cn("inner-row wonders-section")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h3>40 WONDERS</h3>
              {/* 40 WONDERS 넘버링 부분 */}
              {isLandingDataError ? (
                <span style={{ fontSize: "12px" }}>
                  {t("common#errorMassage")}
                </span>
              ) : (
                <div className={cn("wonder-list")}>
                  {[1, 2, 3, 4].map((el, _) => (
                    <div key={el + `wonder-list`}>
                      {wondersList.map(
                        (wonders, idx) =>
                          wonders.number > 10 * (el - 1) &&
                          wonders.number <= 10 * el && (
                            <span
                              // key={wonders.number + wonders.name}
                              key={wonders.name}
                              className={cn(
                                wonders.name &&
                                  !data.isWithdrawal[wonders.address]?.exited &&
                                  "active",
                              )}
                            >
                              {wonders.number < 10
                                ? `0${wonders.number}`
                                : wonders.number}
                            </span>
                          ),
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div data-aos="fade-up" data-aos-duration="500">
              <Swiper
                // 23.01.30 수정 start: slidesPerGroup 값 추가
                slidesPerView={3}
                slidesPerGroup={2}
                speed={500}
                modules={[Navigation]}
                navigation={true}
                breakpoints={{
                  1120: {
                    slidesPerView: 4,
                    slidesPerGroup: 3,
                  },
                  1440: {
                    slidesPerView: "auto",
                    slidesPerGroup: 1,
                  },
                  // 23.01.30 수정 end: slidesPerGroup 값 추가
                }}
              >
                {/* 40 WONDERS 넘버링 리스트 부분 */}
                {wondersList.map((wonders) => {
                  if (data.isWithdrawal[wonders.address]?.exited) return null;
                  return (
                    wonders.name && (
                      <SwiperSlide key={wonders.name}>
                        {/* 22.11.07 수정: 링크 추가 마크업 변경 */}
                        <a
                          className={cn("wonders-swiper-inner")}
                          href={
                            wonders.link.length === 1
                              ? wonders.link[0]
                              : i18n.language === "en"
                              ? wonders.link[0]
                              : wonders.link[1]
                          }
                          target="_blank"
                          title={
                            i18n.language === "en"
                              ? "Open new window"
                              : "새창 열기"
                          }
                          rel="noopener noreferrer"
                        >
                          <div className={cn("wonders-number")}>
                            {wonders.backNumberImg}
                            <span className={cn("a11y")}>{wonders.name}</span>
                          </div>
                        </a>
                      </SwiperSlide>
                    )
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
        <section className={cn("section-wrap bg-black section-our")}>
          <div className={cn("inner-column")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h2>{t("wonders.partners.title")}</h2>
              <span className={cn("desc")}>{t("wonders.partners.desc")}</span>
            </div>
          </div>
          <div className={cn("inner-column visual-list list-type")}>
            <ul>
              {partnerListData.map((ourList, idx) => {
                if (data.isWithdrawal[ourList.address]?.exited) {
                  return null;
                }
                return (
                  <li key={ourList.name}>
                    <button
                      className={cn("list-visual", ourList.state && "active")}
                      onClick={(event) => partnerOnClick(event, idx)}
                    >
                      {ourList.logo}
                      <span className={cn("a11y")}>
                        {i18n.language === "en"
                          ? `more ${ourList.name}`
                          : `${ourList.name} 더보기`}
                      </span>
                    </button>
                    <div
                      className={cn("list-title", ourList.state && "active")}
                    >
                      <a
                        href={
                          ourList.link.length === 1
                            ? ourList.link[0]
                            : i18n.language === "en"
                            ? ourList.link[0]
                            : ourList.link[1]
                        }
                        target="_blank"
                        title={
                          i18n.language === "en"
                            ? "Open new window"
                            : "새창 열기"
                        }
                        rel="noopener noreferrer"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        {/* 22.11.04 수정: tag 추가 */}
                        <strong>
                          {ourList.name}{" "}
                          {ourList.number && (
                            <span className={cn("outlist-tag")}>
                              WONDER {ourList.number}
                            </span>
                          )}
                        </strong>
                        <img
                          src={`${process.env.REACT_APP_URL}/assets/images/ico_link.svg`}
                          alt="ico_link"
                        />
                      </a>
                      <div
                        className={cn("desc desc-sm")}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        {i18n.language === "en"
                          ? ourList.descEN
                          : ourList.descKR}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className={cn("section-wrap bg-purple section-become")}>
          <div className={cn("inner-row title-section")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h3>{t("wonders.become.title")}</h3>
            </div>
            <div
              className={cn("desc")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {t("wonders.become.desc")}
            </div>
            <div
              className={cn("btn-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <a
                href={t("wonders.become.buttonLink")}
                target="_blank"
                title={i18n.language === "en" ? "Open new window" : "새창 열기"}
                rel="noopener noreferrer"
                className={cn("btn btn-arrow")}
              >
                {t("wonders.become.buttonText")}
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_right.svg`}
                  alt="ico_arrow_right"
                />
              </a>
            </div>
          </div>
        </section>
        <section className={cn("section-wrap bg-black section-about")}>
          <div className={cn("title-wrap")}>
            <h2 data-aos="fade-up" data-aos-duration="500">
              {t("wonders.more.title")}
            </h2>
          </div>
          <div className={cn("column-type")}>
            <div className={cn("inner-column inner-title")}>
              <h3 data-aos="fade-up" data-aos-duration="500">
                {t("wonders.communication.title")}
              </h3>
            </div>
            <ul className={cn("inner-column sns-list")}>
              {snsList.map((sns, idx) => {
                return (
                  <li key={sns.name}>
                    <a
                      href={sns.link}
                      target="_blank"
                      title={
                        i18n.language === "en" ? "Open new window" : "새창 열기"
                      }
                      rel="noopener noreferrer"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      {sns.logo}
                      <span className={cn("a11y")}>{sns.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={cn("inner-row")}>
            <div className={cn("inner-column column-type")}>
              <div className={cn("inner-title")}>
                <h3 data-aos="fade-up" data-aos-duration="500">
                  {t("wonders.ecosystem.title")}
                </h3>
              </div>
              <ul className={cn("inner-column familysite-list")}>
                {familySites.map((familySite) => {
                  return (
                    <li key={familySite.name}>
                      {/* 2022.10.23 수정: fi 오픈에 따른 조건문 삭제 */}
                      <a
                        href={familySite.link}
                        target="_blank"
                        title={
                          i18n.language === "en"
                            ? "Open new window"
                            : "새창 열기"
                        }
                        rel="noopener noreferrer"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <span>{familySite.logo}</span>
                        <img
                          src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_right.svg`}
                          alt="ico_arrow_right"
                        />
                        <span className={cn("a11y")}>
                          {t(`wonders.ecosystem.${familySite.name}.title`)}
                        </span>
                      </a>
                      <div
                        className={cn("desc desc-sm")}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        {t(`wonders.ecosystem.${familySite.name}.desc`)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
        {/* 23.06.20 수정 start: wait protocol section 추가 */}
        <section className={cn("section-wrap bg-black section-wait")}>
          <div className={cn("inner-row title-section")}>
            <div
              className={cn("title-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h3>{t("ecosystem.wait.title")}</h3>
            </div>
            <div
              className={cn("desc")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {t("ecosystem.wait.desc")}
            </div>
            <div
              className={cn("btn-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <Link to={"/wait"} className={cn("btn btn-arrow")}>
                {t("ecosystem.wait.buttonText")}
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_right.svg`}
                  alt="ico_arrow_right"
                />
              </Link>
            </div>
          </div>
        </section>
        {/* 23.06.20 수정 end: wait protocol section 추가 */}
      </main>
    </LandingLayout>
  );
};

export default Landing;
