import React, { useState, useRef } from "react";
import classNames from "classnames/bind";
import { CSSTransition } from "react-transition-group";
import useClickOutside from "../platform/useClickOutside";
// import useMediaQuery from "@hooks/common/useMediaQuery";
import style from "./PlatformButton.module.scss";
import { PlatformContents } from "./PlatformContents";

const cn = classNames.bind(style);

// interface PlatformButtonProps {
//   iconSize?: "standard" | "small";
//   disabled?: boolean;
//   wrapperClassName?: string;
//   theme?: "light" | "dark";
// }

const PlatformButton = ({
  iconSize = "standard",
  disabled,
  wrapperClassName,
  theme = "dark",
}) => {
  const [isActive, setIsActive] = useState(false);
  // const isDesktop = useMediaQuery("desktop");
  const platformMenuRef = useRef(null);
  const linkMenuRef = useRef(null);
  useClickOutside(platformMenuRef, () => setIsActive(false));

  const handleButtonClick = () => {
    setIsActive((prev) => !prev);
  };

  const ICON_SIZE = iconSize === "standard" ? 28 : 24;
  // const PADDING = iconSize === "standard" && !isDesktop ? 6 : 4;

  return (
    <div
      className={cn("platform-menu", theme, wrapperClassName)}
      ref={platformMenuRef}
      style={{
        "--icon-size": `${ICON_SIZE}px`,
        // "--padding": `${PADDING}px`,
      }}
    >
      <button
        className={cn("btn", { disabled }, { active: isActive })}
        disabled={disabled}
        onClick={handleButtonClick}
        type="button"
      >
        <img
          src={`${process.env.REACT_APP_URL}/assets/core/images/icon/platform.svg`}
          alt="platform"
        />
        <span className={cn("a11y")}>platform menu</span>
      </button>
      <CSSTransition
        in={isActive}
        appear
        timeout={{ exit: 250 }}
        unmountOnExit
        nodeRef={linkMenuRef}
        classNames={{
          enterDone: cn("enter"),
          exit: cn("leave"),
        }}
      >
        <div className={cn("platform-contents-box")} ref={linkMenuRef}>
          <PlatformContents theme={theme} platformType="wemix" />
        </div>
      </CSSTransition>
    </div>
  );
};

export default PlatformButton;
