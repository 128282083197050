import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import useClickOutside from "../platform/useClickOutside";
import classNames from "classnames/bind";
import style from "./ChangeLanguage.module.scss";

const cn = classNames.bind(style);

/** 다국어 노출 순서
 * English
 * 한국어
 * 기타 로만 알파벳 언어: 알파벳 순
 * 중화권
 * 日本語
 */

const ChangeLanguage = ({
  type = "icon-with-text",
  theme = "dark",
  className,
}) => {
  const { i18n } = useTranslation();
  const languageRef = useRef(null);
  const languageOptionRef = useRef(null);
  const [isChangeLanguage, setIsChangeLanguage] = useState(false);
  useClickOutside(languageRef, () => setIsChangeLanguage(false));
  const date = new Date(Date.now() + 86400e3 * 365);
  const LANGUAGE_LIST = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "한국어",
      code: "ko",
    },
  ];
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsChangeLanguage(false);
    document.cookie = `NEXT_LOCALE=${lang}; path=/; expires=${date.toUTCString()}`;
  };
  const onChangeLanguage = () => {
    setIsChangeLanguage(!isChangeLanguage);
  };

  return (
    <div
      className={cn("wrap", type, theme, className && className)}
      ref={languageRef}
    >
      <button
        type="button"
        className={cn("language-btn", { active: isChangeLanguage })}
        onClick={() => onChangeLanguage()}
        aria-expanded={isChangeLanguage}
        aria-haspopup="listbox"
      >
        {type === "icon-with-text" ? (
          <>
            <span className={cn("title")}>
              <span className={cn("svg-icon")}>
                <img
                  src={`${process.env.REACT_APP_URL}/assets/core/images/icon/language.svg`}
                  alt="language"
                />
              </span>
              <span className={cn("a11y")}>Language</span>

              {
                LANGUAGE_LIST[
                  LANGUAGE_LIST.findIndex((el) => el.code === i18n.language)
                ]?.name
              }
            </span>
            <span className={cn("arrow")}>󺀣</span>
          </>
        ) : (
          <span className={cn("svg-icon")}>
            <img
              src={`${process.env.REACT_APP_URL}/assets/core/images/icon/language.svg`}
              alt="language"
            />
            <span className={cn("a11y")}>Language</span>
          </span>
        )}
      </button>
      <CSSTransition
        in={isChangeLanguage}
        appear
        timeout={{
          appear: 0,
          enter: 0,
          exit: 300,
        }}
        unmountOnExit
        nodeRef={languageOptionRef}
        classNames={{
          enterDone: cn("language-list-enter"),
          exit: cn("language-list-exit"),
        }}
      >
        <ul
          className={cn("language-list")}
          role="listbox"
          aria-hidden={!isChangeLanguage}
          ref={languageOptionRef}
        >
          {LANGUAGE_LIST.map(({ name, code }) => (
            <li key={name}>
              <button
                className={cn("language-list-btn", {
                  active: code === i18n.language,
                })}
                aria-pressed={code === i18n.language}
                onClick={() => handleChangeLanguage(code)}
              >
                <span className={cn("label")}>{name}</span>
                {code === i18n.language && (
                  <span className={cn("icon")}>󺀢</span>
                )}
              </button>
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default ChangeLanguage;
