import React from "react";
import cn from "classnames/bind";
import VotingButton from "./Button";
import { convertWeiToEther } from "../../util";

const VotingTopRewards = ({
  rewardAmount,
  addressType,
  setIsOpenClaimAddressModal,
  handleClaimReward,
  disabledClaim,
}) => {
  return (
    <div className={cn("voting-top-wrap", "rewards")}>
      <div className={cn("inner")}>
        <div className={cn("voting-title")}>
          <h2 className={cn("title")}>Rewards</h2>
          {/* [24.04.11] 기획 변경에 따른 수정 반영 */}
          <VotingButton
            text="Manage Reward Claim Address"
            disabled={addressType !== "2"} // owner 일 때만 가능
            type="outline"
            onClick={() => setIsOpenClaimAddressModal(true)}
          />
        </div>
        <div className={cn("voting-rewards-wrap")}>
          <div className={cn("rewards-block")}>
            <span className={cn("title")}>Available Reward Amount</span>
            <div className={cn("result-wrap")}>
              <span className={cn("svg-icon")}>
                {/* <img
                  src={`${process.env.REACT_APP_URL}/assets/images/logo_wemix_d.svg`}
                /> */}
                <img
                  src={"../../../assets/images/logo_wemix_d.svg"}
                  alt="logo_wemix_d"
                />
              </span>
              <strong>{convertWeiToEther(rewardAmount || "0")}</strong>
            </div>
          </div>
          <VotingButton
            text="Claim Reward"
            disabled={
              // NCP, claim address 일 때만 가능
              addressType === "0" || addressType === "2" || disabledClaim
            }
            type="bg"
            onClick={handleClaimReward}
          />
        </div>
      </div>
    </div>
  );
};

export default VotingTopRewards;
