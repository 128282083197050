/* 23.05.30 수정: useRef 추가  */
import React from "react";
import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

/* 23.05.24 수정: 플렛폼 바로가기 메뉴 추가 */
import LandingHeader from "./landing-header/LandingHeader";
import CommonFooter from "./common-footer/CommonFooter";
import { useLocaleCookie } from "../hooks/useLocaleCookie";

export const familySites = [
  {
    name: "wemix",
    link: "https://www.wemix.com/",
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_family_wemix.svg`}
        alt="ico_family_wemix"
      />
    ),
  },
  {
    name: "play",
    link: "https://wemixplay.com/",
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_family_wemixPlay.svg`}
        alt="ico_family_wemixPlay"
      />
    ),
  },
  {
    name: "nile",
    link: "https://nile.io/",
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_family_nile.svg`}
        alt="ico_family_nile"
      />
    ),
  },
  {
    name: "wemixstake",
    link: "https://wemixstake.com/",
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_family_wemixstake.svg`}
        alt="ico_family_wemixstake"
      />
    ),
  },
];

export const SNS_URL = {
  MEDIUM: "https://medium.com/wemix-communication",
  TELEGRAM: "https://t.me/WEMIX_ANNouncement",
  X: "https://twitter.com/WemixNetwork",
  YOUTUBE: "https://www.youtube.com/channel/UCm7NfySSdy2ljrnh64cUJXA",
  FACEBOOK: "https://www.facebook.com/WEMIXBlockchain",
  UNA_MESSENGER: "https://www.unamessenger.com/",
  WEMIX: "https://www.wemix.com/",
};

export const snsList = [
  {
    name: "Medium",
    link: SNS_URL.MEDIUM,
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_medium.svg`}
        alt="ico_medium"
      />
    ),
  },
  {
    name: "Telegram",
    link: SNS_URL.TELEGRAM,
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_telegram.svg`}
        alt="ico_telegram"
      />
    ),
  },
  {
    name: "x",
    link: SNS_URL.X,
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_twitter.svg`}
        alt="ico_twitter"
      />
    ),
  },
  {
    name: "Youtube",
    link: SNS_URL.YOUTUBE,
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_youtube.svg`}
        alt="ico_youtube"
      />
    ),
  },
  {
    name: "Facebook",
    link: SNS_URL.FACEBOOK,
    logo: (
      <img
        src={`${process.env.REACT_APP_URL}/assets/images/ico_facebook.svg`}
        alt="ico_facebook"
      />
    ),
  },
];

const LandingLayout = ({ children }) => {
  useLocaleCookie();
  const { i18n } = useTranslation();
  return (
    <div className={cn("landing-wrap")}>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <LandingHeader />
      {children}
      <CommonFooter />
    </div>
  );
};

export default LandingLayout;
