import React from "react";
import cn from "classnames/bind";
import { constants } from "../../constants";

const Status = ({ status }) => {
  const statusText = constants.ballotStateArr[status];
  const s = statusText.toLowerCase();

  const statusIcon = () => {
    switch (s) {
      case "active":
      case "inprogress":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_active.svg`}
            alt="ico_active"
          />
        );
      case "approved":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_accepted.svg`}
            alt="ico_accepted"
          />
        );
      case "rejected":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_rejected.svg`}
            alt="ico_rejected"
          />
        );
      // 23.03.02 수정: ready 상태 코드 추가
      case "ready":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_ready.svg`}
            alt="ico_ready"
          />
        );
      default:
        return false;
    }
  };
  return (
    <div className={cn("voting-status", s)}>
      {statusIcon()}
      <span>{statusText === "InProgress" ? "Active" : statusText}</span>
    </div>
  );
};

export default Status;
