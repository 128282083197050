import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames/bind";
import style from "./Checkbox.module.scss";

import { useTranslation } from "react-i18next";

const cn = classNames.bind(style);

const Checkbox = ({
  items,
  disabled,
  labelPosition = "right",
  isLabelVisible = true,
  value,
  onChangeValues,
  visibleAll,
}) => {
  const { t } = useTranslation();

  const [checkedArr, setCheckedArr] = useState(value ? value : []);

  const handleCheckboxChange = useCallback(
    (checked, v) => {
      if (checked) {
        setCheckedArr([...checkedArr, v.value]);
      } else {
        setCheckedArr(checkedArr.filter((el) => el !== v.value));
      }
    },
    [checkedArr],
  );
  const handleCheckedAll = useCallback(
    (checked) => {
      if (checked) {
        const checkedListArray = [];
        items.forEach((item) => {
          checkedListArray.push(item.value);
        });
        setCheckedArr(checkedListArray);
      } else {
        setCheckedArr([]);
      }
    },
    [checkedArr],
  );

  useEffect(() => {
    onChangeValues(checkedArr);
  }, [handleCheckedAll, handleCheckboxChange]);

  return (
    <div className={cn(`checkbox-container`, { disabled: disabled })}>
      {visibleAll && (
        <label
          className={cn(`checkbox`, "all", {
            half: checkedArr.length > 0 && checkedArr.length !== items.length,
          })}
        >
          <input
            type="checkbox"
            className={cn("input", "a11y")}
            value="all"
            checked={
              checkedArr.length === 0
                ? false
                : checkedArr.length === items.length
                ? true
                : false
            }
            onChange={(e) => handleCheckedAll(e.target.checked)}
          />
          <span className={cn("item-wrap", { left: labelPosition === "left" })}>
            {isLabelVisible && (
              <span className={cn(`item-label`, "right")}>
                {t("waitInvest.voting.list.filter.all.text")}
              </span>
            )}
          </span>
        </label>
      )}
      {items.map((v, i) => {
        return (
          <label
            className={cn(`checkbox`, { disabled: v.disabled })}
            key={`checkbox-${i}`}
          >
            <input
              type="checkbox"
              className={cn("input", "a11y")}
              disabled={v.disabled}
              value={v.value}
              checked={checkedArr.includes(v.value) ? true : false}
              onChange={(e) => handleCheckboxChange(e.target.checked, v)}
            />
            <span
              className={cn("item-wrap", { left: labelPosition === "left" })}
            >
              {isLabelVisible && (
                <span className={cn(`item-label`, "right")}>{v.label}</span>
              )}
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default Checkbox;
