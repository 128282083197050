import React, { useState, useEffect, useCallback, useRef } from "react";
import cn from "classnames/bind";
import VotingSelect from "../../components/voting/VotingSelect";
import VotingSearch from "../../components/voting/VotingSearch";

const VotingTitle = ({
  type = "md",
  title,
  count,
  searchName,
  searchBallot,
  filterData,
  handleSelect,
  onClose,
  exp,
}) => {
  const [isFilter, setIsFilter] = useState(false);
  const [isMobile, setIsMobile] = useState();
  let isMobileType = useRef(true);

  const handleWindowSizeChange = useCallback(() => {
    isMobileType.current = window.innerWidth < 1120 ? true : false;

    isMobileType.current ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className={cn("voting-title-wrap", type)}>
        {isFilter && isMobile ? (
          false
        ) : (
          <>
            <strong>{title}</strong>
            {count !== undefined && <span>{count}</span>}
          </>
        )}

        {searchName && (
          <div
            className={cn(
              "detail-search-area",
              isFilter && isMobile ? "active" : "",
            )}
          >
            <VotingSelect
              filterData={filterData}
              className={searchName}
              dropdownClassName={searchName}
              handleSelect={handleSelect}
            />

            <VotingSearch
              setIsFilter={setIsFilter}
              searchBallot={searchBallot}
              onClose={onClose}
            />
          </div>
        )}
      </div>
      {exp && <div className="voting-title-exp">{exp}</div>}
    </>
  );
};

export default VotingTitle;
