import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

export function useLocaleCookie() {
  const date = new Date(Date.now() + 86400e3 * 365);
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["NEXT_LOCALE"]);

  useEffect(() => {
    // 페이지 로드시 기존의 NEXT_LOCALE 값으로 router 변경
    let cookieLocale = cookies.NEXT_LOCALE;

    // 쿠키가 없거나 undefined일 때 선호 언어로 설정 (서브페이지 고려)
    if (!cookieLocale || cookieLocale === "undefined") {
      const preferredLanguage = navigator.language.split("-")[0];
      const locales = ["en", "ko"]; // 지원하는 언어
      if (locales.includes(preferredLanguage)) {
        cookieLocale = preferredLanguage;
      } else {
        cookieLocale = "en"; // 지원하지 않는 언어인 경우 디폴트 로케일로 설정
      }
    }

    const currentLang = i18n.language;

    if (cookieLocale) {
      setCookie("NEXT_LOCALE", cookieLocale, {
        path: "/",
        expires: date,
      });
      if (cookieLocale !== currentLang) {
        i18n.changeLanguage(cookieLocale);
      }
    }
  }, [i18n]);
}
