import React, { useState } from "react";
import cn from "classnames/bind";
import { DESC_MAX_LENGTH } from "../../constants";
import RichTextEditor from "react-rte";

import styles from "../../assets/scss/editor.module.scss";
import { DatePicker } from "antd";
import moment from "moment";

const VotingInputArea = ({
  type = "default",
  disabled = false,
  readonly = false,
  inputType,
  placeholder,
  value,
  onChange,
  fixText,
  description,
  name,
  errType,
  errText,
  enterButton,
  onClick,
  prefix,
  maxLength = DESC_MAX_LENGTH,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [addrValue, setAddrValue] = useState("");
  // editor
  const [memo, setMemo] = useState(RichTextEditor.createEmptyValue());
  const editorOnChange = (v) => {
    setMemo(v);
    if (onChange) {
      onChange({ target: { name: "memo", value: v.toString("html") } });
    }
  };
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
      "LINK_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "H1", style: "header-one" },
      { label: "H2", style: "header-two" },
      { label: "H3", style: "header-three" },
      { label: "H4", style: "header-four" },
      { label: "H5", style: "header-five" },
      { label: "H6", style: "header-six" },
    ],
  };

  return (
    <>
      <div
        className={cn(
          "input-wrap",
          inputType,
          type,
          isFocus && "focus-on",
          disabled && "disabled",
        )}
      >
        {inputType === "multiline" ? (
          <RichTextEditor
            value={memo}
            placeholder={placeholder}
            disabled={disabled}
            toolbarConfig={toolbarConfig}
            onChange={editorOnChange}
            rootStyle={{ width: "-webkit-fill-available" }}
            editorStyle={{ minHeight: "10rem" }}
            editorClassName={styles["editor-wrap"]}
          />
        ) : (
          <div className={cn("input-top-area", prefix ? "prefix" : "")}>
            {prefix && <span>{prefix}</span>}
            <div
              className={cn(
                "input-area",
                type,
                inputType,
                isFocus && "focus-on",
                !value && "not-value",
                errType && "error",
                readonly && "readonly",
              )}
            >
              {type === "date" ? (
                <DatePicker.RangePicker
                  showTime={{
                    minuteStep: 5,
                    secondStep: 60,
                    defaultValue: [
                      moment("00:00", "HH:mm"),
                      moment("00:00", "HH:mm"),
                    ],
                  }}
                  format={"yyyy-MM-DD HH:mm"}
                  inputReadOnly
                  placeholder={placeholder}
                  disabled={disabled}
                  onChange={(_, date) =>
                    onChange({ target: { name, value: date } })
                  }
                  name={name}
                  onFocus={() => {
                    setIsFocus(true);
                  }}
                  onBlur={() => {
                    setIsFocus(false);
                  }}
                  style={{ width: "100%" }}
                />
              ) : (
                <>
                  <input
                    type={"text"}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readonly}
                    onChange={(e) => {
                      onChange(e);
                      setAddrValue(e.target.value);
                    }}
                    value={value}
                    name={name}
                    onFocus={() => {
                      setIsFocus(true);
                    }}
                    onBlur={() => {
                      setIsFocus(false);
                    }}
                    maxLength={maxLength}
                  />
                  {enterButton && (
                    <button
                      type="button"
                      className={cn("input-button")}
                      onClick={() => onClick(addrValue)}
                    >
                      {enterButton}
                    </button>
                  )}

                  {/* 23.03.06 수정 start: Prefix, Suffix text props 수정 */}
                  {inputType === "suffix" && value && (
                    <span className={cn("text-place")}>{fixText}</span>
                  )}
                  {inputType === "prefix" && value && (
                    <span className={cn("text-place")}>{fixText}</span>
                  )}
                </>
              )}
            </div>
            {errType && errText && (
              <div>
                <p className={cn("error-massage")}>{errText}</p>
              </div>
            )}

            {/* 23.03.06 수정: description 컴포넌트 밖으로 구조 변경
              <span className={cn("description")}>{description}</span>*/}
          </div>
        )}
      </div>
    </>
  );
};

export default VotingInputArea;
