import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import IconWithText from "../../components/voting/IconWithText.jsx";
import WaitVotingStatus from "../../components/wait/WaitVotingStatus.jsx";

import classNames from "classnames/bind";
import style from "./WaitGovernanceListBlock.module.scss";
import { loginAcc } from "../../util.js";

const cn = classNames.bind(style);

// WaitGovernanceListBlock 영역
const WaitGovernanceListBlock = ({
  link,
  ncpName,
  ncpAddress,
  title,
  state,
  date,
  investmentState,
}) => {
  const { t } = useTranslation();
  const investmentStateAction = useCallback(
    (state) => {
      /**
       * investmentState state 값 정의
       * tx 등록 전(투자 진행 전): tx-before
       * tx 1건 등록 후(투자 진행 완료): tx-complete
       * 부결된 안건의 경우: tx-none
       */
      switch (state) {
        case "tx-before":
          return t("wait.voting.list.progress.before");
        case "tx-complete":
          return t("wait.voting.list.progress.complete");
        default:
          return false;
      }
    },
    [t],
  );
  return (
    <li className={cn("voting-list-block-wrap")}>
      <Link to={`detail?id=${link}`}>
        <div className={cn("wallet-info")}>
          <div className={cn("ncp-info")}>
            <span className={cn("ncp-name")}>
              <span>{ncpName}</span>
            </span>
            <span className={cn("ncp-address")}>{loginAcc(ncpAddress)}</span>
          </div>
          <WaitVotingStatus status={state} />
        </div>
        <div className={cn("list-title-wrap")}>
          <strong className={cn("title")}>{title}</strong>
        </div>
        {/* [24.03.13] Governance 리스트 페이지 내 리스트 상세 정보 텍스트 영역 삭제 */}
        {/* <div
          className={cn("list-desc-wrap")}
          dangerouslySetInnerHTML={{
            __html: refineMemo(description),
          }}
        ></div> */}
        <div className={cn("list-bottom-info")}>
          <IconWithText
            theme="dark"
            data={[
              {
                icon: "time",
                text: date,
              },
              // [24.03.18] 투표리스트의 현황 그래프 삭제 요청 반영 (추후 활성화될 가능성 고려하여 주석 처리)
              // {
              //   icon: "person",
              //   text: person,
              //   children: <VotingChartMini theme="dark" data={chart} />,
              // },
            ]}
          />
          {state === "approved" && investmentState && (
            <div className={cn("tx-state", `${investmentState}`)}>
              {investmentStateAction(investmentState)}
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};

export default WaitGovernanceListBlock;
