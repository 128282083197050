import React, { useContext, useEffect, useState } from "react";
import cn from "classnames/bind";
import VotingTopList from "../../components/voting/VotingTopList.jsx";
import VotingListBlock from "../../components/voting/VotingListBlock.jsx";
import VotingTitle from "../../components/voting/VotingTitle.jsx";
import { constants } from "../../constants.js";

import { web3Instance } from "../../web3.js";
import { useNavigate } from "react-router-dom";
import { checkUndefined } from "../../util.js";

import { GovInitCtx } from "../../contexts/GovernanceInitContext.jsx";

import { useSendTransaction } from "wagmi";

const VotingList = ({
  isWhiteList,
  isMember,
  isStaker,
  defaultAccount,
  getErrModal,
}) => {
  const { data } = useContext(GovInitCtx);
  const {
    // authorityOriginData,
    ballotMemberOriginData,
    ballotBasicOriginData,
    authorityNames,
    waitBallotMemberOriginData,
    waitBallotBasicOriginData,
  } = data;
  const { sendTransactionAsync } = useSendTransaction();
  // -------------------- state
  const [ballotBasicOriginItems, setBallotBasicOriginItems] = useState([]);
  // 리스트에 출력할 항목
  const [activeItems, setActiveItems] = useState([]);
  const [proposalItems, setProposalItems] = useState([]);
  const [finalizedItems, setFinalizedItems] = useState([]);
  const [revokeItems, setRevokeItems] = useState([]);
  // 상태 별 항목의 개수
  const [itemCount, setItemCount] = useState({});
  // filtering
  const [currentSelect, setCurrentSelect] = useState("All");
  const [visibleActiveItems, setVisibleActiveItems] = useState([]);
  const [visibleProposalItems, setVisibleProposalItems] = useState([]);
  const [visibleFinalizedItems, setVisibleFinalizedItems] = useState([]);
  const [renderSelectedItems, setRenderSelectedItems] = useState(<></>);

  const navigate = useNavigate();

  // select 옵션
  const filterData = ["All", "Active & Proposal", "Finalized"];

  // -------------------- useEffect
  useEffect(() => {
    getBallotOriginItem();
  }, [
    ballotBasicOriginData,
    waitBallotBasicOriginData,
    isMember,
    isStaker,
    isWhiteList,
  ]);

  useEffect(() => {
    // 리스트 생성 후 상세 내용 가져옴
    if (ballotBasicOriginItems.length > 0) {
      getBallotDetailInfo();
    }
  }, [ballotBasicOriginItems]);

  useEffect(() => {
    // 리스트를 가져온 후 화면에 뿌리기 위한 용도
    if (Object.keys(itemCount)) {
      handleSelect();
    }
  }, [itemCount]);

  // 검색 후 리스트 업데이트
  useEffect(() => {
    handleSelect(currentSelect);
  }, [
    visibleActiveItems,
    visibleProposalItems,
    visibleFinalizedItems,
    currentSelect,
  ]);

  // 현재 진행 중인 투표를 다 close
  const sendTransaction = async (id = "") => {
    // wait 안건이 아닐 경우 id 값이 없으면서 staker 여야함
    if (!id.length && !isStaker) return;
    const trx = id.length
      ? await web3Instance.web3Contracts.WaitGovernance.methods
          .finalizeEndedVote(id)
          .encodeABI()
      : await web3Instance.web3Contracts.GovImp.methods
          .finalizeEndedVote()
          .encodeABI();

    // 각 네트워크에 맞는 GovImp address (또는 WaitGovernance address) 가져오기
    const to = id.length
      ? web3Instance.web3Contracts.WaitGovernance._address
      : web3Instance.web3Contracts.GovImp._address;

    try {
      /* 
        from, to, data, gasPrice, value 중 어느값이 없으면 Wemix wallet이 데이터를 화면에 그리지 못합니다.
      **/
      await sendTransactionAsync({
        from: defaultAccount,
        to,
        data: trx,
        gasPrice: 101000000000,
        value: "0x0",
      })
        .then(({ hash }) => {
          waitForReceipt(hash, async (receipt) => {
            if (receipt.status) navigate(0);
            else {
              getErrModal(
                "The transaction could not be sent normally.",
                "Proposal Submit Error",
                receipt.transactionHash,
              );
            }
          });
        })
        .catch((e) => console.error(e));
    } catch (e) {
      getErrModal(
        "The transaction could not be sent normally.",
        "Proposal Submit Error",
        e,
      );
    }
  };

  const waitForReceipt = (hash, cb) => {
    web3Instance.web3.eth.getTransactionReceipt(hash, (e, receipt) => {
      if (e) throw e;
      // 아직 receipt을 받지 못헀다면 다시 확인
      if (checkUndefined(receipt) || receipt === null) {
        setTimeout(() => {
          waitForReceipt(hash, cb);
        }, 1000);
      } else {
        // receipt을 받았다면 callback 함수 실행
        if (cb) cb(receipt);
      }
    });
  };

  // -------------------- function
  // 화면에 뿌릴 리스트를 만들어 줌
  const getBallotOriginItem = () => {
    if (!ballotBasicOriginData || !ballotMemberOriginData) return;
    let list = [];
    // 일반 proposal
    Object.values(ballotBasicOriginData).forEach((item, index) => {
      // // ! mainnet일 경우 기존 안건을 보여주지 않음
      // if (NETWORK === "mainnet" && item.id <= 55) return;
      list.push(
        <VotingListBlock
          key={index}
          item={item}
          authorityName={authorityNames.get(item.creator) || "-"}
          // ballotType === "5" 인 경우 (change env)만 멤버 데이터가 있음
          ballotMemberOriginData={Object.values(ballotMemberOriginData).find(
            (data) => data.ballotId === item.id,
          )}
          setTopic={(item) => setTopic(item)}
          onClick={sendTransaction}
          isStaker={isStaker}
          isWhiteList={isWhiteList}
        />,
      );
    });
    // wait 안건
    Object.values(waitBallotBasicOriginData).forEach((item, index) => {
      list.push(
        <VotingListBlock
          key={`wait-${index}`}
          item={item}
          authorityName={authorityNames.get(item.creator) || "-"}
          ballotMemberOriginData={waitBallotMemberOriginData[item.id]}
          setTopic={(item) => setTopic(item)}
          onClick={(id) => sendTransaction(id)}
          isStaker={isStaker}
          isWhiteList={isWhiteList}
        />,
      );
    });
    setBallotBasicOriginItems(list.reverse());
  };

  // 각 항목 별 상세 정보를 가져옴
  const getBallotDetailInfo = () => {
    let activeList = [];
    let proposalList = [];
    let finalizedList = [];
    let revokeList = [];

    let active = 0;
    let proposal = 0;
    let approved = 0;
    let rejected = 0;

    // 상태 별로 나누어 저장
    ballotBasicOriginItems.forEach((item) => {
      switch (item.props.item.state) {
        case constants.ballotState.InProgress:
          activeList.push(item);
          active++;
          break;
        case constants.ballotState.Ready:
          proposalList.push(item);
          proposal++;
          break;
        case constants.ballotState.Approved:
          finalizedList.push(item);
          approved++;
          break;
        case constants.ballotState.Rejected:
          // 투표 기간이 지나 자동으로 rejected된 proposal
          if (item.props.item.isFinalized === "") {
            revokeList.push(item);
          } else {
            finalizedList.push(item);
          }
          rejected++;
          break;
        case constants.ballotState.Invalid:
        default:
          break;
      }
    });

    // finalized 투표 종료된 순으로 출력
    const sortFinalizedList = finalizedList.sort(
      (s, l) => l.props.item.endTime - s.props.item.endTime,
    );

    setActiveItems(activeList);
    setProposalItems(proposalList);
    setFinalizedItems(sortFinalizedList);
    setRevokeItems(revokeList);

    setVisibleActiveItems(activeList);
    setVisibleProposalItems(proposalList);
    setVisibleFinalizedItems(sortFinalizedList);

    setItemCount({
      ...itemCount,
      total: active + proposal + approved + rejected,
      active,
      proposal,
      approved,
      rejected,
    });
  };

  // 투표 항목의 타이틀을 리턴
  const setTopic = ({ ballotType, envVariableName, companyName }) => {
    // MyInfo에서 변경한 항목의 타이틀 세팅
    // if (
    //   creator &&
    //   oldStakerAddress &&
    //   newStakerAddress &&
    //   newVoterAddress &&
    //   newRewardAddress
    // ) {
    //   if (
    //     ballotType === constants.ballotTypes.ReplaceAuthorityMember &&
    //     creator === oldStakerAddress
    //   ) {
    //     if (oldStakerAddress !== newVoterAddress) {
    //       return "Replace Voting Address";
    //     } else if (oldStakerAddress !== newRewardAddress) {
    //       return "Replace Reward Address";
    //     }
    //   }
    // }
    if (ballotType === constants.ballotTypes.ChangedEnv) return envVariableName;
    else if (parseInt(ballotType) > 0)
      return constants.ballotTypesArr[parseInt(ballotType)];
    // wait protocol 항목 검색 가능하도록 추가
    return companyName || "-";
  };

  const searchBallot = (e) => {
    // 입력한 값이 투표 제목이나 투표 생성자에 포함되는지 확인
    const filteringBallot = (ballots, str) => {
      return ballots.filter((value) => {
        const { ballotType } = value.props.item;
        const { ballotMemberOriginData } = value.props;
        const envVariableName =
          ballotType === "5" ? ballotMemberOriginData.envVariableName : "";
        const companyName =
          ballotMemberOriginData && ballotMemberOriginData.isWait
            ? ballotMemberOriginData.isWait
            : "";
        let topic = setTopic({
          ballotType,
          envVariableName,
          companyName,
        });
        return [topic, value.props.item.creator].some((elem) => {
          return elem.toLowerCase().indexOf(str) !== -1;
        });
      });
    };

    const str = e.target.value.toLowerCase();
    // 입력한 값이 없다면 전체 리스트를 보여줌
    if (str === "") {
      getBallotDetailInfo();
      return;
    }

    setVisibleActiveItems(filteringBallot(activeItems, str));
    setVisibleProposalItems(filteringBallot(proposalItems, str));
    setVisibleFinalizedItems(filteringBallot(finalizedItems, str));
  };

  // select 옵션 변경에 따른 항목 렌더링
  const handleSelect = (e = filterData[0]) => {
    const props = [];
    // 옵션에 따른 props 값 적용
    if (e === filterData[0] || e === filterData[1]) {
      props.push({
        title: filterData[1],
        count: `${visibleActiveItems.length + visibleProposalItems.length}`,
        items: [...visibleActiveItems, ...visibleProposalItems],
      });
    }
    if (e === filterData[0] || e === filterData[2]) {
      props.push({
        title: filterData[2],
        count: `${visibleFinalizedItems.length}`,
        items: visibleFinalizedItems,
      });
    }

    const render = props.map((prop) => {
      return (
        <div className={cn("voting-list-section")} key={prop.title}>
          <VotingTitle type="md" title={prop.title} count={prop.count} />
          <div className={cn("section-inner")}>
            {prop.items.length ? (
              <>{prop.items}</>
            ) : (
              <div className={cn("empty-area")}>
                <div className={cn("empty-notice")}>
                  {/* 23.04.11 수정: 아이콘 삭제 */}
                  {/* <IconEmpty /> */}
                  <strong className={cn("empty-title")}>No result</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
    setCurrentSelect(e);
    setRenderSelectedItems(render);
  };

  return (
    <>
      <VotingTopList
        isMember={isMember}
        isStaker={isStaker}
        totalCount={itemCount.total}
        activeCount={itemCount.active}
        readyCount={itemCount.proposal}
        approvedCount={itemCount.approved}
        rejectedCount={itemCount.rejected}
      />
      <main>
        <div className={cn("inner")}>
          <div className={cn("content-filter-wrap")}>
            <VotingTitle
              type="sm"
              title="Total"
              count={itemCount.total}
              searchName="search-type"
              searchBallot={(e) => searchBallot(e)}
              filterData={filterData}
              handleSelect={(e) => handleSelect(e)}
              onClose={() => getBallotDetailInfo()}
            />
            <div className={cn("filter-wrap")}></div>
          </div>
          {/* voting time over - filter와 상관없이 고정*/}
          {revokeItems.length > 0 && (
            <div className={cn("voting-list-section", "revoke-item")}>
              <VotingTitle
                type="md"
                title={"Voting Time Over"}
                count={revokeItems.length}
                exp={
                  <>
                    The proposed vote was rejected because more than 50% of the
                    votes were not carried out.
                    <strong>
                      Please cancel the proposal to proceed with another vote.
                    </strong>
                  </>
                }
              />
              <div className={cn("section-inner")}>{revokeItems}</div>
            </div>
          )}
          {renderSelectedItems}
        </div>
      </main>
    </>
  );
};

export default VotingList;
