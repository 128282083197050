import React, { useState, useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";
import style from "../assets/scss/wait.module.scss";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { throttle } from "lodash";
import { Table } from "antd";

import LandingLayout from "../components/LandingLayout.jsx";

import IconWithText from "../components/voting/IconWithText.jsx";
// import VotingChartMini from "../components/voting/VotingChartMini.jsx";
import WaitVotingStatus from "../components/wait/WaitVotingStatus.jsx";
import OGHeader from "../components/wait/OGHeader.jsx";

import { web3Instance } from "../web3";
import { GovInitCtx } from "../contexts/GovernanceInitContext";

import AOS from "aos";
import "aos/dist/aos.css";

// SVG Resource
import {
  convertWeiToEther,
  loginAcc,
  // refineMemo,
  timeConverter,
} from "../util";
import { NETWORK, constants } from "../constants";

const cn = classNames.bind(style);

const Wait = () => {
  const { data } = useContext(GovInitCtx);
  const {
    waitBallotMemberOriginData,
    waitBallotBasicOriginData,
    authorityNames,
  } = data;
  const { t } = useTranslation();
  const [governanceData, setGovernanceData] = useState({
    proposals: 0,
    approved: 0,
    rejected: 0,
  });
  const [renderItems, setRenderItems] = useState([]);
  const [txHashItems, setTxHashItems] = useState([]);
  const [txHashLength, setTxHashLength] = useState(0);
  const [amount, setAmount] = useState(0);

  let investment = [];

  useEffect(() => {
    if (waitBallotMemberOriginData) {
      setWaitData();
    }
  }, [t, waitBallotMemberOriginData]);

  useEffect(() => {
    if (txHashLength === investment.length) {
      setTxHashItems(investment);
    }
  }, [txHashLength]);

  const setWaitData = () => {
    const renderItems = [];
    let amount = 0;
    let proposals = 0;
    let approved = 0;
    let rejected = 0;

    Object.values(waitBallotMemberOriginData).map((memberData, index) => {
      const { state, id, creator, startTimeConverted, endTimeConverted } =
        waitBallotBasicOriginData[index + 1];
      const { companyName, txHashes } = memberData;

      // approved, rejected된 항목 갯수
      if (state === "3") approved++;
      else if (state === "4") rejected++;
      else return null;
      // 리스트 갯수
      proposals++;
      // txHashes가 있는 id를 배열로 저장
      if (txHashes.length) {
        try {
          txHashes.map((item) => {
            // 각 investment tx의 timestamp(block), value 값 가져오기
            web3Instance.web3.eth.getTransaction(item).then((tx) => {
              // 잘못된 tx 입력 시 그냥 넘기도록 수정
              if (tx === null) return;
              const { blockNumber, value } = tx;
              // approved txHash amount 값 다 더하기
              amount = amount + parseFloat(convertWeiToEther(value));

              web3Instance.web3.eth.getBlock(blockNumber).then((block) => {
                const { timestamp } = block;
                investment.push({
                  key: `${timestamp + Math.random(1, 100)}`,
                  proposal: {
                    title: companyName,
                    link: id,
                  },
                  transaction: {
                    hash: item,
                    link: `https://microscope.${
                      NETWORK === "mainnet" ? "" : "test."
                    }wemix.com/tx/${item}`, // explorer로 이동
                  },
                  investmentFund: convertWeiToEther(value),
                  date: timestamp,
                });
                // 최신순 정렬
                investment.sort((s, l) => l.date - s.date);
                // txHash 길이 저장
                setTxHashLength((prev) => prev + 1);
                // amount 값 저장
                setAmount(amount);
              });
            });
            return null;
          });
        } catch (err) {
          console.log(err);
        }
      }

      // 리스트 출력할 항목 배열로 저장
      renderItems.push(
        <li
          className={cn("voting-list-block-wrap")}
          key={`wait-voting-${index}`}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Link to={`/waitgov/detail?id=${id}`}>
            <div className={cn("wallet-info")}>
              <div className={cn("ncp-info")}>
                <span className={cn("ncp-name")}>
                  <span>{authorityNames.get(creator) || "-"}</span>
                </span>
                <span className={cn("ncp-address")}>{loginAcc(creator)}</span>
              </div>
              <WaitVotingStatus
                status={constants.ballotStateArr[state].toLowerCase()}
              />
            </div>
            <div className={cn("list-title-wrap")}>
              <strong className={cn("title")}>{companyName}</strong>
            </div>
            {/* [24.03.18] 리스트 페이지 내 리스트 상세 정보 텍스트 영역 삭제 */}
            {/* <div
              className={cn("list-desc-wrap")}
              dangerouslySetInnerHTML={{
                __html: refineMemo(description),
              }}
            ></div> */}
            <div className={cn("list-bottom-info")}>
              <IconWithText
                theme="dark"
                data={[
                  {
                    icon: "time",
                    text: `${startTimeConverted} ~ ${endTimeConverted}`,
                  },
                  // [24.03.18] 투표리스트의 현황 그래프 삭제 요청 반영 (추후 활성화될 가능성 고려하여 주석 처리)
                  // {
                  //   icon: "person",
                  //   // GD-102 | 40 WONDERS 로 고정
                  //   text: "40 WONDERS",
                  //   children: (
                  //     <VotingChartMini
                  //       data={{
                  //         yes: powers[1],
                  //         no: powers[2],
                  //       }}
                  //       theme="dark"
                  //     />
                  //   ),
                  // },
                ]}
              />
              {state === "3" && (
                <div
                  className={cn(
                    "tx-state",
                    `${txHashes.length ? "tx-complete" : "tx-before"}`,
                  )}
                >
                  {txHashes.length
                    ? `${t("wait.voting.list.progress.complete")}`
                    : `${t("wait.voting.list.progress.before")}`}
                </div>
              )}
            </div>
          </Link>
        </li>,
      );
      return null;
    });
    // 리스트 최신순 3개만 화면에 뿌림
    const items = renderItems.reverse();
    setRenderItems(items.slice(0, 3));
    setGovernanceData({
      ...governanceData,
      proposals,
      approved,
      rejected,
    });
  };

  return (
    <LandingLayout>
      {/* 23.04.28 수정: OG 추가 */}
      <OGHeader url="wait" />
      <h1 className={cn("a11y")}>WEMIX WAIT Protocol</h1>
      <main className={cn("landing-container")}>
        <WaitHero />
        <Protocol />
        <Structure />
        <Governance governanceData={governanceData} amount={amount} />
        <Investment txHashItems={txHashItems} />
        <VotingSection renderItems={renderItems} />
      </main>
    </LandingLayout>
  );
};

// Hero 영역
const WaitHero = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className={cn("wait-hero")}>
      <div
        className={cn("wait-inner")}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <h2 className={cn("title")}>{t("wait.header.title")}</h2>
        <p className={cn("description")}>{t("wait.header.description")}</p>
        <span className={cn("ico-scroll")}>
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_scroll.svg`}
            alt="ico_scroll"
          />
          SCROLL
        </span>
      </div>
    </section>
  );
};

// Protocol 영역
const Protocol = () => {
  const { t } = useTranslation();
  const imgList = [
    `${process.env.REACT_APP_URL}/assets/images/img_wait_step_1.png`,
    `${process.env.REACT_APP_URL}/assets/images/img_wait_step_2.png`,
    `${process.env.REACT_APP_URL}/assets/images/img_wait_step_3.png`,
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className={cn("wait-protocol")}>
      <div
        className={cn("wait-inner")}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <h3 className={cn("title")}>{t("wait.protocol.title")}</h3>
      </div>
      <ol className={cn("protocol-list")}>
        {Array.from({ length: 3 }, (_, index) => (
          <li key={`protocol-${index}`}>
            <span
              className={cn("tag")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {t(`wait.protocol.step${index + 1}.caption`)}
            </span>
            <div
              className={cn("img-wrap")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <img src={imgList[index]} alt="" />
            </div>
            <div
              className={cn("content")}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <strong className={cn("list-title")}>
                {t(`wait.protocol.step${index + 1}.title`)}
              </strong>
              <div className={cn("list-description")}>
                {t(`wait.protocol.step${index + 1}.description`)}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </section>
  );
};

// Structure 영역
const Structure = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className={cn("wait-structure")}>
      <div className={cn("half-inner")}>
        <div
          className={cn("title-wrap")}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h3 className={cn("title")}>{t("wait.structure.title")}</h3>
          <div className={cn("description")}>
            {t("wait.structure.description")}
          </div>
        </div>
        <div
          className={cn("img-wrap")}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_diagram.svg`}
            alt="ico_diagram"
          />
        </div>
      </div>
    </section>
  );
};

// Governance 영역
const Governance = ({ governanceData, amount }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className={cn("wait-governance")}>
      <div className={cn("wait-inner")}>
        <div
          className={cn("title-wrap")}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h3 className={cn("title")}>{t("wait.governance.title")}</h3>
          <div className={cn("description")}>
            {t("wait.governance.description", { n: governanceData.approved })}
          </div>
        </div>
        <dl
          className={cn("governance-list")}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <div>
            <dt>{t("wait.governance.index.amount")}</dt>
            <dd>
              <span className={cn("img-wrap")}>
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/ico_bi.svg`}
                  alt="ico_bi"
                />
              </span>
              <CountUp start={0.0} end={amount} duration={1} separator={","}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start}>
                    <span className={cn("number")} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.proposals")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.proposals}</span>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.approved")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.approved}</span>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.rejected")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.rejected}</span>
            </dd>
          </div>
        </dl>
      </div>
    </section>
  );
};

// Investment 영역
const Investment = ({ txHashItems }) => {
  const { t, i18n } = useTranslation();
  const [offset, setOffset] = useState({
    width: 0,
  });

  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
    });
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  const columnsData = [
    {
      title: t("wait.investment.column1"),
      dataIndex: "proposal",
      key: "proposal",
      align: "left",
      render: (_, { proposal }) => {
        return (
          <Link to={`/waitgov/detail?id=${proposal.link}`}>
            {/* 23.06.20 수정: arrow 아이콘 추가 */}
            <span>{proposal.title}</span>
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow.svg`}
              alt="ico_arrow"
            />
          </Link>
        );
      },
    },
    {
      title: t("wait.investment.column2"),
      dataIndex: "transaction",
      key: "transaction",
      align: "left",
      render: (_, { transaction }) => {
        return (
          <a
            href={transaction.link}
            target="_blank"
            rel="noopener noreferrer"
            title={i18n.language === "en" ? "Open new window" : "새창 열기"}
            className={cn("tx-hash-num")}
          >
            {/* 23.06.20 수정: arrow 아이콘 추가 */}
            <span>{loginAcc(transaction.hash)}</span>
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow.svg`}
              alt="ico_arrow"
            />
          </a>
        );
      },
    },
    {
      title: t("wait.investment.column3"),
      dataIndex: "investmentFund",
      key: "investmentFund",
      align: "left",
      render: (_, { investmentFund }) => {
        const float = parseFloat(investmentFund);
        return (
          <span className={cn("unit")}>
            {t(`wait.investment.column3-${float > 1 ? "1" : "2"}`, {
              investment: float.toLocaleString(),
            })}
          </span>
        );
      },
    },
    {
      title: t("wait.investment.column4"),
      dataIndex: "date",
      key: "date",
      align: "left",
      render: (_, { date }) => {
        return timeConverter(date);
      },
    },
  ];

  return (
    <section className={cn("wait-investment")}>
      <div
        className={cn("title-wrap")}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <h3 className={cn("title")}>{t("wait.investment.title")}</h3>
        {/* <span className={cn("date")}>2023-02-27 21:00 UTC+9</span> */}
      </div>
      {/* 23.06.20 수정: 투자 내역이 6개 이상일 경우 더보기 버튼 추가 */}
      <div
        className={cn("table-wrap", { "has-more": txHashItems.length > 5 })}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        {txHashItems.length ? (
          <Table
            pagination={false}
            columns={columnsData}
            dataSource={txHashItems.slice(0, 5)}
            scroll={offset.width < 960 ? { x: 960 } : undefined}
          />
        ) : (
          <p className={cn("empty-wrap")}>{t("wait.investment.emptyText")}</p>
        )}
        {/* 23.06.20 수정 start: 투자 내역이 6개 이상일 경우 더보기 버튼 추가 */}
        {txHashItems.length > 6 && (
          <div className={cn("table-btn-wrap")}>
            <a href={`/waitgov`}>
              {t("wait.investment.more")}
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_bottom.svg`}
                alt="ico_arrow_bottom"
              />
            </a>
          </div>
        )}
        {/* 23.06.20 수정 end: 투자 내역이 6개 이상일 경우 더보기 버튼 추가 */}
      </div>
    </section>
  );
};

const VotingSection = ({ renderItems }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className={cn("wait-voting")}>
      <div
        className={cn("title-wrap")}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <h3 className={cn("title")}>{t("wait.voting.title")}</h3>
        <span className={cn("date")}>
          {timeConverter(new Date() / 1000, true, true)}
        </span>
      </div>
      {renderItems.length ? (
        <ul className={cn("wait-voting-list-wrap")}>
          {renderItems}
          <li
            className={cn("wait-voting-list-more")}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <a href="/waitgov">
              <strong className={cn("title")}>
                {t("wait.voting.list.more")}
              </strong>
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_right.svg`}
                alt="ico_arrow_right"
              />
            </a>
          </li>
        </ul>
      ) : (
        <p
          className={cn("empty-wrap")}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {t("wait.voting.list.emptyText")}
        </p>
      )}
    </section>
  );
};

export default Wait;
