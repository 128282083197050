import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames/bind";
import style from "./PlatformContents.module.scss";

const cn = classNames.bind(style);

/**
 * 서버에서 내려오는 리스트는 아래와 같습니다. (대소문자 유의)
 * 'WEMIX' | 'WEMIX$' | 'WEMIX PLAY' | 'NILE' | 'WEMIX.Fi' | 'WEMIX Wallet' | '40 WONDERS' | 'WEMIX BURN' | 'Wepublic' | 'Eco Fund' | 'EXPLORER' | 'WEMIX Scan' | 'unagi' | 'una Wallet' | 'una Messenger'
 */

const PlatformContents = ({ theme = "dark", platformType }) => {
  const [isReady, setIsReady] = useState(false);
  const [linkMenuItems, setLinkMenuItems] = useState([[], []]);

  useEffect(() => {
    setIsReady(true);
    return () => setIsReady(false);
  }, []);

  const fetchPlatformContents = useCallback(async () => {
    try {
      const platformList = JSON.parse(
        window.localStorage.getItem("platformList"),
      ).filter((platform) => platform.title !== "EXPLORER");

      setLinkMenuItems([
        platformList.filter((d) => d.groupName === platformType && !d.disabled),
        platformList.filter((d) => d.groupName !== platformType && !d.disabled),
      ]);
    } catch (e) {
      setLinkMenuItems([]);
    }
  }, [setLinkMenuItems, platformType]);

  useEffect(() => {
    if (isReady) fetchPlatformContents();
  }, [isReady, fetchPlatformContents]);

  return (
    <div className={cn("platform-contents", theme)}>
      {linkMenuItems.map((platformItems, sectionIndex) => (
        <div key={sectionIndex} className={cn("platform-inner-section")}>
          {platformItems.map((item) => (
            <a
              href={item.link}
              key={item.id}
              target={item.current ? "_self" : "_blank"}
              title={item.current ? undefined : `${item.title} new window`}
              rel="noreferrer"
              className={cn(`platform-link`)}
            >
              <img
                src={item[`${theme}ImageUrl`]}
                alt=""
                // width={ICON_SIZE}
                // height={ICON_SIZE}
                className={cn(`platform-icon`)}
              />
              <span className={cn("platform-title")}>{item.title}</span>
            </a>
          ))}
        </div>
      ))}
    </div>
  );
};

export { PlatformContents };
