import React from "react";
import cn from "classnames/bind";

const Button = ({
  onClick,
  text,
  type,
  href,
  size = "md",
  disabled = false,
  prefix = false,
  icon = false,
}) => {
  return (
    <>
      {href !== undefined ? (
        <a
          href={href}
          className={cn("button-wrap", type, size, prefix && "prefix")}
        >
          {text}
        </a>
      ) : (
        <button
          className={cn("button-wrap", type, size, prefix && "prefix")}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && (
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_wallet.svg`}
              alt="ico_wallet"
            />
          )}{" "}
          {text}
        </button>
      )}
    </>
  );
};

export default Button;
