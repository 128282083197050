import React from "react";
import cn from "classnames/bind";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

const VotingTopProposal = ({
  loading,
  options,
  showProposal,
  selectedTopic,
  handleSelectTopicChange,
  isWhiteList,
}) => {
  const navitage = useNavigate();

  return (
    <div className={cn("voting-top-wrap")}>
      <div className={cn("inner")}>
        {!showProposal && (
          <button
            className={cn("btn-prev")}
            onClick={() => {
              navitage("/voting/list");
            }}
          >
            <span className={cn("arrow-left-white")}>left arrow</span>
          </button>
        )}
        <div className={cn("voting-title")}>
          <h2 className={cn("title")}>
            {showProposal ? "My Info" : "New Proposal"}
          </h2>
        </div>

        <div className={cn("voting-proposal-select")}>
          <span className={cn("select-label")}>
            {showProposal ? "Replace List" : "Topic for voting"}
          </span>
          <Select
            value={selectedTopic}
            filterOption={false}
            onChange={handleSelectTopicChange}
            disabled={loading}
            className={cn("voting-filter", "proposal")}
            suffixIcon={
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_select_arrow_drop_down.svg`}
                alt="ico_select_arrow_drop_down"
              />
            }
            dropDownClassName="proposal"
          >
            {options.map((item, i) => {
              // wait 안건 올리는 건 whitelist에 등록된 멤버만 가능
              if (item.value === "AddWaitProposal" && !isWhiteList) return null;
              return (
                <Select.Option value={item.value} key={i}>
                  {item.id}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default VotingTopProposal;
