import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames/bind";
import style from "./WaitVotingStatus.module.scss";

const cn = classNames.bind(style);

// Voting 영역
const WaitVotingStatus = ({ status }) => {
  const { t } = useTranslation();
  const statusIcon = useCallback(() => {
    switch (status) {
      case "approved":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_accepted.svg`}
            alt="ico_accepted"
          />
        );
      case "rejected":
        return (
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_rejected.svg`}
            alt="ico_rejected"
          />
        );
      default:
        return false;
    }
  }, [status]);
  return (
    <div className={cn("voting-status-dark", status)}>
      {statusIcon()}
      <span>{t(`waitGov.voting.list.status.${status}`)}</span>
    </div>
  );
};

export default WaitVotingStatus;
