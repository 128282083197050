import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";
import { Table } from "antd";

import classNames from "classnames/bind";
import style from "../assets/scss/governance.module.scss";

import { convertWeiToEther, loginAcc, timeConverter } from "../util";
import LandingLayout from "../components/LandingLayout.jsx";
import OGHeader from "../components/wait/OGHeader.jsx";
import {
  DetailTopLayout,
  DetailContentLayout,
} from "../components/wait/WaitDetailLayout.jsx";
import TableFilter from "../components/governance/TableFilter.jsx";
import TablePagination from "../components/governance/TablePagination";

import { web3Instance } from "../web3";
import { GovInitCtx } from "../contexts/GovernanceInitContext";
import { NETWORK } from "../constants.js";

const cn = classNames.bind(style);

const GovernanceHistory = () => {
  const { t, i18n } = useTranslation();
  const { data } = useContext(GovInitCtx);
  const { waitBallotMemberOriginData, waitBallotBasicOriginData } = data;

  const [isFilter, setIsFilter] = useState(0);
  const [defaultFilterData, setDefaultFilterData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [offset, setOffset] = useState({
    width: 0,
  });
  const [filterNames, setFilterNames] = useState([]);
  const [initFilterNames, setInitFilterNames] = useState(false);
  const [txHashItems, setTxHashItems] = useState([]);
  const [visibleTxHashItems, setVisibleTxHashItems] = useState([]);
  const [visibleTxHashItemsLength, setVisibleTxHashItemsLength] = useState(0);
  const [amount, setAmount] = useState(0);
  const [totals, setTotals] = useState({
    proposals: 0,
    transfers: 0,
  });
  const [filteringLength, setFilteringLength] = useState(0);
  const defaultPageSize = 10;
  const readOnlyFilterNames = useRef([]); // 필터 검색 기능 저장용

  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
    });
  }, []);

  const pageChange = (pageNum) => {
    setPageNum(pageNum);
    const prev = pageNum - 1 < 1 ? 0 : pageNum - 1;
    const filters = txHashItems.filter((txHash) =>
      defaultFilterData.includes(txHash.proposal.title),
    );
    setVisibleTxHashItems(
      !defaultFilterData.length ||
        defaultFilterData.length === filterNames.length
        ? txHashItems.slice(defaultPageSize * prev, defaultPageSize * pageNum)
        : filters.slice(defaultPageSize * prev, defaultPageSize * pageNum),
    );
  };

  useEffect(() => {
    resize();

    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  useEffect(() => {
    if (waitBallotMemberOriginData) {
      setHistoryData();
    }
  }, [waitBallotMemberOriginData]);

  const setHistoryData = async () => {
    let investment = [];
    let visibleInvestment = [];
    let amount = 0;
    let proposals = 0;
    let transfers = 0;

    Object.values(waitBallotMemberOriginData).map((memberData, index) => {
      const { id } = waitBallotBasicOriginData[index + 1];
      const { companyName, txHashes } = memberData;

      // 리스트 갯수
      proposals++;
      // txHashes가 있는 id를 배열로 저장
      if (txHashes.length) {
        // filter 이름 저장용
        if (!initFilterNames) {
          setFilterNames((prev) => {
            const _filterNames = prev;
            _filterNames.push({
              label: companyName,
              value: companyName,
            });
            return _filterNames;
          });
        }
        try {
          txHashes.map(async (item) => {
            transfers++;
            // 각 investment tx의 timestamp(block), value 값 가져오기
            const tx = await web3Instance.web3.eth.getTransaction(item);
            // 잘못된 tx 입력 시 그냥 넘기도록 수정
            if (tx === null) return;
            const { blockNumber, value } = tx;
            // approved txHash amount 값 다 더하기
            amount = amount + parseInt(convertWeiToEther(value));

            const { timestamp } = await web3Instance.web3.eth.getBlock(
              blockNumber,
            );
            investment.push({
              key: `${timestamp + Math.random(1, 100)}`,
              proposal: {
                title: companyName,
                link: id,
              },
              transaction: {
                hash: item,
                link: `https://microscope.${
                  NETWORK === "mainnet" ? "" : "test."
                }wemix.com/tx/${item}`, // explorer로 이동
              },
              investmentFund: convertWeiToEther(value),
              date: timestamp,
            });
            visibleInvestment.push({
              key: `${timestamp + Math.random(1, 100)}`,
              proposal: {
                title: companyName,
                link: id,
              },
              transaction: {
                hash: item,
                link: `https://microscope.${
                  NETWORK === "mainnet" ? "" : "test."
                }wemix.com/tx/${item}`, // explorer로 이동
              },
              investmentFund: convertWeiToEther(value),
              date: timestamp,
            });

            // 최신순 정렬
            investment.sort((s, l) => l.date - s.date);
            visibleInvestment.sort((s, l) => l.date - s.date);
            if (visibleInvestment.length > defaultPageSize) {
              visibleInvestment.pop();
            }
            // txHash 길이 저장
            // setTxHashLength((prev) => prev + 1);
            // amount 값 저장
            setAmount(amount);
          });
        } catch (err) {
          console.log(err);
        }
      }

      return null;
    });

    setInitFilterNames(true);
    setTotals({
      ...totals,
      proposals,
      transfers,
    });
    setTxHashItems(investment);
    setVisibleTxHashItems(visibleInvestment);
    setFilteringLength(transfers);
  };

  // 초기에 filterList 모두 선택된 상태로 출력
  useEffect(() => {
    if (initFilterNames) {
      const _filterData = [];
      filterNames.map((name) => {
        _filterData.push(name.value);

        return null;
      });
      readOnlyFilterNames.current = filterNames;
      setDefaultFilterData(_filterData);
    }
  }, [initFilterNames, filterNames]);

  // 필터 검색 기능
  const onFilterInputChange = (e) => {
    const v = e.target.value.toLowerCase();
    const filtered = v
      ? readOnlyFilterNames.current.filter(
          (name) => name.value.toLowerCase().indexOf(v) > -1,
        )
      : readOnlyFilterNames.current;
    setFilterNames(filtered);
    // 검색된 필터대로 리스트 뿌리기
    const names = filtered.map((name) => {
      return name.value;
    });
    onFilterChange(names);
  };

  const onFilterChange = (target) => {
    // 선택된 filters에 맞는 transfer만 가져오기
    const filters = txHashItems.filter((txHash) =>
      target.includes(txHash.proposal.title),
    );
    setIsFilter(target.length);
    setDefaultFilterData(target);
    setVisibleTxHashItems(
      !target.length || target.length === readOnlyFilterNames.length
        ? txHashItems.slice(0, defaultPageSize)
        : filters.slice(0, defaultPageSize),
    );
    setVisibleTxHashItemsLength(
      !target.length || target.length === readOnlyFilterNames.length
        ? defaultPageSize
        : filters.length,
    );
    setPageNum(1);
    setFilteringLength(
      !target.length || target.length === readOnlyFilterNames.length
        ? txHashItems.length
        : filters.length,
    );
  };

  const columnsData = [
    {
      title: t("waitInvest.table.column1"),
      dataIndex: "proposal",
      key: "proposal",
      align: "left",
      className: "proposal",
      width: "33.333%",
      render: (_, { proposal }) => {
        return (
          <Link to={`/waitgov/detail?id=${proposal.link}`}>
            <span>{proposal.title}</span>
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow.svg`}
              alt="ico_arrow"
            />
          </Link>
        );
      },
    },
    {
      title: t("waitInvest.table.column2"),
      dataIndex: "transaction",
      key: "transaction",
      align: "left",
      className: "transaction",
      width: "25%",
      render: (_, { transaction }) => {
        return (
          <a
            href={transaction.link}
            target="_blank"
            rel="noopener noreferrer"
            title={i18n.language === "en" ? "Open new window" : "새창 열기"}
            className={cn("tx-hash-num")}
          >
            <span>{loginAcc(transaction.hash)}</span>
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow.svg`}
              alt="ico_arrow"
            />
          </a>
        );
      },
    },
    {
      title: t("waitInvest.table.column3"),
      dataIndex: "investmentFund",
      key: "investmentFund",
      align: "right",
      className: "investment-fund",
      width: "25%",
      render: (_, { investmentFund }) => {
        const float = parseFloat(investmentFund);
        return (
          <span className={cn("unit")}>
            {t(`wait.investment.column3-${float > 1 ? "1" : "2"}`, {
              investment: float.toLocaleString(),
            })}
          </span>
        );
      },
    },
    {
      title: t("waitInvest.table.column4"),
      dataIndex: "date",
      key: "date",
      align: "right",
      className: "date",
      width: "16.666%",
      render: (_, { date }) => {
        return timeConverter(date);
      },
    },
  ];

  return (
    <LandingLayout>
      <OGHeader url="governance" />
      <DetailTopLayout theme="dark">
        <div className={cn("title-wrap")}>
          <h2 className={cn("title")}>{t("waitInvest.header.title")}</h2>
          <div className={cn("description")}>
            <p>{t("waitInvest.header.description")}</p>
            <p className={cn("caption")}>{t("waitInvest.header.annotation")}</p>
          </div>
        </div>
        <dl className={cn("governance-top-list", "history-list")}>
          <div>
            <dt>{t("waitInvest.header.index.funds")}</dt>
            <dd>
              <span className={cn("img-wrap")}>
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/ico_bi.svg`}
                  alt="ico_bi"
                />
              </span>
              <span className={cn("number")}>{amount.toLocaleString()}</span>
            </dd>
          </div>
          <div>
            <dt>{t("waitInvest.header.index.proposals")}</dt>
            <dd>
              <span className={cn("number")}>{totals.proposals}</span>
            </dd>
          </div>
          <div>
            <dt>{t("waitInvest.header.index.transfers")}</dt>
            <dd>
              <span className={cn("number")}>{totals.transfers}</span>
            </dd>
          </div>
        </dl>
      </DetailTopLayout>
      <DetailContentLayout theme="dark">
        <TableFilter
          filterData={filterNames}
          isFiltering={isFilter}
          onFilterInputChange={onFilterInputChange}
          onFilterChange={onFilterChange}
          defaultFilterData={defaultFilterData}
          total={filteringLength}
        />
        <div className={cn("history-table-wrap")}>
          <Table
            columns={columnsData}
            dataSource={visibleTxHashItems}
            scroll={offset.width < 744 ? { x: 680 } : undefined}
            pagination={false}
          />
          <TablePagination
            pageChangeActive={pageChange}
            activate={pageNum}
            total={
              !defaultFilterData.length ||
              defaultFilterData.length === filterNames.length
                ? txHashItems.length
                : visibleTxHashItemsLength
            }
            defaultPageSize={defaultPageSize}
          />
        </div>
      </DetailContentLayout>
    </LandingLayout>
  );
};

export default GovernanceHistory;
