import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames/bind";
import style from "./WaitWondersVoteBoard.module.scss";
import { Tooltip } from "antd";
import { throttle } from "lodash";

const cn = classNames.bind(style);

const WondersVoteBoard = ({ state, allList, voteData }) => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState({
    width: 0,
    height: 0,
  });

  const checkVote = useCallback(
    (item) => {
      // proposal 준비 상태일 때 vote 정보가 없으므로 다 default 로 내려줌
      if (!voteData.yes && !voteData.no) return "default";

      const checkYes = voteData.yes.map((data) =>
        data === item.voter || data === item.staker ? true : false,
      );
      const checkNo = voteData.no.map((data) =>
        data === item.voter || data === item.staker ? true : false,
      );
      if (checkYes.includes(true)) {
        return "yes";
      } else if (checkNo.includes(true)) {
        return "no";
        // mainbp (할당되지 않은 노드가 아닌데 투표를 안 한 경우) / 투표가 진행 중이거나 준비 상태일 경우에는 기권 표기 안함
      } else if (
        (state === "Approved" || state === "Rejected") &&
        !item.name.includes("mainbp")
      ) {
        return "abstain";
      }

      return "default";
    },
    [allList, voteData],
  );

  // 원더 파트너사가 아닌 경우 툴팁 비노출 조건 적용
  const checkNcp = (name) => {
    return !name.includes("mainbp");
  };

  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  return (
    allList &&
    voteData && (
      <>
        <ul className={cn("wonders-vote-board")}>
          {allList.map((item, index) => (
            /** class 매칭 예시
             * yes: yes
             * no: no
             * abstain: abstain
             */
            <li
              key={`wonder-vote-${item.id}`}
              className={cn("vote-board", item.id && checkVote(item))}
            >
              {/* 투표를 하지 않은 상태인데 ncp로 할당되지 않은 노드일 경우 숫자로 표기 */}
              {/* [24.03.06] 원더 파트너사가 아닌 경우 툴팁 비노출 조건 적용 */}
              {checkNcp(item.name) ? (
                // 파트너사인 경우 : 파트너사 로고 + 툴팁 구조 포함
                <Tooltip
                  placement={"top"}
                  title={
                    <>
                      <span>WONDER {index + 1}</span>
                      <strong>{item.name}</strong>
                    </>
                  }
                  trigger={offset.width < 1120 ? "click" : "hover"}
                >
                  <div className={cn("img-wrap")}>
                    {checkVote(item) === "default" &&
                    item.name.includes("mainbp") ? (
                      index + 1
                    ) : (
                      <img src={item.img} alt={item.name} />
                    )}
                    <span className={cn("a11y")}>
                      {checkVote(item) !== "default" ? checkVote(item) : ""}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                // 그 외 : 위메이드 로고 또는 숫자의 경우 툴팁 구조 비포함
                <div className={cn("img-wrap")}>
                  {checkVote(item) === "default" &&
                  item.name.includes("mainbp") ? (
                    index + 1
                  ) : (
                    <img src={item.img} alt={item.name} />
                  )}
                  <span className={cn("a11y")}>
                    {checkVote(item) !== "default" ? checkVote(item) : ""}
                  </span>
                </div>
              )}
              <div className={cn("vote-info")}>
                <span className={cn("icon")} />
              </div>
            </li>
          ))}
        </ul>
        <div className={cn("vote-legend")} aria-hidden={true}>
          <ul>
            <li className={cn("yes")}>
              {t("votingDetail.votingBoard.legend.yes")}
            </li>
            <li className={cn("no")}>
              {t("votingDetail.votingBoard.legend.no")}
            </li>
            <li className={cn("abstain")}>
              {t("votingDetail.votingBoard.legend.abstain")}
            </li>
            <li className={cn("none")}>
              {t("votingDetail.votingBoard.legend.vacant")}
            </li>
          </ul>
        </div>
      </>
    )
  );
};

export default WondersVoteBoard;
