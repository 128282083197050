import React, { useEffect, useState, createContext } from "react";
import { useLocation } from "react-router-dom";

const LandingDataStateContext = createContext(null);

const LandingDataProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [isLandingDataReady, setIsLandingDataReady] = useState(false);
  const [isLandingDataError, setIsLandingDataError] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [wondersList, setWondersList] = useState([]);
  const [addressNcpInfo, setAddressNcpInfo] = useState({});

  useEffect(() => {
    const fetchLandingData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_RESOURCES_STORAGE_API_URL}/storage/landing-data-resources/landing-data.json`,
        );
        const partnersResponseData = await response.json();

        const wondersList = Array.from({ length: 40 }, (_, i) => ({
          number: i + 1,
        }));

        const partnerList = partnersResponseData.partnersData;

        wondersList.forEach((_, idx) => {
          if (!partnerList[idx]) return;

          partnerList[idx] = {
            ...partnerList[idx],
            logo: <img src={partnerList[idx].logoImgURL} alt="logo" />,
            backNumberImg: (
              <img src={partnerList[idx].backNumberImgURL} alt="partner-no" />
            ),
          };

          if (partnerList[idx].number) {
            wondersList[partnerList[idx].number - 1] = partnerList[idx];
          }
        });

        setPartnerList(partnerList);
        setWondersList(wondersList);
        setAddressNcpInfo(partnersResponseData.addressNcpInfo);

        setIsLandingDataReady(true);

        if (isLandingDataError) {
          setIsLandingDataError(false);
        }
      } catch (e) {
        setIsLandingDataError(true);
      }
    };

    if (!isLandingDataReady) {
      fetchLandingData();
    }
  }, [pathname]);

  return (
    <LandingDataStateContext.Provider
      value={{
        isLandingDataReady,
        isLandingDataError,
        partnerList,
        wondersList,
        addressNcpInfo,
      }}
    >
      {children}
    </LandingDataStateContext.Provider>
  );
};

export { LandingDataStateContext, LandingDataProvider };
