import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import { GovInitProvider } from "./contexts/GovernanceInitContext";
import { ModalProvider } from "./contexts/ModalContext";

import App from "./App";
import "./index.css";
import Landing from "./landing";
import "./i18n";

import WalletConnector from "./components/WalletConnector";
import Privacy from "./pages/policy/privacy";
import Terms from "./pages/policy/terms";
import CaliforniaPrivacy from "./pages/policy/california-privacy";
import CookiePolicy from "./pages/policy/cookie-policy";
import { LandingDataProvider } from "./contexts/LandingDataContext";

const paths = [
  "/app",
  "/voting/list",
  "/voting/detail",
  "/voting/proposal",
  "/rewards",
  "/my-info",
];

const renderApp = () => (
  <BrowserRouter>
    <ModalProvider>
      <LandingDataProvider>
        <GovInitProvider>
          <WalletConnector>
            <Routes>
              <Route path="/" element={<Landing />}></Route>
              {/* web3 init - App component로 보냄 */}
              {process.env.REACT_APP_EXPOSURE === "true" &&
                paths.map((path) => (
                  <Route key={path} path={path} element={<App />}></Route>
                ))}
              <Route key="/gov" path="/gov" element={<App />}></Route>
              <Route
                key="/gov/detail"
                path="/gov/detail"
                element={<App />}
              ></Route>
              <Route key="/wait" path="/wait" element={<App />}></Route>
              <Route key="/waitgov" path="/waitgov" element={<App />}></Route>
              <Route
                key="/waitgov/detail"
                path="/waitgov/detail"
                element={<App />}
              ></Route>
              {/* 23.06.20 수정: wait 투자 내역 페이지 추가 */}
              {/* <Route key="/history" path="/history" element={<App />}></Route> */}
              {/* 2022.12.05 수정: 로딩 화면 확인용 url
      <Route path="/loading" element={<Loading />}></Route> */}
              {/* Replace route when entering a different route */}
              <Route
                key="/policy/terms"
                path="/policy/terms"
                element={<Terms />}
              ></Route>
              <Route
                key="/policy/privacy"
                path="/policy/privacy"
                element={<Privacy />}
              ></Route>
              <Route
                key="/ko/policy/california-privacy"
                path="/ko/policy/california-privacy"
                element={<CaliforniaPrivacy />}
              ></Route>
              <Route
                key="/policy/california-privacy"
                path="/policy/california-privacy"
                element={<CaliforniaPrivacy />}
              ></Route>
              <Route
                key="/ko/policy/cookie-policy"
                path="/ko/policy/cookie-policy"
                element={<CookiePolicy />}
              ></Route>
              <Route
                key="/policy/cookie-policy"
                path="/policy/cookie-policy"
                element={<CookiePolicy />}
              ></Route>
              <Route path="/*" element={<Navigate replace to="/" />}></Route>
            </Routes>
          </WalletConnector>
        </GovInitProvider>
      </LandingDataProvider>
    </ModalProvider>
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(renderApp(), rootElement);
} else {
  render(renderApp(), rootElement);
}
// ReactDOM.render(renderApp(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
