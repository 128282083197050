import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import useClickOutside from "../platform/useClickOutside";
import classNames from "classnames/bind";
import style from "./SelectDropdown.module.scss";

const cn = classNames.bind(style);

const SelectDropdown = ({ handleChange }) => {
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const optionRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("all");
  useClickOutside(selectRef, () => setIsOpen(false));
  const handleChangeSelected = (value) => {
    setSelected(value);
    setIsOpen(!isOpen);

    handleChange(value);
  };
  const optionList = [
    {
      name: t("governance.list.filter1"),
      value: "all",
    },
    {
      name: t("governance.list.filter2"),
      value: "inprogress",
    },
    {
      name: t("governance.list.filter3"),
      value: "ready",
    },
    {
      name: t("governance.list.filter4"),
      value: "approved",
    },
    {
      name: t("governance.list.filter5"),
      value: "rejected",
    },
  ];

  return (
    <div className={cn("wrap")} ref={selectRef}>
      <button
        type="button"
        className={cn("selected-btn", { active: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <span className={cn("title")}>
          {optionList[optionList.findIndex((el) => el.value === selected)].name}
        </span>
        <span className={cn("arrow")}>󺀣</span>
      </button>
      <CSSTransition
        in={isOpen}
        appear
        timeout={{
          appear: 0,
          enter: 0,
          exit: 300,
        }}
        unmountOnExit
        nodeRef={optionRef}
        classNames={{
          enterDone: cn("select-list-enter"),
          exit: cn("select-list-exit"),
        }}
      >
        <ul
          className={cn("option-list")}
          role="listbox"
          aria-hidden={!isOpen}
          ref={optionRef}
        >
          {optionList.map(({ name, value }) => (
            <li key={name}>
              <button
                className={cn("option-list-btn", {
                  active: value === selected,
                })}
                aria-pressed={value === selected}
                onClick={() => handleChangeSelected(value)}
              >
                <span className={cn("label")}>{name}</span>
                {value === selected && <span className={cn("icon")}>󺀢</span>}
              </button>
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default SelectDropdown;
