import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import style from "./WaitDetailLayout.module.scss";

const cn = classNames.bind(style);

const DetailTopLayout = ({ theme = "light", children, prev = false }) => {
  const navigate = useNavigate();
  return (
    <div className={cn("layout-top", `${theme}`)}>
      <div className={cn("inner")}>
        {prev && (
          <button className={cn("btn-prev")} onClick={() => navigate(-1)}>
            <span className={cn("arrow-left-black")}>left arrow</span>
            <span className={cn("a11y")}>이전 화면으로 이동</span>
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

const DetailContentLayout = ({ theme = "light", children }) => {
  return (
    <main className={cn("detail-container", `${theme}`)}>
      <div className={cn("inner")}>{children}</div>
    </main>
  );
};

export { DetailTopLayout, DetailContentLayout };
