import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import style from "../../assets/scss/governance.module.scss";

import LandingLayout from "../../components/LandingLayout.jsx";
import OGHeader from "../../components/wait/OGHeader.jsx";
import { constants } from "../../constants";
import { web3Instance } from "../../web3";

import WaitGovernanceListBlock from "../../components/wait/WaitGovernanceListBlock.jsx";
import {
  DetailTopLayout,
  DetailContentLayout,
} from "../../components/wait/WaitDetailLayout.jsx";

import { asyncExecuteBatch, convertWeiToEther } from "../../util";
import { GovInitCtx } from "../../contexts/GovernanceInitContext";

const cn = classNames.bind(style);

const WaitGovernance = () => {
  const { data } = useContext(GovInitCtx);
  const {
    waitBallotMemberOriginData,
    waitBallotBasicOriginData,
    authorityNames,
  } = data;
  const { t } = useTranslation();
  const [governanceData, setGovernanceData] = useState({
    proposals: 0,
    approved: 0,
    rejected: 0,
  });
  const [renderItems, setRenderItems] = useState([]);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setTxHashData();
  }, [waitBallotMemberOriginData]);

  const setTxHashData = async () => {
    if (waitBallotMemberOriginData) {
      const renderItems = [];
      let amount = 0;
      let proposals = 0;
      let approved = 0;
      let rejected = 0;

      const br = new web3Instance.web3.BatchRequest();

      Object.values(waitBallotMemberOriginData).map((memberData, index) => {
        const {
          state,
          id,
          creator,
          startTimeConverted,
          endTimeConverted,
          powers,
        } = waitBallotBasicOriginData[index + 1];
        const { companyName, description, txHashes } = memberData;

        // approved, rejected된 항목 갯수
        if (state === "3") {
          approved++;
          // txHashes가 있는 id를 배열로 저장
          if (txHashes.length) {
            try {
              txHashes.map((item) => {
                // 각 항목 별 txHashes batch로 가져오기
                br.add(web3Instance.web3.eth.getTransaction.request(item));

                return null;
              });
            } catch (err) {
              console.log(err);
            }
          }
        } else if (state === "4") rejected++;
        else return null;
        // 리스트 갯수
        proposals++;
        // 리스트 출력할 항목 배열로 저장
        renderItems.push(
          <WaitGovernanceListBlock
            key={`governance-list-${index}`}
            link={id}
            ncpName={authorityNames.get(creator) || "-"}
            ncpAddress={creator}
            title={companyName}
            description={description}
            state={constants.ballotStateArr[state].toLowerCase()}
            date={`${startTimeConverted} ~ ${endTimeConverted}`}
            person="40 WONDERS" // GD-102 | 40 WONDERS 로 고정
            chart={{
              yes: powers[1],
              no: powers[2],
            }}
            investmentState={txHashes.length ? "tx-complete" : "tx-before"}
          />,
        );

        return null;
      });

      const txHashes = await asyncExecuteBatch(br);
      // 각 investment tx의 timestamp(block), value 값 가져오기
      txHashes.map((tx) => {
        // 잘못된 tx 입력 시 그냥 넘기도록 수정
        if (tx === null) return null;
        const { value } = tx;
        // approved txHash amount 값 다 더하기
        amount = amount + parseInt(convertWeiToEther(value));
        // amount 값 저장
        setAmount(amount);

        return null;
      });

      // 리스트 최신순 정렬
      setRenderItems(renderItems.reverse());
      setGovernanceData({
        ...governanceData,
        proposals,
        approved,
        rejected,
      });
    }
  };

  return (
    <LandingLayout>
      {/* 23.04.28 수정: OG 추가 */}
      <OGHeader url="waitgov" />
      <DetailTopLayout theme="dark">
        <div className={cn("title-wrap")}>
          <h1 className={cn("title")}>{t("waitGov.header.title")}</h1>
          <div className={cn("description")}>
            <p>{t("waitGov.header.description")}</p>
            <p className={cn("caption")}>{t("waitGov.header.annotation")}</p>
          </div>
        </div>
        <dl className={cn("governance-top-list")}>
          <div>
            <dt>{t("wait.governance.index.amount")}</dt>
            <dd>
              <span className={cn("img-wrap")}>
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/ico_bi.svg`}
                  alt="ico_bi"
                />
              </span>
              <span className={cn("number")}>
                {/* wei 단위 => ether 단위로 변경 후 소수점 보이지 않도록 처리 */}
                {/* {parseInt(
                  convertWeiToEther(governanceData.amount.toString()),
                ).toLocaleString()} */}
                {amount.toLocaleString()}
              </span>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.proposals")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.proposals}</span>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.approved")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.approved}</span>
            </dd>
          </div>
          <div>
            <dt>{t("wait.governance.index.rejected")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.rejected}</span>
            </dd>
          </div>
        </dl>
      </DetailTopLayout>
      <DetailContentLayout theme="dark">
        {governanceData.proposals ? (
          <ul className={cn("governance-voting-list")}>{renderItems}</ul>
        ) : (
          <div className={cn("no-data")}>{t("wait.voting.list.emptyText")}</div>
        )}
      </DetailContentLayout>
    </LandingLayout>
  );
};

export default WaitGovernance;
