// ! 사용하는 contract address: Imp가 아닌 address, 사용하는 abi 파일: Imp abi 파일
// https://40wonders.test.wemix.com (testnet)
const TESTNET_CONTRACTS = [
  {
    name: "GovGatewayImp",
    address: "0xCCBbD3407c5639D06bB965cC77848183e9Cb3e01",
  },
  { name: "Registry", address: "0xA68A135Ccd37E720000fC30CFcc453b15F8040df" },
  {
    name: "WaitBallotStorage",
    address: "0x55469A0436E71c1739d50764e6824E9218A31d71",
  },
  {
    name: "WaitGovernance",
    address: "0xef49F4B544065DCc2D990594a4EF6C4b6BE7ef30",
  },
  {
    name: "NCPStakingGateway",
    address: "0xA3B76b17CeF3C634397ff60030a970D0eD89b423",
  },
  {
    name: "NCPStaking",
    address: "0x64d2ccd2C4c7aC869b9f776CbC7b4d6c6fdc6022",
  },
];
// https://40wonders.wemix.com (mainnet)
const MAINNET_CONTRACTS = [
  {
    name: "GovGatewayImp",
    address: "0x489fd7832EA8f3C636f78D2948981CFE784b07a8",
  },
  { name: "Registry", address: "0x2e051a657014024f3e6099fbf3931f8dc14ef0f8" },
  {
    name: "WaitBallotStorage",
    address: "0x9B104D4730c10f07A7D1d82dEDBFe9DE142569A8",
  },
  {
    name: "WaitGovernance",
    address: "0x631FE9c57de28dDC2642ea70005fb61e14f0374D",
  },
  {
    name: "NCPStakingGateway",
    address: "0xf421e57180215af5A1D9ADA50eC7449e2CcE77B6",
  },
  {
    name: "NCPStaking",
    address: "0x6Af09e1A3c886dd8560bf4Cabd65dB16Ea2724D8",
  },
];

// wemix chain info
const TESTNET_CHAIN_INFO = {
  chainId: "0x458",
  chainName: "Wemix Testnet",
  rpcUrls: "https://api.test.wemix.com",
  serviceRpcUrls: "https://api.test.wemix.com",
  blockExplorerUrls: "https://testnet.wemixscan.com",
  name: "WEMIX",
  decimals: 18,
  symbol: "WEMIX",
  apiUrl: "https://devapi.wemix.fi",
};
const MAINNET_CHAIN_INFO = {
  chainId: "0x457",
  chainName: "Wemix Mainnet",
  rpcUrls: "https://api.wemix.com",
  serviceRpcUrls: "https://api-wemix.wemix.com", // MAINNET만 해당
  blockExplorerUrls: "https://wemixscan.com",
  name: "WEMIX",
  decimals: 18,
  symbol: "WEMIX",
  apiUrl: "https://api.wemix.fi",
};

// ---------------- env 관련 내용 ----------------
// proposal list
export const ENV_VOTING_PROPOSAL_LIST = [
  { id: "Add Authority Member", value: "AddAuthorityMember" },
  { id: "Replace Authority Member", value: "ReplaceAuthorityMember" },
  { id: "Remove Authority Member", value: "RemoveAuthorityMember" },
  { id: "Governance Contract Address", value: "GovernanceContractAddress" },
  {
    id: "Wonder Staking Upgrade",
    value: "WonderStakingUpgrade",
  },
  {
    id: "Voting Duration Setting",
    value: "VotingDurationSetting",
    sha3Name:
      "0xe10074dceffb75f13bf0ce50145afd35182d63796823f1280ce40e01c19109e7",
  },
  {
    id: "Authority Member Staking Amount",
    value: "AuthorityMemberStakingAmount",
    sha3Name:
      "0x6c6f69f426081752a5d3e73746599acd2a4cb145d5de4203ca1e3473b281680b",
  },
  {
    id: "Block Creation Time",
    value: "BlockCreationTime",
    sha3Name:
      "0x8086da5becff4dfac91a3105821b361078d2d4abba0ccc2401b974cf0dcf05c1",
  },
  {
    id: "Block Reward Amount",
    value: "BlockRewardAmount",
    sha3Name:
      "0x89dd490ecaf395283ed4ff2fd9557ca767fc425dce063451a9b0da6d72f600c3",
  },
  {
    id: "Block Reward Distribution Method",
    value: "BlockRewardDistributionMethod",
    sha3Name:
      "0x9b2e0c7fdae148f225bae7deb92d7e7bd24bb77edb12956e8fa7204900dd8a22",
  },
  {
    id: "MaxPriorityFeePerGas",
    value: "MaxPriorityFeePerGas",
    sha3Name:
      "0xbe90e461bbdb9a95a694f7796912ea04244caf7f5b60ad7ded17e16821d3e44c",
  },
  {
    id: "Gas Limit & baseFee",
    value: "GasLimitBaseFee",
    sha3Name:
      "0x04f7b94450bbcad85f37ea47cd1062728f884bb2040e357738f8fd53056134bc",
  },
  { id: "Add Wait Proposal", value: "AddWaitProposal" },
];
export const ENV_MY_INFO_PROPOSAL_LIST = [
  { id: "Voting Address", value: "VotingAddress" },
  { id: "Reward Address", value: "RewardAddress" },
  { id: "IP Address", value: "IPAddress" },
];
// parameter count
export const ENV_PARAMETER_COUNT = {
  "Voting Duration Setting": 2,
  "Authority Member Staking Amount": 2,
  "Block Creation Time": 1,
  "Block Reward Amount": 1,
  "Block Reward Distribution Method": 4,
  MaxPriorityFeePerGas: 1,
  "Gas Limit & baseFee": 4,
};
// envName
export const ENV_NAMES = {
  ENV_BALLOT_DURATION_MIN_MAX: "ballotDurationMinMax",
  ENV_STAKING_MIN_MAX: "stakingMinMax",
  ENV_BLOCK_CREATION_TIME: "blockCreationTime",
  ENV_BLOCK_REWARD_AMOUNT: "blockRewardAmount",
  ENV_BLOCK_REWARD_DISTRIBUTION: "blockRewardDistribution",
  ENV_MAX_PRIORITY_FEE_PER_GAS: "maxPriorityFeePerGas",
  ENV_GASLIMIT_AND_BASE_FEE: "gasLimitAndBaseFee",
};
// -----------------------------------------------

export const constants = {
  /* ControlEnums */
  expirationTime: 300000,
  /* AuthorityEnums */
  authoritieDescriptionHeight: 88,
  authoritieDescriptionHeightToPixel: "88px",
  /* BallotEnums */
  ballotDetailHeight: 124,
  ballotDetailHeightToPixel: "124px",
  ballotState: {
    Invalid: "0",
    Ready: "1",
    InProgress: "2",
    Approved: "3",
    Rejected: "4",
    Canceled: "5",
  },
  ballotTypes: {
    Invalid: "0",
    AddAuthorityMember: "1",
    RemoveAuthorityMember: "2",
    ReplaceAuthorityMember: "3",
    GovernanceContractAddress: "4",
    ChangedEnv: "5",
    WonderStakingUpgrade: "6",
  },
  ballotStateArr: [
    "Invalid",
    "Ready",
    "InProgress",
    "Approved",
    "Rejected",
    "Canceled",
  ],
  ballotTypesArr: [
    "Invalid",
    "Add Authority Member",
    "Remove Authority Member",
    "Replace Authority Member",
    "Governance Contract Address",
    "Changed Env",
    "Wonder Staking Upgrade",
  ],
  // /gov, /gov/detail replace authority member title 정의 용도
  // governance contract 변경 건도 적용
  ballotGovernanceNameArr: [
    "",
    "Welcoming Newest Node Council Partner",
    "Node Council Partner Withdrawal",
    "Welcoming Newest Node Council Partner",
    "WEMIX3.0 Governance Contract Update",
    "",
  ],
  /* Debug */
  debugMode: false,
};

export const walletTypes = {
  META_MASK: "injected",
  WALLET_CONNECT: "walletconnect",
  COIN_BASE: "walletlink",
};

// 현재 연결된 네트워크의 정보
export const NETWORK = process.env.REACT_APP_NETWORK_TYPE;
export const CHAIN_INFO =
  NETWORK === "mainnet" ? MAINNET_CHAIN_INFO : TESTNET_CHAIN_INFO;
export const CONTRACT_INFO =
  NETWORK === "mainnet" ? MAINNET_CONTRACTS : TESTNET_CONTRACTS;

export const NETWORK_SPECIFICATION = {
  id: parseInt(CHAIN_INFO.chainId),
  name: CHAIN_INFO.chainName,
  network: "homestead",
  nativeCurrency: {
    decimals: 18,
    name: CHAIN_INFO.name,
    symbol: CHAIN_INFO.symbol,
  },
  rpcUrls: {
    public: { http: [CHAIN_INFO.rpcUrls] },
    default: { http: [CHAIN_INFO.rpcUrls] },
  },
};

// web3Modal project id
export const PROJECT_ID = "1faac271d9fe1528c3014fbf28ebad55";

export const ADDRESS_NCP_INFO = {
  "0xD1CE79166bfbbF5E507c04f3Fe5163A4A5Aa09E4": {
    name: "Algorith Capital",
    img: "/img/ncp/algorith.png",
    id: 39,
  },
  "0x9AfC1C88A5FDe68eef7262cf0b7841986630df55": {
    name: "Dsrv",
    img: "/img/ncp/dsrv.png",
    id: 3,
  },
  "0x42c55d8c8391369a30bcbe1fA50b237a3bDE79e8": {
    name: "WEMADE",
    img: "/img/ncp/wemade.png",
    id: 40,
  },
  "0x74a71557bcD8EeF790A18f2434a8A0792834Aeb7": {
    name: "Xangle",
    img: "/img/ncp/xangle.png",
    id: 5,
  },
  "0x9E97E304Ed4b9e9222842bB02C7E1FD79e18911d": {
    name: "Cosmostation",
    img: "/img/ncp/cosmostation.png",
    id: 20,
  },
  "0x474E571Ab6dd77489EC3C7DDF9CBc893FcbA684C": {
    name: "Allnodes",
    img: "/img/ncp/allnodes.png",
    id: 7,
  },
  "0x2cAee1F6b790f8D3Dc6701716916e78d6f4842B5": {
    name: "Presto Labs",
    img: "/img/ncp/prestolabs.png",
    id: 2,
  },
  "0xbcFAA364a3accBa323a7Eb42311995c1f562802B": {
    name: "B-Harvest",
    img: "/img/ncp/b-harvest.png",
    id: 38,
  },
  "0xa0cfa0D4c5eB90aA9D61358FD24b48BE6F1D92E2": {
    name: "Ozys",
    img: "/img/ncp/ozys.png",
    id: 8,
  },
  "0x90F1522d3039E46647FBBC7fFBcFa789C3F0B1E5": {
    name: "CPLabs",
    img: "/img/ncp/cplabs.png",
    id: 10,
  },
  "0xEc5966e57EEf90D03ED83854A4b095Ea7F07f396": {
    name: "Gdac",
    img: "/img/ncp/gdac.png",
    id: 22,
  },
  "0x60785b1BD73a681cC7b684265179d518FF44aB23": {
    name: "Blockdaemon",
    img: "/img/ncp/blockdaemon.png",
    id: 11,
  },
  "0x7aD5fFAA39d3638Fc775Bb118D1092cAA210FCbf": {
    name: "Figment",
    img: "/img/ncp/figment.png",
    id: 14,
  },
  "0x05475475EEB5EE27c8F86C917DB6FdC5557E21ce": {
    name: "Ankr",
    img: "/img/ncp/ankr.png",
    id: 6,
  },
  "0x8Eab8a3535b6C2715DcB3da026C2a1241F08B28D": {
    name: "Mblock",
    img: "/img/ncp/mblock.png",
    id: 15,
  },
  "0x247a949B5e2c7DA0C2F73C58AE51c0Cb97Ea35A9": {
    name: "WONDER DAO",
    img: "/img/ncp/wonderdao.png",
    id: 1,
  },
  "0x99C795fC9bBb2A6a0163A87a9034Ab33b1aFc751": {
    name: "Kakao Games",
    img: "/img/ncp/kakaogames.png",
    id: 21,
  },
  "0x44afbc739f51F4C9BA7e347CAAEf29E5FE79801B": {
    name: "Gumi",
    img: "/img/ncp/gumi.png",
    id: 17,
  },
  "0x6373C4d77414A017F767120840f3B3ed00868cDe": {
    name: "Allnodes",
    img: "/img/ncp/allnodes.png",
    id: 7,
  },
  "0x3B84f2d7b4740110c4A02d3d417F9E46F7bbf245": {
    name: "Gumi",
    img: "/img/ncp/gumi.png",
    id: 17,
  },
  "0xd308518db16F025Fdb9B8b3fa93a6e61b09F8507": {
    name: "B-Harvest",
    img: "/img/ncp/b-harvest.png",
    id: 38,
  },
  "0x2a26cb4e8D4668B3Bb4fb895A412Eff00Ad8528a": {
    name: "Klaytn",
    img: "/img/ncp/klaytn.png",
    id: 23,
  },
  "0xD943401Bac53A97b08d8b95C2D23d07Cb6f2c609": {
    name: "Hashkey Cloud",
    img: "/img/ncp/hashkeycloud.png",
    id: 16,
  },
  "0x79474d443CB3B4c3f61bBd97E71C7741aE1C13AE": {
    name: "CertiK",
    img: "/img/ncp/certik.png",
    id: 9,
  },
  "0xF0dDF8A7d9609a63faC56963d757d82A18FBC843": {
    name: "Hwik",
    img: "/img/ncp/hwik.png",
    id: 33,
  },
  "0xB38BC67e37e30153b205Ea1882A981171b24A2cA": {
    name: "Ubisoft",
    img: "/img/ncp/ubisoft.png",
    id: 26,
  },
  "0x9F86e74d26611a3E99EEEf9Aff43aE2B559Cd073": {
    name: "Verichains",
    img: "/img/ncp/verichains.png",
    id: 12,
  },
  "0xADa0974656d69104455B8E950A2B2bC2a61E4ca7": {
    name: "factblock",
    img: "/img/ncp/factblock.png",
    id: 13,
  },
  "0xf4d4Dc80C10548e0A587881C9885dD97f7A98435": {
    name: "Sygnum",
    img: "/img/ncp/sygnumbank.png",
    id: 18,
  },
  "0xC4524898D6C807835ddC3D9b7dC06101c7bE3889": {
    name: "XLGAMES",
    img: "/img/ncp/xlgames.png",
    id: 24,
  },
  "0xA989C79e3dDC0b94F1bddc3c112f35d8d781578c": {
    name: "Kroma",
    img: "/img/ncp/kroma.png",
    id: 4,
  },
  "0xc2016F173D8006D3dbed22EdD082852b492EA0Ea": {
    name: "MADNGINE",
    img: "/img/ncp/madngine.png",
    id: 25,
  },
  "0xfB40Fa4CbF1aB30184641D98b2D332dcD0d5bbE7": {
    name: "SOOHO.IO",
    img: "/img/ncp/sooho.png",
    id: 36,
  },
};

// 퇴출 당한 NCP address 리스트 (gdac)
export const EXITED_NCP_LIST = ["0xEc5966e57EEf90D03ED83854A4b095Ea7F07f396"];

// ! state ready 상태일 경우 inprogress 로 수정 (투표 날짜도 하드 코딩해서 박음)
export const VOTING_DURATION_SETTING = {
  57: {
    // replace authority member (Ubisoft) - add
    count: "WGP-202401081",
    startTime: new Date("2024-01-08T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-01-08",
    endTime: new Date("2024-01-15T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-01-15",
  },
  58: {
    // replace authority member (Verichains) - add
    count: "WGP-202401121",
    startTime: new Date("2024-01-12T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-01-12",
    endTime: new Date("2024-01-17T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-01-17",
  },
  61: {
    // replace authority member (Factblock) - add
    count: "WGP-202401161",
    startTime: new Date("2024-01-16T03:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-01-16",
    endTime: new Date("2024-01-19T03:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-01-19",
  },
  64: {
    // replace authority member (Sygnum Bank) - add
    count: "WGP-202401261",
    startTime: new Date("2024-01-26T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-01-26",
    endTime: new Date("2024-01-31T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-01-31",
  },
  65: {
    // governance contract update
    count: "WGP-202401311",
    startTime: new Date("2024-01-31T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-01-31",
    endTime: new Date("2024-02-05T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-02-05",
  },
  67: {
    // replace authority member (GDAC) - remove
    count: "WGP-202402061",
    startTime: new Date("2024-02-06T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    startTimeConverted: "2024-02-06",
    endTime: new Date("2024-02-12T06:00Z").getTime() / 1000, // safari 대응 timezone 형식으로
    endTimeConverted: "2024-02-12",
  },
};

export const DESC_MAX_LENGTH = 30000;
