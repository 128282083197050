import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import style from "../../assets/scss/governance.module.scss";
import edStyle from "../../assets/scss/editor.module.scss";

import LandingLayout from "../../components/LandingLayout.jsx";
import WaitWondersVoteBoard from "../../components/wait/WaitWondersVoteBoard.jsx";
import {
  DetailTopLayout,
  DetailContentLayout,
} from "../../components/wait/WaitDetailLayout.jsx";
import WaitVotingStatus from "../../components/wait/WaitVotingStatus.jsx";
import VotingStickChart from "../../components/voting/VotingStickChart.jsx";
import OGHeader from "../../components/wait/OGHeader.jsx";
import { message } from "antd";
import {
  convertWeiToEther,
  loginAcc,
  refineMemo,
  timeConverter,
} from "../../util.js";
import { constants } from "../../constants.js";
import { GovInitCtx } from "../../contexts/GovernanceInitContext.jsx";
import { web3Instance } from "../../web3.js";

const cn = classNames.bind(style);

const WaitGovernanceDetail = () => {
  const { data, getNodeByTimestamp } = useContext(GovInitCtx);
  const {
    waitBallotMemberOriginData,
    waitBallotBasicOriginData,
    authorityNames,
    proposalMemberLength,
  } = data;
  const { t } = useTranslation();
  const [ballotMember, setBallotMemberData] = useState({});
  const [ballotBasic, setBallotBasicData] = useState({});
  const [nodeList, setNodeList] = useState([]);

  const [txHashItems, setTxHashItems] = useState([]);
  const [amount, setAmount] = useState({});
  const [link, setLink] = useState("");

  const [vote, setVote] = useState({});

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // 유효한 투표인지 확인
    const id = new URLSearchParams(search).get("id");

    const regex = /^[^0]\d*$/;
    if (id === null || !regex.test(id)) {
      openToast("Invalid Wait Governance ID.");
      navigate("/gov");
      return;
    }
    window.scrollTo(0, 0);

    getWaitGovernaceData(id);
  }, [search, navigate]);

  const openToast = (content) => {
    message.destroy();
    message.open({
      type: "warning",
      content,
      className: "voting-toast",
    });
  };

  const getWaitGovernaceData = async (id) => {
    const ballotBasic = Object.values(waitBallotBasicOriginData, "id").filter(
      (item) => item.id.toString() === id,
    )[0];
    const ballotMember = waitBallotMemberOriginData[id];

    // 투표 정보가 없을 경우 리스트로 보내기
    if (!ballotMember || !ballotBasic) {
      openToast("No Information about this Wait Governance ID.");
      navigate("/gov");
      return;
    }

    // 현재의 노드 리스트를 가져옴
    const { companyName, link, txHashes } = ballotMember;
    const getCount = companyName.slice(0, companyName.indexOf(":"));
    const name = companyName.slice(
      companyName.indexOf(":") + 2, // 띄어쓰기 고려
      companyName.length,
    );

    // node list 가져오기
    const { startTime } = ballotBasic;
    // getNodeTimestamp 시 timestamp로 localStorage에서 저장된 값을 가져오도록 수정헀기 때문에 new Date() 값은 함수 내부엥서 처리
    const date = startTime > 0 ? startTime : 0; //parseInt(new Date().getTime() / 1000); 투표 준비 상태일 경우 현재 시간으로 처리
    const nodeList = await getNodeByTimestamp(date);
    setNodeList(nodeList);

    // 위믹스 ether 단위로 출력
    const amount = convertWeiToEther(ballotMember.investmentAmount);
    // vote list 돌면서 index 안 맞는 거 맞추기
    // (40: 1번 WONDER DAO, 37: 40번 WEMADE, 1: 37번 노드, 나머지 동일)
    const setBallotVoters = (voters) => {
      const refineVoters = [];
      voters.map((voter) => {
        if (parseInt(voter) === 40) refineVoters.push(1);
        else if (parseInt(voter) === 37) refineVoters.push(40);
        else if (parseInt(voter) === 1) refineVoters.push(37);
        else refineVoters.push(parseInt(voter));
        return null;
      });
      return refineVoters;
    };
    const abstain = setBallotVoters(ballotBasic.abstain);
    const yes = setBallotVoters(ballotBasic.yes);
    const no = setBallotVoters(ballotBasic.no);
    const refineVote = {
      abstain,
      yes,
      no,
    };
    // link
    const httpsIdx = link.slice(0, 10);
    setLink(
      httpsIdx.includes("https://" || "http://") ? link : `https://${link}`,
    );

    // txHashes가 있는 id를 배열로 저장
    let investment = [];
    if (txHashes.length) {
      try {
        txHashes.map(async (item, index) => {
          // 각 investment tx의 timestamp(block), value 값 가져오기
          const tx = await web3Instance.web3.eth.getTransaction(item);
          // 잘못된 tx 입력 시 그냥 넘기도록 수정
          if (tx === null) return;
          const { blockNumber, value } = tx;
          const { timestamp } = await web3Instance.web3.eth.getBlock(
            blockNumber,
          );
          investment.push({
            key: `${timestamp + Math.random(1, 100)}`,
            transaction: {
              hash: item,
            },
            investmentFund: convertWeiToEther(value),
            date: timestamp,
          });
          // 최신순 정렬
          investment.sort((s, l) => l.date - s.date);
          // 마지막 map 돌 때 setState
          if (txHashes.length === index + 1) {
            setTxHashItems(investment);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }

    setVote((vote) => {
      return {
        ...vote,
        ...refineVote,
        length: yes.length + no.length,
      };
    });
    setAmount(amount);
    setBallotBasicData(ballotBasic);
    setBallotMemberData({
      ...ballotMember,
      getCount,
      name,
    });
  };

  return (
    <LandingLayout>
      {/* 23.04.28 수정: OG 추가 */}
      <OGHeader url="waitgov" />
      <h1 className={cn("a11y")}>WEMIX Governance</h1>
      <DetailTopLayout theme="dark" prev={true}>
        <div className={cn("title-wrap")}>
          <h2 className={cn("title")}>
            {/* 24.01.09 수정: 안건 넘버링과 실제 타이틀을 분리 */}
            <span>{ballotMember.getCount}</span>
            <strong>{ballotMember.name}</strong>
          </h2>
          <dl className={cn("top-info")}>
            <div className={cn("proposer-cell")}>
              <dt>{t("votingDetail.header.proposer")}</dt>
              <dd>
                <strong className={cn("cell-title")}>
                  {authorityNames.get(ballotBasic.creator) || "-"}
                </strong>
                <span className={cn("cell-cont")}>{ballotBasic.creator}</span>
              </dd>
            </div>
            {/* NOTE: 해당 영역 governance 페이지 상세 일 경우 삭제 필요 */}
            <div className={cn("proposal-cell")}>
              <dt>{t("votingDetail.header.proposalContent")}</dt>
              <dd>
                <strong className={cn("cell-title")}>
                  {t("votingDetail.header.proposalTargetCompany")}
                </strong>
                <span className={cn("cell-cont")}>
                  {ballotMember.companyName}
                </span>
              </dd>
              <dd>
                <strong className={cn("cell-title")}>
                  {t("votingDetail.header.proposalTargetAddress")}
                </strong>
                <span className={cn("cell-cont")}>
                  {ballotMember.companyAddress}
                </span>
              </dd>
              <dd>
                <strong className={cn("cell-title")}>
                  {t("votingDetail.header.proposalTargetAmount")}
                </strong>
                <span className={cn("cell-cont")}>
                  {`${parseFloat(amount).toLocaleString()} WEMIX`}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </DetailTopLayout>
      <DetailContentLayout theme="dark">
        <div className={cn("board-title-wrap")}>
          <h3 className={cn("board-title")}>
            {t("votingDetail.votingBoard.title")} (
            {`${vote.length ? vote.length : 0}/${nodeList.length}`})
          </h3>
          {ballotBasic.state && (
            <WaitVotingStatus
              status={constants.ballotStateArr[ballotBasic.state].toLowerCase()}
            />
          )}
        </div>
        {Object.keys(vote).length && (
          <WaitWondersVoteBoard allList={nodeList} voteData={vote} />
        )}
        <div className={cn("vote-content")}>
          <div className={cn("block-wrap")}>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.description.title")}
                </h4>
                <div
                  className={`${cn("block-cont", "editor")} ${
                    edStyle["editor-wrap"]
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: refineMemo(ballotMember.description),
                  }}
                ></div>
              </div>
            </div>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.link.title")}
                </h4>
                <div className={cn("block-cont")}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {ballotMember.link}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={cn("block-wrap")}>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.votingStatus.title")}
                </h4>
                <div className={cn("block-cont")}>
                  {ballotBasic.powers && (
                    <>
                      <VotingStickChart
                        title={t("votingDetail.votingStatus.yes")}
                        percent={ballotBasic.powers[1]}
                        count={ballotBasic.acceptVoters.length}
                        theme="dark"
                      />
                      <VotingStickChart
                        title={t("votingDetail.votingStatus.no")}
                        percent={ballotBasic.powers[2]}
                        count={ballotBasic.rejectVoters.length}
                        type="no-type"
                        theme="dark"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.votingPeriod.title")}
                </h4>
                <div className={cn("block-cont")}>
                  <dl className={cn("date-wrap")}>
                    <div>
                      <dt>{t("votingDetail.votingPeriod.start")}</dt>
                      <dd>
                        {timeConverter(ballotBasic.startTime, true, true)}
                      </dd>
                    </div>
                    <div>
                      <dt>{t("votingDetail.votingPeriod.end")}</dt>
                      <dd>{timeConverter(ballotBasic.endTime, true, true)}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            {/* 23.06.20 수정 start: 투자 내역 테이블 추가 */}
            {txHashItems.length ? (
              <div className={cn("block")}>
                <div className={cn("block-inner")}>
                  <h4 className={cn("block-title")}>
                    {t("votingDetail.investmentHistory.title")}
                  </h4>
                  <div className={cn("block-cont")}>
                    <dl className={cn("date-wrap")}>
                      {txHashItems.map((item, idx) => (
                        <div key={`investment-${idx}`}>
                          <dt>{loginAcc(item.transaction.hash)}</dt>
                          <dd>
                            <div className={cn("amount-wrap")}>
                              <span>
                                {parseFloat(
                                  item.investmentFund,
                                ).toLocaleString()}
                              </span>
                              <span className={cn("date")}>
                                {timeConverter(item.date)}
                              </span>
                            </div>
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* 23.06.20 수정 end: 투자 내역 테이블 추가 */}
          </div>
        </div>
      </DetailContentLayout>
    </LandingLayout>
  );
};

export default WaitGovernanceDetail;
