import React, { useEffect, useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import style from "../../assets/scss/governance.module.scss";

import LandingLayout from "../../components/LandingLayout.jsx";
import OGHeader from "../../components/wait/OGHeader.jsx";

import WaitGovernanceListBlock from "../../components/wait/WaitGovernanceListBlock.jsx";
import {
  DetailTopLayout,
  DetailContentLayout,
} from "../../components/wait/WaitDetailLayout.jsx";

import { GovInitCtx } from "../../contexts/GovernanceInitContext";
import SelectDropdown from "../../components/select-dropdown/SelectDropdown.jsx";
import {
  NETWORK,
  VOTING_DURATION_SETTING,
  constants,
} from "../../constants.js";
import {
  loginAcc,
  refineMemo,
  refineProposalCount,
  refineProposalTitle,
} from "../../util.js";
import { LandingDataStateContext } from "../../contexts/LandingDataContext.jsx";

const cn = classNames.bind(style);

const Governance = () => {
  const { t } = useTranslation();

  const { data } = useContext(GovInitCtx);
  const { ballotBasicOriginData, ballotMemberOriginData } = data;

  let { current: basicData } = useRef([]); // 리스트 원본

  const [listData, setListData] = useState([]); // 화면에 뿌릴 리스트
  const [governanceData, setGovernanceData] = useState({
    InProgress: 0,
    Ready: 0,
    Approved: 0,
    Rejected: 0,
  });
  const [total, setTotal] = useState(0);

  const { addressNcpInfo } = useContext(LandingDataStateContext);

  useEffect(() => {
    getList();
  }, []);

  // authority 관련 안건만 추출 (add, remove, replace)
  const getList = () => {
    Object.values(ballotBasicOriginData).map((basic) => {
      // ! mainnet일 경우 기존 안건을 보여주지 않음
      if (NETWORK === "mainnet" && basic.id <= 55) return null;
      // 1: add, 2: remove, 3: replace 만 사용
      // 4: gov contract address 변경하는 것도 띄움
      // HISTORY: 2024-08-06 - 5: ChangedEnv 빼고 다 보여줌
      if (parseInt(basic.ballotType) === 5) return null;
      // cancel 된 것 보여주지 않음
      if (basic.state === "5") return null;

      const { id, ballotType, state, memo } = basic;
      // ! state ready 상태일 경우 inprogress 로 수정
      let readyTempData;
      const startTimeConverted = refineMemo(memo, "proposalStartTime");
      const endTimeConverted = refineMemo(memo, "proposalEndTime");
      if (state === constants.ballotState.Ready) {
        readyTempData = {
          startTimeConverted: startTimeConverted.slice(0, 10),
          endTimeConverted: endTimeConverted.slice(0, 10),
        };
      }

      const tempState =
        (NETWORK === "mainnet" &&
          VOTING_DURATION_SETTING[id] !== undefined &&
          state === constants.ballotState.Ready) ||
        (state === constants.ballotState.Ready &&
          new Date(startTimeConverted) <= new Date() &&
          new Date(endTimeConverted) >= new Date())
          ? constants.ballotState.InProgress
          : state;

      // state 값에 맞는 텍스트를 가져옴
      let stateType = constants.ballotStateArr[tempState];
      // 안건 제목 재정의
      let name = "";
      // replace authority member 일 때는 newNodeName을 타이틀에 보여줌
      if (ballotType === "3") {
        const memberData = Object.values(ballotMemberOriginData).filter(
          (member) => member.ballotId === id,
        )[0];
        const { newNodeName, oldStakerAddress, newStakerAddress } = memberData;
        // memo에 입력한 title이 없으면 기존 로직 (default)으로 뿌림
        name =
          refineMemo(memo, "proposalTitle") ||
          refineProposalTitle(
            ballotType,
            newNodeName,
            newStakerAddress === "0x0000000000000000000000000000000000000000"
              ? oldStakerAddress
              : newStakerAddress,
            oldStakerAddress,
          );
      } else {
        name =
          refineMemo(memo, "proposalTitle") || refineProposalTitle(ballotType);
      }
      // WGP 카운팅 따로 빼기
      // const proposalCount = refineProposalCount(wgpCount);
      // wgpCount++;
      const proposalCount =
        refineMemo(memo, "proposalNumber") || refineProposalCount(id);

      if (NETWORK === "mainnet" && VOTING_DURATION_SETTING[id] !== undefined) {
        if (
          basic.state === constants.ballotState.Ready ||
          basic.state === constants.ballotState.InProgress
        ) {
          // ! state ready 상태일 경우 inprogress 로 수정 (투표 시간도 수정)
          // ! inprogress 상태일 때도 하드 코딩 시간으로 뿌림
          const { startTime, startTimeConverted, endTime, endTimeConverted } =
            VOTING_DURATION_SETTING[id];
          readyTempData = {
            state: "InProgress",
            startTime,
            startTimeConverted,
            endTime,
            endTimeConverted,
          };
        }
      }

      basicData.push({
        ...basic,
        name: `${proposalCount}: ${name}`,
        stateType: stateType.toLowerCase(),
        ...readyTempData,
      });
      // 안건 상태 값 세팅
      setGovernanceData((prev) => {
        return {
          ...prev,
          [stateType]: prev[stateType] + 1,
        };
      });
      // total
      setTotal((prev) => prev + 1);

      return null;
    });
    // 최신순으로 정렬
    basicData = basicData.reverse();
    setListData(basicData);
  };

  // 선택 값에 따라 리스트 다시 뿌리기
  const handleChangeList = (state) => {
    if (state === "all") {
      setListData(basicData);
      return;
    }
    const selectedList = basicData.filter((basic) => basic.stateType === state);
    setListData(selectedList);
  };

  return (
    <LandingLayout>
      <OGHeader url="gov" />
      <DetailTopLayout theme="dark">
        <div className={cn("title-wrap")}>
          <h1 className={cn("title")}>{t("governance.header.title")}</h1>
          <div className={cn("description")}>
            <p>{t("governance.header.description")}</p>
          </div>
        </div>
        {/* 안건 개수 보여주는 부분 */}
        <dl className={cn("governance-top-list", "gov")}>
          <div>
            <dt>{t("governance.header.index.total")}</dt>
            <dd>
              <span className={cn("number")}>{total}</span>
            </dd>
          </div>
          <div>
            <dt>{t("governance.header.index.active")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.InProgress}</span>
            </dd>
          </div>
          <div>
            <dt>{t("governance.header.index.ready")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.Ready}</span>
            </dd>
          </div>
          <div>
            <dt>{t("governance.header.index.approved")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.Approved}</span>
            </dd>
          </div>
          <div>
            <dt>{t("governance.header.index.rejected")}</dt>
            <dd>
              <span className={cn("number")}>{governanceData.Rejected}</span>
            </dd>
          </div>
        </dl>
      </DetailTopLayout>
      <DetailContentLayout theme="dark">
        {total && (
          // 필터링 기능을 위한 SelectDropdown 컴포넌트 추가
          // 전체 안건이 하나라도 있을 경우에만 필터 표시
          <SelectDropdown handleChange={(state) => handleChangeList(state)} />
        )}
        {listData.length ? (
          <ul className={cn("governance-voting-list")}>
            {listData.map((data, index) => {
              const {
                id,
                creator,
                name,
                memo,
                stateType,
                startTime,
                startTimeConverted,
                endTimeConverted,
                powerOfAccepts,
                powerOfRejects,
              } = data;

              // proposal ready 상태일 경우 처리 (다국어?)
              const date =
                startTime > 0 || startTimeConverted.length
                  ? `${startTimeConverted} ~ ${endTimeConverted}`
                  : `${t("waitGov.voting.list.status.ready")}`;
              return (
                <WaitGovernanceListBlock
                  key={`governance-list-${index}`}
                  link={id}
                  ncpName={addressNcpInfo[creator]?.name || "WEMIX PTE. LTD."}
                  ncpAddress={loginAcc(creator)}
                  title={name}
                  description={memo}
                  state={stateType}
                  date={date}
                  person="40 WONDERS" // GD-102 | 40 WONDERS 로 고정
                  chart={{
                    yes: powerOfAccepts,
                    no: powerOfRejects,
                  }}
                />
              );
            })}
          </ul>
        ) : (
          // empty 케이스
          <div className={cn("no-data")}>{t("governance.list.empty.text")}</div>
        )}
      </DetailContentLayout>
    </LandingLayout>
  );
};

export default Governance;
