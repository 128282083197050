import React, { useContext, useState } from "react";
import { Button, Modal, Select, Icon, Slider } from "antd";
import { useSendTransaction } from "wagmi";

import { convertSecondsToDay } from "../util";

import "./style/style.css";
// import "../assets/scss/components.scss";

// 2023.02.24 수정 voting 페이지 팝업 추가 관련코드 추가
import cn from "classnames/bind";
import VotingInputArea from "./voting/VotingInputArea";

// 2023.07.04 App.js의 Staking 관련 로직 Modal로 이동
import * as util from "../util";
import {
  encodeABIValueInMethod,
  encodeABIValueInTrx,
  web3Instance,
} from "../web3";
import { ModalContext } from "../contexts/ModalContext";

const Option = Select.Option;

const StakingModal = ({
  isMember,
  defaultAccount,
  accountBalance,
  setStakingModalVisible,
  stakingModalVisible,
  setStakingEventsWatch,
  scrollType,
  getErrModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stakingTopic, setStakingTopic] = useState("deposit");
  const [stakingAmount, setStakingAmount] = useState("");
  const [errStaking, setErrStaking] = useState(false);

  const { sendTransactionAsync } = useSendTransaction();

  const submitWemixStaking = () => {
    if (!/^[1-9]\d*$/.test(stakingAmount)) {
      setErrStaking(true);
      return;
    }

    let trx = {};
    const amount = util.convertEtherToWei(stakingAmount);

    if (stakingTopic === "deposit") {
      trx = encodeABIValueInTrx(web3Instance, "Staking", "deposit", amount);
    } else {
      trx = encodeABIValueInMethod(web3Instance, "Staking", "withdraw", amount);
    }
    setIsLoading(true);
    sendStakingTransaction(trx);
  };

  const waitForReceipt = (hash, cb) => {
    web3Instance.web3.eth.getTransactionReceipt(hash, (err, receipt) => {
      if (err) console.log("err: ", err);
      if (util.checkUndefined(receipt) || receipt === null) {
        // Try again in 1 second
        window.setTimeout(() => {
          waitForReceipt(hash, cb);
        }, 1000);
      } else {
        // Transaction went through
        if (cb) cb(receipt);
      }
    });
  };

  const sendStakingTransaction = async (trx) => {
    try {
      trx.from = defaultAccount;
      trx.gasPrice = 101000000000;
      // trx.maxFeePerGas = 101000000000;
      // trx.maxPriorityFeePerGas = 100000000000;
      await sendTransactionAsync(trx).then(({ hash }) => {
        waitForReceipt(hash, async (receipt) => {
          if (receipt.status) {
            await setStakingEventsWatch();
            setIsLoading(false);
            setStakingModalVisible(false);
            setStakingAmount("");
          } else {
            getErrModal(
              "The transaction could not be sent normally.",
              "Proposal Submit Error",
              receipt.transactionHash,
            );
            setIsLoading(false);
            setStakingModalVisible(false);
            setStakingAmount("");
          }
        });
      });
    } catch (err) {
      let message = err?.details || "Unknown Error";
      // 어떠한 경우에 JSON-RPC error가 메세지에 들어있는지 확인 불가.
      // Error 객체의 기본 형이 {code : any, message : any} 인데 sendTransactionAsync를 사용하면 기본 에러를 감싸서 details 에 내려줍니다.
      if (err.message.includes("JSON-RPC error")) message = "RPC error";
      setIsLoading(false);
      getErrModal(message, "Staking Error");
      setStakingAmount("");
    }
  };

  const handleSelectChange = (topic) => {
    setStakingTopic(topic);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (/^([0-9]*)$/.test(value)) {
      setStakingAmount(value);
      setErrStaking(false);
    }
  };

  return (
    <Modal
      className={cn("staking-modal", scrollType && "scroll")}
      title="WEMIX Staking"
      visible={stakingModalVisible}
      onCancel={() => {
        if (!isLoading) {
          setStakingModalVisible(false);
        }
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            if (!isLoading) {
              setStakingModalVisible(false);
            }
          }}
          className="voting-cancel-btn"
        >
          cancel
        </Button>,
        <Button
          key="submit"
          className="voting-ok-btn"
          onClick={submitWemixStaking}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <div className={cn("staking-wrap")}>
        <Select
          value={stakingTopic}
          onChange={handleSelectChange}
          disabled={isLoading}
          className={cn("voting-filter")}
        >
          <Option value="deposit" style={{ zIndex: 1200 }}>
            {isMember ? "Additional" : "Deposit"} Staking
          </Option>
          <Option value="withdraw" style={{ zIndex: 1200 }}>
            Withdraw Staking
          </Option>
        </Select>

        {/* <Input
        onChange={handleInputChange}
        placeholder="WEMIX Amount"
        addonAfter="WEMIX"
        value={stakingAmount}
        className={errStakging ? "errInput" : ""}
        disabled={loading}
      /> */}

        <VotingInputArea
          inputType="suffix"
          suffixCoin="WEMIX"
          placeholder={"WEMIX Amount"}
          value={stakingAmount}
          type={"default"}
          onChange={handleInputChange}
          className={errStaking ? "errInput" : ""}
          disabled={isLoading}
          errType={errStaking}
          errText="Invalid Amount"
        />
      </div>
      <div className="sub-info">
        <p className="sub-title">Staked {accountBalance.balance} WEMIX </p>
        <p className="sub-info-detail">
          (Locked {accountBalance.lockedBalance} WEMIX)
        </p>
      </div>
    </Modal>
  );
};

const ErrModal = () => {
  const { setIsModalOpened, isModalOpened, title, content, errLink } =
    useContext(ModalContext);
  const RPCErrorMSG = () => (
    <div>
      <p>Ensure the network has been added correctly.</p>
      <p>Ensure you have enough coins to pay for gas.</p>
      <p>
        Ensure you are using the latest version of the app or the extension.
      </p>
    </div>
  );

  return (
    <Modal
      className={cn("staking-modal")}
      title={
        <div>
          {title}
          <p className={cn("sub-title")}>revises the following information!</p>
        </div>
      }
      visible={isModalOpened}
      onCancel={() => setIsModalOpened(false)}
      closeIcon={
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_popup_close.svg`}
          alt="ico_popup_close"
        />
      }
      footer={
        errLink
          ? [
              <a
                key="link"
                href={errLink}
                rel="noopener noreferrer"
                target="_blank"
                className="ant-btn"
              >
                Checking on the Explorer
              </a>,
              <Button
                key="ok"
                className="voting-ok-btn"
                onClick={() => setIsModalOpened(false)}
              >
                Okay
              </Button>,
            ]
          : [
              <Button
                key="ok"
                className="voting-ok-btn"
                onClick={() => setIsModalOpened(false)}
              >
                Okay
              </Button>,
            ]
      }
    >
      <div className="flex error-icon">
        <Icon type="exclamation-circle" />
        <div>{content === "RPC error" ? <RPCErrorMSG /> : content}</div>
      </div>
    </Modal>
  );
};

const AccessFailedModal = ({ visible, message }) => (
  <Modal
    className="accessFail"
    title="Access Failed"
    visible={visible}
    footer={null}
  >
    <Icon type="close-circle" />
    <p className="text-bold">{message}</p>
  </Modal>
);

const ChangeModal = ({
  updateModal,
  ballotUpdateDuration,
  votingDurationMin,
  votingDurationMax,
  completeModal = (f) => f,
  hideChangeModal = (f) => f,
  sliderChange = (f) => f,
}) => {
  const min = convertSecondsToDay(votingDurationMin);
  const max = convertSecondsToDay(votingDurationMax);

  return (
    <Modal
      title="Voting Duration Change"
      visible={updateModal}
      onOk={completeModal}
      onCancel={hideChangeModal}
    >
      <p className="changeDay flex flex-center-vertical">
        {ballotUpdateDuration} days
      </p>
      <Slider
        marks={{
          [min]: min + " days",
          [ballotUpdateDuration]: ballotUpdateDuration + " days",
          [max]: max + " days",
        }}
        min={min}
        max={max}
        defaultValue={ballotUpdateDuration}
        tooltipVisible={false}
        onChange={sliderChange}
      />
    </Modal>
  );
};

const ConnectWalletModal = ({ children, visible, setWalletModal }) => {
  return (
    <Modal
      visible={visible}
      title={"Wallet Connect"}
      onCancel={setWalletModal}
      footer={null}
    >
      {children}
    </Modal>
  );
};

const DisConnectWalletModal = ({
  onDisConnect,
  visible,
  setDisConnectView,
}) => {
  return (
    <Modal
      className="dis-connect-foot"
      visible={visible}
      title={"Disconnect"}
      onCancel={() => setDisConnectView(false)}
      onOk={onDisConnect}
      footer={[
        <Button
          key="cancel"
          className="discon-cancel-btn"
          onClick={() => setDisConnectView(false)}
        >
          Cancel
        </Button>,
        <Button key="ok" className="discon-ok-btn" onClick={onDisConnect}>
          Okay
        </Button>,
      ]}
    >
      <p className="modal-disconnect-title">Disconnect your Wallet?</p>
    </Modal>
  );
};

// 2023.02.24 수정 voting 페이지 팝업 추가 작업
// [24.04.01] Rewards 메뉴 추가건 (header, close x button 노출 여부 관련 수정)
const VotingModal = ({
  onOk,
  visible,
  isVotingModal,
  btn,
  children,
  scrollType,
  title,
  subTitle,
  notHeader, // [24.04.01] Rewards 메뉴 추가건 (header, close x button 노출 여부 관련 수정)
  closable = true, // [24.04.01] Rewards 메뉴 추가건 (header, close x button 노출 여부 관련 수정)
  disabledOk = false,
}) => {
  return (
    <Modal
      className={cn("staking-modal", scrollType && "scroll")}
      visible={visible}
      title={
        !notHeader && (
          <div>
            {title}
            {subTitle && <p className={cn("sub-title")}>{subTitle}</p>}
          </div>
        )
      }
      onCancel={() => isVotingModal(false)}
      transitionName="voting"
      closeIcon={
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_popup_close.svg`}
          alt="ico_popup_close"
        />
      }
      closable={closable}
      footer={[
        btn.cancel && (
          <Button
            key="cancel"
            className="voting-cancel-btn"
            onClick={() => isVotingModal(false)}
          >
            Cancel
          </Button>
        ),
        <Button
          key="ok"
          className={`voting-ok-btn ${disabledOk ? "disabled" : ""}`}
          onClick={() => onOk()}
          disabled={disabledOk}
        >
          {btn.btnName}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

// 2023.04.20 수정: TxHashAddModal 팝업 추가
const TxHashAddModal = ({
  onApply,
  visible,
  children,
  scrollType,
  disabled,
  onCancel,
}) => {
  return (
    <Modal
      className={cn("tx-hash-add-modal", scrollType && "scroll")}
      visible={visible}
      title={<div>Update Tx Hash</div>}
      onCancel={() => onCancel()}
      transitionName="tx-hash-add"
      closeIcon={
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_popup_close.svg`}
          alt="ico_popup_close"
        />
      }
      footer={[
        <Button
          key="cancel"
          className="tx-hash-add-cancel-btn"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>,
        <Button
          key="apply"
          className="tx-hash-add-apply-btn"
          onClick={() => onApply()}
          disabled={disabled}
        >
          Apply
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

// [24.04.01] Rewards 메뉴 추가건 (LoadingModal 팝업 추가)
const LoadingModal = ({ visible, loadingMsg, msg, width }) => {
  /*
    옵션 설명
    visible : boolean | 모달 노출 여부
    loadingMsg? : string | 로딩 스피너 하단 메세지 | 값 없는 경우 비노출
    msg : string | 로딩 메세지
  */
  return (
    <Modal
      className="loading-modal"
      width={width}
      visible={visible}
      footer={null}
      closable={false}
    >
      <div className={cn("loading-modal-wrap")}>
        {/* loading spinner */}
        <div className={cn("top-content-wrap")}>
          <div className={cn("loading-spinner")}>
            <svg width="64px" height="64px">
              <circle cx="32px" cy="32px" r="0.45em" />
            </svg>
          </div>
          {loadingMsg && <p>{loadingMsg}</p>}
        </div>
        {/* content */}
        <p className={cn("loading-msg")}>{msg}</p>
      </div>
    </Modal>
  );
};

export {
  StakingModal,
  ErrModal,
  AccessFailedModal,
  ChangeModal,
  ConnectWalletModal,
  DisConnectWalletModal,
  // 2023.02.24 수정 voting 페이지 팝업 추가 작업
  VotingModal,
  TxHashAddModal, // 2023.04.20 수정 TxHashAddModal 팝업 추가
  LoadingModal, // [24.04.01] Rewards 메뉴 추가건 (LoadingModal 팝업 추가)
};
