import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { web3Instance, callContractMethod } from "../web3";
import * as util from "../util";
import { useAccount, useNetwork, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import * as abis from "../abis/index";

const useAuth = () => {
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const [myBalance, setMyBalance] = useState("");
  const [lockedBalance, setLockedBalance] = useState("");

  const [isMember, setIsMember] = useState(false);
  const [isStaker, setIsStaker] = useState(false);
  const [stakerAddress, setStakerAddress] = useState("");
  // wait whitelist
  const [isWhiteList, setIsWhiteList] = useState(false);
  // ncp staking
  const [showReward, setShowReward] = useState(false);
  const [ncpInfo, setNcpInfo] = useState({});

  const onLogin = async () => {
    try {
      await open();
    } catch (e) {
      console.error(e);
    }
  };
  const onLogout = () => {
    setIsMember(false);
    setIsStaker(false);
    setStakerAddress("");
    setIsWhiteList(false);

    disconnect();

    // 로그 아웃 시  list 페이지로 무조건 이동
    localStorage.clear();
    redirectTo();
  };

  const redirectTo = () => navigate("/voting/list");

  // update data related to new account
  const updateAccountData = async (newAccount) => {
    if (!web3Instance) return;
    // if (web3Instance.web3.currentProvider.constructor.name === "HttpProvider") {
    //   return;
    // }

    // checksum
    newAccount = web3Instance.web3.utils.toChecksumAddress(newAccount);

    await updateAccountBalance(newAccount);
    setStakingEventsWatch(newAccount);
    const isMember = await callContractMethod(
      web3Instance,
      "GovImp",
      "isMember",
      newAccount,
    );
    const isStaker = await callContractMethod(
      web3Instance,
      "GovImp",
      "isStaker",
      newAccount,
    );
    const voterIdx = await callContractMethod(
      web3Instance,
      "GovImp",
      "voterIdx",
      newAccount,
    );
    let staker;
    if (voterIdx) {
      staker = await callContractMethod(
        web3Instance,
        "GovImp",
        "getMember",
        voterIdx,
      );
    }
    const whiteList = await callContractMethod(
      web3Instance,
      "WaitGovernance",
      "isWhitelisted",
      newAccount,
    );

    setStakerAddress(isStaker ? newAccount : staker.length ? staker : "");
    setIsMember(isMember);
    setIsStaker(isStaker);
    setIsWhiteList(whiteList);

    // 계정이 바꼈는데 멤버가 아닐 경우 리스트로 강제 이동
    // if (!isMember && pathname !== "/voting/list") {
    //   redirectTo();
    // }
  };

  // Reward Tab
  // 현재 로그인 된 계정을 기준으로 보여줌
  const getNCPStakingInfo = useCallback(async () => {
    try {
      if (!address) {
        setShowReward(false);
        return;
      }

      const ncpTypeInfo = await callContractMethod(
        web3Instance,
        "NCPStakingGateway",
        "checkAddressType",
        address,
      );
      const { ncp, rewarder, addressType } = ncpTypeInfo;
      // * addressType이 0일 때 reward tab 숨김
      setShowReward(!!parseInt(addressType));

      // rewarder가 없을 때 (일반 address) return
      if (rewarder === "0x0000000000000000000000000000000000000000") {
        setNcpInfo({
          ...ncpTypeInfo,
          contract: null,
          reward: "0",
          owner: rewarder,
        });
        return;
      }

      // reward contract 객체 만들어 주기
      const contract = new web3Instance.web3.eth.Contract(
        abis["Rewarder"].abi,
        rewarder,
      );
      const owner = await contract.methods.owner().call();

      // ncp address로 pendingReward 리워드 수량 가져오기
      const ncpInfo = await callContractMethod(
        web3Instance,
        "NCPStakingGateway",
        "getUserInfo",
        ncp,
      );
      const { pendingReward } = ncpInfo[0];

      setNcpInfo({
        ...ncpTypeInfo,
        contract,
        reward: pendingReward,
        owner,
      });
    } catch (e) {
      console.error(e);
    }
  }, [address]);

  // set the balance of account
  const updateAccountBalance = async (defaultAccount = address) => {
    const weiBalance = await callContractMethod(
      web3Instance,
      "Staking",
      "balanceOf",
      defaultAccount,
    );
    const locked = await callContractMethod(
      web3Instance,
      "Staking",
      "lockedBalanceOf",
      defaultAccount,
    );
    const myBalance = util.convertWeiToEther(weiBalance);
    const lockedMyBalance = util.convertWeiToEther(locked);
    setMyBalance(myBalance);
    setLockedBalance(lockedMyBalance);
    // this.setState({ stakingModalVisible: false, loading: false });
  };

  // update balance (after send transaction, changed account)
  const setStakingEventsWatch = async (defaultAccount = address) => {
    try {
      // for getting only default accounts event
      const filteraddress = web3Instance.web3.eth.abi.encodeParameter(
        "address",
        defaultAccount,
      );
      const result = await web3Instance.web3Contracts.Staking.getPastEvents(
        "allEvents",
        {
          fromBlock: "latest",
          topics: [null, filteraddress],
        },
      );

      if (result) {
        // update balance
        updateAccountBalance(defaultAccount);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    isMember,
    isStaker,
    stakerAddress,
    address,
    myBalance,
    lockedBalance,
    isLoggedIn: isConnected,
    chain,
    updateAccountData,
    setStakingEventsWatch,
    onLogin,
    onLogout,
    getNCPStakingInfo,
    ncpInfo,
    showReward,
    isWhiteList,
  };
};

export { useAuth };
