import React from "react";
import { Helmet } from "react-helmet";
import { version } from "../../../package.json";

const metaData = {
  gov: {
    title: "Governance | 40 WONDERS",
    keywords:
      "WAIT Protocol, Investment, Governance, Voting, 4, 거버넌스, 투표, 안건",
    ogTitle: "40 WONDERS | Governance",
    description:
      "WONDERS, as the representatives of the WEMIX3.0 community, participates in chain's governance activities in order to ensure stable operation of the WEMIX3.0 network and foster growth of the ecosystem. The governance proposals including the addition, replacement, and withdrawal of 40 WONDERS, are transparently disclosed to all, allowing everyone to access proposal details and voting results.",
  },
  wait: {
    title: "WAIT Protocol | 40 WONDERS",
    keywords:
      "WAIT Protocol, Investment, WEMIX 재단, 블록체인 생태계, WEMIX 투자, WAIT, 프로토콜",
    ogTitle: "40 WONDERS | WAIT Protocol",
    description:
      "The WAIT Protocol is the investment approval protocol of the WEMIX mega-ecosystem. Investments are processed in three transparent stages upon approval which include investment proposition, investment review, and governance approval.",
  },
  waitgov: {
    title: "WAIT Governance | 40 WONDERS",
    keywords:
      "WAIT Protocol, Investment, Governance, Voting, 4, 거버넌스, 투표, 안건",
    ogTitle: "40 WONDERS | WAIT Governance",
    description:
      "Through the qualitative assessment of the investments for the WEMIX ecosystem's growth, the feasibility is validated, and the final approval of the proposal is decided upon the votes of the 40 WONDERS.",
  },
  default: {
    title: "40 WONDERS",
    keywords:
      "wemix, wemix3.0, wemix 3.0, blockchain, ethereum, evm, cryptocurrency, digital asset, decentralization, public blockchain, wemix$, dapp, platform, web3, web3.0, on-chain, smart contract, 위믹스, 위믹스3.0, 위믹스 3.0, 블록체인, 이더리움, 암호화폐, 가상화폐, 디지털 자산, 탈중앙화, 퍼블릭 블록체인, 위믹스달러, 디앱, 플랫폼, 웹3, 웹3.0, 온체인, 스마트 컨트랙트, 스마트 계약, 40 wonders, 40 ncp, main net, wonders, wonder, wait, wait protocol, protocol, governance, node, voting, pmr, reward, partner, ncp, community, staking, consensus, validation, wonder dao, dao, 40 원더스, 원더스, 원더, 위믹스 노드, 프로토콜, 거버넌스, 노드, 투표, 리워드, 민팅 리워드, 파트너, 커뮤니티, 스테이킹, 합의, 검증, 원더다오, 다오",
    ogTitle: "40 WONDERS",
    description:
      "Discover the 40 WONDERS of the WEMIX3.0 mega-ecosystem. The 40 WONDERS is the backbone of the WEMIX3.0 governance that will participate in the multi-phase deomcratized governance developed on a decentralized architecture.",
  },
};

const OGHeader = ({ url = "default" }) => (
  <Helmet>
    {/* TODO: process.env.PUBLIC_URL가 배포 URL이 맞는지 꼭 확인해주세요~! */}
    <title>{metaData[url].title}</title>
    <meta name="version" content={version} />
    <meta
      property="og:url"
      content={`${process.env.REACT_APP_URL}/${url}`}
      key="ogUrl"
    />
    <meta
      property="og:image"
      content={`${process.env.REACT_APP_URL}/img/OG_40WONDERS.png`}
    />
    <meta name="keywords" content={metaData[url].keywords} key="keywords" />
    <meta property="og:title" content={metaData[url].ogTitle} key="ogTitle" />
    <meta
      property="og:description"
      content={metaData[url].description}
      key="ogDescription"
    />
    <meta
      property="twitter:url"
      content={`${process.env.REACT_APP_URL}/${url}`}
      key="twitterUrl"
    />
    <meta
      property="twitter:image"
      content={`${process.env.REACT_APP_URL}/img/OG_40WONDERS.png`}
    />
    <meta
      property="twitter:title"
      content={metaData[url].ogTitle}
      key="twitterTitle"
    />
    <meta
      property="twitter:description"
      content={metaData[url].description}
      key="twitterDescription"
    />
  </Helmet>
);

export default OGHeader;
