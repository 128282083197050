import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import { Link } from "react-router-dom";

import { CSSTransition } from "react-transition-group";
import ChangeLanguage from "../change-language/ChangeLanguage";
import PlatformButton from "../platform/PlatformButton";
import { PlatformContents } from "../platform/PlatformContents";

import classNames from "classnames/bind";
import style from "./LandingHeader.module.scss";

const cn = classNames.bind(style);
const menuList = [
  {
    title: "40 WONDERS",
    path: "/",
    key: "menu-home",
  },
  {
    title: "Governance",
    path: "/gov",
    key: "menu-gov",
  },
  {
    title: "WAIT Protocol",
    path: "/wait",
    key: "menu-wait",
  },
  {
    title: "WAIT Governance",
    path: "/waitgov",
    key: "menu-waitgov",
  },
];
const MenuComponent = () => {
  const location = useLocation();
  const pathName = `/${location.pathname.split("/")[1]}`;
  return menuList.map((menu) => (
    <li key={menu.title} className={cn(menu.path === pathName && "active")}>
      {/* 23.06.20 수정: 모바일 gnb 클릭 시 body lock 해제를 위한 클릭 이벤트 추가 */}
      <Link
        to={menu.path}
        onClick={() => {
          if (
            window.innerWidth < 1120 &&
            document.body.classList.contains("modal-open")
          ) {
            document.body.classList.remove("modal-open");
            document.body.removeAttribute("style");
          }
        }}
      >
        {menu.title}
      </Link>
    </li>
  ));
};

const LandingHeader = React.memo(() => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isGnbOpen, setIsGnbOpen] = useState(false);

  /* 23.05.30 수정: 모바일 GNB 관련 수정 코드 추가 */
  const [scrollTop, setScrollTop] = useState(0);
  const mobileGnbRef = useRef(null);

  const [offset, setOffset] = useState({
    width: 0,
    height: 0,
  });
  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    /* 23.05.30 수정: 모바일 GNB 관련 수정 코드 추가 (리사이즈 시 스크롤 락 해지) */
    if (
      /* 23.06.20 수정: 모바일 메뉴바 노출에 따라 resize 이벤트 발생하여 동작 이상 발생하여 width 체크 구간 수정 */
      window.innerWidth >= 1120 &&
      document.body.classList.contains("modal-open")
    ) {
      document.body.classList.remove("modal-open");
      document.body.removeAttribute("style");
    }
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    setIsGnbOpen(false);
  }, [i18n.language]);

  const handleLink = () => {
    navigate({
      pathname: "/voting/list",
    });
  };

  /* 23.05.30 수정: 모바일 GNB 오픈 시 스크롤 width 만큼 body padding 추가 */
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vw",
      `${window.innerWidth - document.documentElement.clientWidth}px`,
    );
  }, []);
  return (
    <header className={cn("header", "dark")}>
      <div className={cn("header-logo-wrap")}>
        <div className={cn("header-logo")}>
          <Link to={"/"}>
            <span className={cn("logo", "logo-typo-wemix")}>
              <span className={cn("a11y")}>WEMIX</span>
            </span>
            <span className={cn("logo", "logo-symbol")}>
              {/* 22.11.15 수정: IconSymbol 컴포넌트 추가 */}
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/logo_symbol_governance.svg`}
                alt="logo_symbol_governance"
              />
            </span>
            <span className={cn("logo", "logo-typo-governance")}>
              <span className={cn("a11y")}>40 Wonders</span>
            </span>
          </Link>
        </div>
        {offset.width >= 1120 && (
          <nav>
            <ul className={cn("header-gnb")}>
              <MenuComponent />
            </ul>
          </nav>
        )}
      </div>
      {offset.width >= 1120 ? (
        <div className={cn("header-utils")}>
          {/* 23.04.11 수정: Launch Governance 버튼 추가 */}
          {process.env.REACT_APP_EXPOSURE === "true" && (
            <div className={cn("user-wallet")}>
              <button type="button" onClick={() => handleLink()}>
                Launch Governance
              </button>
            </div>
          )}
          <ChangeLanguage type="icon" selected={i18n.language} />
          {/* 23.05.24 수정: 플렛폼 바로가기 메뉴 추가 */}
          {/* <PlatformButton /> */}
        </div>
      ) : (
        <>
          {/* 23.05.30 수정: 플렛폼 바로가기 추가로 인한 디자인 변경 */}
          <CSSTransition
            in={isGnbOpen}
            appear
            timeout={{ exit: 400 }}
            unmountOnExit
            nodeRef={mobileGnbRef}
            classNames={{
              enterDone: cn("show"),
              exit: cn("leave"),
            }}
            onEnter={() => {
              setScrollTop(window.pageYOffset);
              document.body.classList.add("modal-open");
              document.body.setAttribute(
                "style",
                `overflow: hidden; position: fixed; top: 0; margin-top: -${
                  window.pageYOffset
                }px; padding-right: ${
                  window.innerWidth - document.documentElement.clientWidth
                }px;`,
              );
            }}
            onEntered={() => {}}
            onExit={() => {
              document.body.classList.remove("modal-open");
              document.body.removeAttribute("style");
              window.scrollTo(0, scrollTop);
              setScrollTop(0);
            }}
          >
            <div className={cn("mobile-gnb")} ref={mobileGnbRef}>
              <div
                className={cn("gnb-bg")}
                onClick={() => setIsGnbOpen(false)}
              />
              <div className={cn("gnb-inner")}>
                <ChangeLanguage
                  type="icon"
                  selected={i18n.language}
                  className={cn("btn-lang-change")}
                />
                <button
                  onClick={() => setIsGnbOpen(false)}
                  className={cn("btn-close")}
                >
                  <img
                    src={`${process.env.REACT_APP_URL}/assets/images/ico_close.svg`}
                    alt="ico_close"
                  />
                </button>
                <div className={cn("header-content")}>
                  <ul className={cn("header-gnb")}>
                    <MenuComponent />
                  </ul>
                  {/* <PlatformContents platformType="wemix" /> */}
                </div>
              </div>
            </div>
          </CSSTransition>
          {/* 23.05.30 수정: 플렛폼 바로가기 추가로 인한 디자인 변경 */}
          <div className="header-utils">
            <button onClick={() => setIsGnbOpen(true)}>
              <span className={cn("a11y")}>menu open</span>
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_menu.svg`}
                alt="ico_menu"
              />
            </button>
          </div>
        </>
      )}
    </header>
  );
});

export default LandingHeader;
