import React from "react";
import { Form } from "antd";

import { decodeHexToString, shouldPass } from "../util";
import VotingInputArea from "./voting/VotingInputArea";
import VotingButton from "../components/voting/Button";
import cn from "classnames/bind";

// Voting Address
export const VotingAddress = ({
  loading,
  oldVotingAddr,
  newVotingAddrErr,
  handleChange = shouldPass(),
  handleSubmit = shouldPass(),
}) => (
  <Form onSubmit={handleSubmit}>
    <div className={cn("voting-input-wrap")}>
      <strong>
        Old Voting Address <span className="required">*</span>
      </strong>
      <VotingInputArea name="oldVotingAddr" value={oldVotingAddr} disabled />
    </div>
    <div className={cn("voting-input-wrap")}>
      <strong>
        New Voting Address <span className="required">*</span>
      </strong>
      <VotingInputArea
        name="newVotingAddr"
        disabled={loading}
        onChange={handleChange}
        errType={newVotingAddrErr}
        errText="Invalid Address"
      />
      <div className={cn("description")}>
        Enter the old Voting Address and the new Voting Address to change the
        Voting Address.
      </div>
    </div>
    <div className="myinfo-footer">
      <VotingButton
        text="Submit"
        disabled={loading || newVotingAddrErr}
        type="bg"
        loading={loading}
      />
    </div>
  </Form>
);

// Reward Address
export const RewardAddress = ({
  netName,
  loading,
  oldRewardAddr,
  newRewardAddrErr,
  handleChange = shouldPass(),
  handleSubmit = shouldPass(),
}) => (
  <Form onSubmit={handleSubmit}>
    <div className={cn("voting-input-wrap")}>
      <strong>
        Old Reward Address <span className="required">*</span>
      </strong>
      <VotingInputArea name="oldRewardAddr" value={oldRewardAddr} disabled />
    </div>
    <div className={cn("voting-input-wrap")}>
      <strong>
        New Reward Address <span className="required">*</span>
      </strong>
      <VotingInputArea
        name="newRewardAddr"
        disabled={loading}
        onChange={handleChange}
        errType={newRewardAddrErr}
        errText="Invalid Address"
      />
      <div className={cn("description")}>
        Enter the old Reward Address and the new Reward Address to change the
        Reward Address.
      </div>
    </div>
    <div className="myinfo-footer">
      <VotingButton
        text="Submit"
        disabled={loading || newRewardAddrErr}
        type="bg"
        loading={loading}
      />
    </div>
  </Form>
);

// IP Address
export const IPAddress = ({
  oldIP,
  newIPErr,
  oldPort,
  newPortErr,
  handleChange = shouldPass(),
  handleSubmit = shouldPass(),
}) => (
  <Form onSubmit={handleSubmit}>
    <div className={cn("voting-input-wrap")}>
      <strong>Old IP</strong>
      <VotingInputArea name="oldIP" disabled value={decodeHexToString(oldIP)} />
    </div>
    <div className={cn("voting-input-wrap")}>
      <strong>New IP</strong>
      <VotingInputArea
        name="newIP"
        placeholder={"Enter IP"}
        onChange={handleChange}
        errType={newIPErr}
        errText="Invalid IP"
      />
    </div>
    <div className={cn("voting-input-wrap")}>
      <strong>Old Port</strong>
      <VotingInputArea name="oldPort" disabled value={oldPort} />
    </div>
    <div className={cn("voting-input-wrap")}>
      <strong>New Port</strong>
      <VotingInputArea
        name="newPort"
        placeholder={"Enter Port"}
        onChange={handleChange}
        errType={newPortErr}
        errText="Invalid Port"
      />
    </div>
    <div className="myinfo-footer">
      <VotingButton text="Submit" disabled={newPortErr || newIPErr} type="bg" />
    </div>
  </Form>
);
