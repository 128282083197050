import React, { useState, useCallback, useEffect, useRef } from "react";
import classNames from "classnames/bind";
import style from "./TableFilter.module.scss";

import { useTranslation, Trans } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { throttle } from "lodash";

import Checkbox from "./Checkbox.jsx";

const cn = classNames.bind(style);
const TableFilter = ({
  filterData,
  isFiltering,
  onFilterInputChange,
  onFilterChange,
  defaultFilterData,
  total,
}) => {
  const { t } = useTranslation();

  const [filterState, setFilterState] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [offset, setOffset] = useState({
    width: 0,
  });
  const [visibleAll, setVisibleAll] = useState(true);

  const tooltipRef = useRef(null);
  const tooltipWrapRef = useRef(null);

  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
    });
  }, []);

  // 필터 검색할 때 검색 결과로 나온 리스트가 없으면 All 버튼 안 나오도록 처리
  useEffect(() => {
    setVisibleAll(filterData.length > 0);
  }, [onFilterInputChange]);

  useEffect(() => {
    resize();

    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  useEffect(() => {
    offset.width >= 1120 ? setIsMobile(false) : setIsMobile(true);
  }, [offset.width]);

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        tooltipWrapRef.current &&
        !tooltipWrapRef.current.contains(event.target) &&
        filterState
      ) {
        setFilterState(false);
      }
    },
    [filterState, tooltipRef],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className={cn("filter-container")} ref={tooltipWrapRef}>
      <div className={cn(`filter-tools`)}>
        <button
          onClick={() => {
            setFilterState(!filterState);
          }}
          className={cn(`button`)}
        >
          {isFiltering > 0 && isFiltering < filterData.length ? (
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_filter_on.svg`}
              alt="ico_filter_on"
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_URL}/assets/images/ico_filter.svg`}
              alt="ico_filter"
            />
          )}
          {isFiltering > 0 && isFiltering < filterData.length && (
            <span>{isFiltering}</span>
          )}
        </button>
        <p className={cn(`result`)}>
          <Trans
            i18nKey="waitInvest.voting.list.filter.count.text"
            components={[
              <span className={cn(`value`)}>
                <>{{ n: total }}</>
              </span>,
            ]}
          />
        </p>
      </div>
      <CSSTransition
        in={filterState}
        appear={true}
        timeout={{ exit: 400 }}
        unmountOnExit
        nodeRef={tooltipRef}
        classNames={{
          enterDone: cn("active"),
          exit: cn(""),
        }}
        onEnter={() => {
          if (isMobile) {
            document.body.classList.add("modal-open");
            document.body.setAttribute(
              "style",
              `overflow: hidden; position: fixed; top: 0; margin-top: -${
                window.pageYOffset
              }px; padding-right: ${
                window.innerWidth - document.documentElement.clientWidth
              }px;`,
            );
          }
        }}
        onEntered={() => {}}
        onExit={() => {
          document.body.classList.remove("modal-open");
          document.body.removeAttribute("style");
          // window.scrollTo(0, scrollTop);
        }}
      >
        <div className={cn("filter-area")} ref={tooltipRef}>
          <span className={cn("filter-input-wrap")}>
            <input
              type="text"
              placeholder={t("waitInvest.voting.list.filter.input.text")}
              onChange={(e) => onFilterInputChange(e)}
            />
          </span>
          <Checkbox
            items={filterData}
            value={defaultFilterData}
            onChangeValues={(v) => {
              onFilterChange(v);
            }}
            visibleAll={visibleAll}
          />
          {isMobile && (
            <button
              type="button"
              className={cn(`btn-apply`)}
              onClick={() => {
                setFilterState(false);
                onFilterChange(defaultFilterData);
              }}
            >
              {t("waitInvest.voting.list.filter.buttonText")}
            </button>
          )}
        </div>
      </CSSTransition>
      {filterState && (
        <span className={cn("bg")} onClick={() => setFilterState(false)}></span>
      )}
    </div>
  );
};

export default TableFilter;
