import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import { StakingModal, AccessFailedModal } from "./components";
import Loading from "./Loading";
import Header from "./components/Header";
import CommonFooter from "./components/common-footer/CommonFooter";
import { GovInitCtx } from "./contexts/GovernanceInitContext";

import "./App.css";
import "./components/style/style.css";
import "./assets/scss/common.scss";

import VotingList from "./pages/voting/list";
import VotingDetail from "./pages/voting/detail";
import Proposal from "./pages/voting/proposal";
import Rewards from "./pages/voting/rewards"; // [24.04.01] Rewards 메뉴 추가건
import Governance from "./pages/gov";
import WaitGovernance from "./pages/waitgov";
import Wait from "./pages/wait";
import WaitGovernanceDetail from "./pages/waitgov/detail";
import OGHeader from "./components/wait/OGHeader";
import { useAuth } from "./hooks/useAuth";
import { usePrevious } from "./hooks/usePrevious";
import { web3Instance } from "./web3";
import { ModalContext } from "./contexts/ModalContext";
import GovernanceHistory from "./pages/history";
import GovernanceDetail from "./pages/gov/detail";

const { Content } = Layout;

export const withLocation = (Component) => {
  return (props) => <Component {...props} location={useLocation()} />;
};

const AppFunc = () => {
  const { getErrModal } = useContext(ModalContext);
  const { isWeb3Loaded, isContractReady, accessFailMsg } =
    useContext(GovInitCtx);
  const {
    isMember,
    isStaker,
    stakerAddress,
    address,
    myBalance,
    lockedBalance,
    isLoggedIn,
    chain,
    onLogin,
    onLogout,
    updateAccountData,
    setStakingEventsWatch,
    getNCPStakingInfo,
    ncpInfo,
    showReward,
    isWhiteList,
  } = useAuth();
  const { pathname } = useLocation();
  const prev = usePrevious(chain);

  const _chainId = chain?.id;
  const _prevChainId = prev.current?.id;

  useEffect(() => {
    const isChanged = _chainId !== _prevChainId;
    if (!!_chainId && !!_prevChainId && isChanged) {
      onLogout();
    }
  }, [_chainId, _prevChainId]);

  useEffect(() => {
    let timeout;
    if (web3Instance && address) {
      updateAccountData(address);
      getNCPStakingInfo();
      if (pathname === "/rewards") {
        // 30초 마다 pendingAmount (claim 수량 업데이트)
        timeout = setInterval(() => {
          getNCPStakingInfo();
        }, 30000);
      }
    }
    return () => {
      clearInterval(timeout);
    };
  }, [web3Instance, address, pathname]);

  const [isShowStakingModal, setIsShowStakingModal] = useState(false);

  const getContent = () => {
    if (!isWeb3Loaded) return;

    switch (pathname) {
      case "/gov":
      case "/gov/":
        return <Governance />;
      case "/gov/detail":
      case "/gov/detail/":
        return <GovernanceDetail />;
      case "/waitgov":
      case "/waitgov/":
        return <WaitGovernance />;
      case "/waitgov/detail":
      case "/waitgov/detail/":
        return <WaitGovernanceDetail />;
      case "/history":
      case "/history/":
        return <GovernanceHistory />;
      case "/wait":
      case "/wait/":
        return <Wait />;
      case "/my-info":
      case "/voting/proposal":
      case "/my-info/":
      case "/voting/proposal/":
        return (
          // this.data.memberIdx &&
          // this.state.defaultAccount && ( // 2023.07.03 Proposal 화면의 코드에서 블록합니다.
          <Proposal
            getErrModal={getErrModal}
            defaultAccount={address}
            isLogin={isLoggedIn}
            isWhiteList={isWhiteList}
          />
          // )
        );
      case "/voting/detail":
      case "/voting/detail/":
        return (
          <VotingDetail
            isWhiteList={isWhiteList}
            defaultAccount={address}
            isLoggedIn={isLoggedIn}
            isMember={isMember}
            stakerAddress={stakerAddress}
          />
        );
      // * [24.04.01] Rewards 메뉴 추가건
      case "/rewards":
      case "/rewards/":
        return (
          <Rewards
            defaultAccount={address}
            isMember={isMember}
            isLoggedIn={isLoggedIn}
            ncpInfo={ncpInfo}
          />
        );
      case "/voting/list":
      case "/voting/list/":
      case "/app":
      case "/app/":
        return (
          <VotingList
            isWhiteList={isWhiteList}
            isMember={isMember}
            isStaker={isStaker}
            defaultAccount={address}
            getErrModal={getErrModal}
          />
        );
      default:
    }
    // this.setState({ selectedMenu: true });
  };

  const checkPath =
    pathname === "/wait" ||
    pathname === "/gov" ||
    pathname === "/waitgov" ||
    pathname === "/wait/" ||
    pathname === "/gov/" ||
    pathname === "/waitgov/" ||
    pathname === "/waitgov/detail" ||
    pathname === "/waitgov/detail/" ||
    pathname === "/gov/detail" ||
    pathname === "/gov/detail/" ||
    pathname === "/history" ||
    pathname === "/history/";

  return (
    <>
      {/* <Layout className="layout"> */}
      <AccessFailedModal visible={!!accessFailMsg} message={accessFailMsg} />
      {window.navigator.userAgent === "ReactSnap" ? (
        <>
          <OGHeader
            url={
              pathname.startsWith("/gov")
                ? "gov"
                : pathname.startsWith("/waitgov")
                ? "waitgov"
                : pathname.startsWith("/wait")
                ? "wait"
                : "default"
            }
          />
          <Loading />
        </>
      ) : isContractReady && isWeb3Loaded ? (
        checkPath ? (
          // landing 페이지 데이터 연동
          <>{getContent()}</>
        ) : (
          // VotingLayout에서 사용하는 class  voting-wrap이 header에서도 필요함. 추후 다시 확인해볼 것
          <div className="flex-column voting-wrap">
            {/* mobile gnb open 시 필요 */}
            {/* <Helmet htmlAttributes={{ lang: i18n.language }}>
            <body className={cn(this.state.isGnbOpen && "body-lock")} />
          </Helmet> */}
            <OGHeader />
            <Header
              isMember={isMember}
              isStaker={isStaker}
              isConnect={isLoggedIn}
              myLockedBalance={lockedBalance}
              myBalance={myBalance}
              defaultAccount={address}
              onLogin={onLogin}
              onLogout={onLogout}
              updateAccountData={updateAccountData}
              getStakingModal={() => setIsShowStakingModal(true)}
              showReward={showReward}
            />
            <StakingModal
              defaultAccount={address}
              isMember={isMember}
              accountBalance={{
                balance: myBalance,
                lockedBalance: lockedBalance,
              }}
              stakingModalVisible={isShowStakingModal}
              scrollType={false}
              setStakingEventsWatch={setStakingEventsWatch}
              setStakingModalVisible={setIsShowStakingModal}
              getErrModal={getErrModal}
            />
            <Content>
              {isWeb3Loaded ? (
                <div> {getContent()} </div>
              ) : (
                getErrModal(
                  "This is an unknown network. Please connect to WEMIX network",
                  "Connecting Error",
                )
              )}
            </Content>
            {/* <Footer /> */}
            <CommonFooter theme="light" language={false} />
          </div>
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default withLocation(AppFunc);
