import { getDataTimeout } from "./getData";

const PLATFORM_NAME = "40 WONDERS";

export const getPlatformList = async () => {
  try {
    const res = await getDataTimeout(
      `${process.env.REACT_APP_PLATFORM_API_URL}/eco/platforms`,
    );
    return res.data.map((raw) => ({
      id: raw.id,
      title: raw.name,
      link: raw.url,
      image: raw.imageUrl,
      current: raw.name === PLATFORM_NAME,
      lightImageUrl: raw.lightImageUrl,
      darkImageUrl: raw.darkImageUrl,
      groupName: raw.groupName,
      displayIndex: raw.displayIndex,
    }));
  } catch (e) {
    return [];
  }
};
