import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames/bind";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { throttle } from "lodash";
import { DisConnectWalletModal, ErrModal } from "./Modal";
import { web3Instance } from "../web3";

import { loginAcc } from "../util";
import Button from "./voting/Button";

const HeaderCopy = ({
  isMember,
  isStaker,
  isConnect,
  myLockedBalance,
  myBalance,
  onLogin,
  onLogout,
  getStakingModal,
  defaultAccount,
  showReward,
}) => {
  const [offset, setOffset] = useState({
    width: 0,
    height: 0,
  });
  const resize = useCallback(() => {
    setOffset({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  // ---------- wallet modal state start ----------
  const [isGnbOpen, setIsGnbOpen] = useState(false);

  const [disConnectView, setDisConnectView] = useState(false);
  const [errVisible, setErrVisible] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  // ---------- wallet modal state end ----------

  function resizeVh() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    resize();
    window.addEventListener("resize", throttle(resize, 200));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  const onClickToggle = () => setIsGnbOpen((prev) => !prev);
  // const onMenuClick = () => {};
  // ---------- wallet modal useEffect stsart ----------

  // check login data & auto login
  useEffect(() => {
    resizeVh();
    window.addEventListener("resize", resizeVh);
    return () => {
      window.removeEventListener("resize", resizeVh);
    };
  }, []);

  const onDisConnect = () => {
    setDisConnectView(false);
    onLogout();
  };

  const closeErrModal = () => {
    setErrVisible(false);
    setErrMsg("");
  };

  // ---------- wallet modal method end ----------

  return (
    <header className={cn("header")}>
      {offset.width >= 1120 ? (
        <>
          <div className="header-logo-wrap">
            <HeaderLogo />
            {/* <Menu
              className={cn("header-gnb", isConnect && isMember && "connect")}
            >
              {menuComponent}
            </Menu> */}
            <HeaderMenu
              isConnect={isConnect}
              isMember={isMember}
              isStaker={isStaker}
              setIsGnbOpen={setIsGnbOpen}
              showReward={showReward}
            />
          </div>
          <div className={cn("header-utils")}>
            <div className={cn("header-my-info")}>
              {isConnect ? (
                <>
                  <dl>
                    <div>
                      <dt>Locked</dt>
                      <dd>{myLockedBalance} WEMIX </dd>
                    </div>
                    <div>
                      <dt>Staked</dt>
                      <dd>{myBalance} WEMIX </dd>
                    </div>
                  </dl>
                  <div className="btns-wrap">
                    <Button
                      text="WEMIX Staking"
                      type="outline"
                      size="sm"
                      onClick={getStakingModal}
                    />
                    {/* 2023.03.02 해당 버튼이 삭제된 기획안 => 버튼이 없으면 로그아웃을 못함. 확인 후 삭제 */}
                    {/* 2022.12.02 수정: 커넥트 시 지갑 버튼 노출 */}
                    <Button
                      type="outline"
                      size="sm"
                      text={defaultAccount && loginAcc(defaultAccount)}
                      onClick={() => setDisConnectView(true)}
                    />
                  </div>
                </>
              ) : (
                <Button
                  type="outline"
                  size="sm"
                  text="Connect Wallet"
                  icon
                  onClick={onLogin}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        // mobile toggle open
        <>
          <div className="header-logo-wrap">
            <HeaderLogo />
            <div className={cn("mobile-gnb", isGnbOpen && "show")}>
              <div className={cn("gnb-inner")}>
                <button onClick={onClickToggle} className={cn("btn-close")}>
                  <img
                    src={`${process.env.REACT_APP_URL}/assets/images/ico_close.svg`}
                    alt="ico_close"
                  />
                </button>
                <div className={cn("header-content")}>
                  {/* <Menu
                    className={cn(
                      "header-gnb",
                      isConnect && isMember && "connect",
                    )}
                  >
                    {menuComponent}
                  </Menu> */}
                  <HeaderMenu
                    isConnect={isConnect}
                    isMember={isMember}
                    isStaker={isStaker}
                    setIsGnbOpen={setIsGnbOpen}
                  />
                  <div className={cn("header-my-info")}>
                    {isConnect && (
                      <dl>
                        <div>
                          <dt>Locked</dt>
                          <dd>{myLockedBalance} WEMIX</dd>
                        </div>
                        <div>
                          <dt>Staked</dt>
                          <dd>{myBalance} WEMIX</dd>
                        </div>
                      </dl>
                    )}
                    {isConnect ? (
                      <div className="btns-wrap">
                        <Button
                          text="WEMIX Staking"
                          type="outline"
                          size="sm"
                          onClick={getStakingModal}
                        />

                        <Button
                          type="outline"
                          size="sm"
                          text={defaultAccount && loginAcc(defaultAccount)}
                          onClick={() => setDisConnectView(true)}
                        />
                      </div>
                    ) : (
                      <Button
                        type="outline"
                        size="sm"
                        text="Connect Wallet"
                        onClick={onLogin}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-utils">
            <button onClick={onClickToggle}>
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_menu.svg`}
                alt="ico_menu"
              />
            </button>
          </div>
        </>
      )}
      <DisConnectWalletModal
        onDisConnect={onDisConnect}
        visible={disConnectView}
        setDisConnectView={setDisConnectView}
      />
      <ErrModal
        netName={web3Instance.netName}
        title="Error"
        err={errMsg}
        visible={errVisible}
        coloseErrModal={closeErrModal}
      />
    </header>
  );
};

// TODO:  path는 상황에 맞게 넣어주세요. voting, myinfo 페이지에 layout 내용 넣을 때 props로 activate="voting" or activate="my-info" 이런식으로 메뉴 키값에 맞게 넣어주세요.
const menuList = [
  // [24.04.01] Rewards 메뉴 추가건
  {
    title: "Rewards",
    path: "/rewards",
    key: "rewards",
    onlyMember: false,
  },
  {
    title: "Voting",
    path: "/voting/list",
    key: "voting",
    onlyMember: false,
  },
  {
    title: "My Info",
    path: "/my-info",
    key: "my-info",
    onlyMember: true,
  },
];

// const MenuComponent = () => {
//   const location = useLocation();
//   const pathName = `/${location.pathname.split("/")[1]}`;
//   return menuList.map((menu) => (
//     <li key={menu.title} className={cn(menu.path === pathName && "active")}>
//       {/* 23.06.20 수정: 모바일 gnb 클릭 시 body lock 해제를 위한 클릭 이벤트 추가 */}
//       <Link
//         to={menu.path}
//         onClick={() => {
//           if (
//             window.innerWidth < 1120 &&
//             document.body.classList.contains("modal-open")
//           ) {
//             document.body.classList.remove("modal-open");
//             document.body.removeAttribute("style");
//           }
//         }}
//       >
//         {menu.title}
//       </Link>
//     </li>
//   ));
// };

const HeaderMenu = ({
  isConnect,
  isMember,
  isStaker,
  setIsGnbOpen,
  showReward,
}) => {
  const location = useLocation();
  // console.log("location.pathname", location.pathname);
  const pathName = `/${location.pathname.split("/")[1]}`;

  // useEffect(() => {
  //   console.log("location.pathname", location.pathname);
  //   console.log(
  //     "/${location.pathname.split('/')[1]}",
  //     location.pathname.split("/")[1],
  //   );
  // }, [location]);

  const onMenuClick = (pathName) => {
    // menu.path === pathName;

    window.localStorage.removeItem("selectedTopic");
    setIsGnbOpen(false);
  };

  const menuComponent = menuList.map((menu) => {
    return menu.onlyMember ? (
      isStaker && ( // voting address도 isMember 값이 true로 내려오기 때문에 staker로 NCP 확인
        <Menu.Item
          key={menu.title}
          className={cn(`/${menu.key}` === pathName && "active")}
        >
          <Link to={menu.path} onClick={() => onMenuClick(pathName)}>
            {menu.title}
          </Link>
        </Menu.Item>
      )
    ) : menu.key === "rewards" ? (
      showReward && (
        <Menu.Item
          key={menu.title}
          className={cn(`/${menu.key}` === pathName && "active")}
        >
          <Link to={menu.path} onClick={() => onMenuClick(pathName)}>
            {menu.title}
          </Link>
        </Menu.Item>
      )
    ) : (
      <Menu.Item
        key={menu.title}
        className={cn(`/${menu.key}` === pathName && "active")}
      >
        <Link to={menu.path} onClick={() => onMenuClick(pathName)}>
          {menu.title}
        </Link>
      </Menu.Item>
    );
  });

  return (
    <Menu className={cn("header-gnb", isConnect && isMember && "connect")}>
      {menuComponent}
    </Menu>
  );
};

export const HeaderLogo = () => {
  return (
    <h1 className={cn("header-logo")}>
      <Link to="/">
        <span className={cn("logo-typo-wemix")}>
          <span className={cn("a11y")}>WEMIX</span>
        </span>
        <span className={cn("logo-symbol")}>
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/logo_symbol_governance.svg`}
            alt="logo_symbol_governance"
          />
        </span>
        <span className={cn("logo-typo-governance")}>
          <span className={cn("a11y")}>GOVERNANCE</span>
        </span>
      </Link>
    </h1>
  );
};

// const MobileHeaderMenu = ({ isConnect, myLockedBalance, myBalance }) => {
//   const [isVotingModal, setIsVotingModal] = useState(false);

//   const filterData = ["Deposit Staking", "3days", "4days", "5days"];
//   const [inputValue, setInputValue] = useState("");
//   const [inputValue2, setInputValue2] = useState("");

//   return (
//     <div className={cn("header-my-info")}>
//       {isConnect && (
//         <dl>
//         <div>
//           <dt>Locked</dt>
//           <dd>{myLockedBalance} WEMIX</dd>
//         </div>
//         <div>
//           <dt>Staked</dt>
//           <dd>{myBalance} WEMIX</dd>
//         </div>
//       </dl>
//       )}
//       <Button
//         text="WEMIX Staking"
//         type="outline"
//         size="sm"
//         onClick={() => {
//           setIsVotingModal(true);
//         }}
//       />

//       {/* staking modal */}
//       <VotingModal
//         visible={isVotingModal}
//         isVotingModal={setIsVotingModal}
//         btn={{ btnName: "Submit", cancel: true }}
//         scrollType={false}
//         title="WEMIX Staking"
//         // subTitle='subtitle 영역 입니다.'
//       >
//         <div className={cn("staking-wrap")}>
//           <VotingSelect filterData={filterData} />
//           <VotingInputArea
//             inputType={"suffix"}
//             placeholder={"input text | Suffix"}
//             value={inputValue}
//             onChange={setInputValue}
//             type={"default"}
//           />
//           <VotingInputArea
//             inputType={"default"}
//             placeholder={"WIMIX amount"}
//             value={inputValue2}
//             onChange={setInputValue2}
//             type={"default"}
//           />
//         </div>

//         <div className={cn("sub-info")}>
//           <strong className={cn("sub-title")}>Staked 5 WEMIX</strong>
//           <p className={cn("sub-info-detail")}>(Locked 1 WEMIX)</p>
//         </div>
//       </VotingModal>
//     </div>
//   );
// };

export default HeaderCopy;
