import React, { useState } from "react";

import cn from "classnames/bind";

export default function VotingSearch({ setIsFilter, searchBallot, onClose }) {
  const [isNonACtive, setIsNonACtive] = useState("");
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className={cn("voting-search", isNonACtive)}>
      <span className={cn("search-text-area", isNonACtive)}>
        <input
          name="authMemSkAmountMax"
          placeholder="Search..."
          className={cn("search-input")}
          onChange={(e) => {
            searchBallot(e);
            setSearchValue(e.target.value);
          }}
          value={searchValue}
        ></input>

        <button
          type="button"
          className={cn("search-close-btn")}
          onClick={() => {
            setIsFilter(false);
            setIsNonACtive("");
            setSearchValue("");
            onClose();
          }}
        >
          <img
            src={`${process.env.REACT_APP_URL}/assets/images/ico_popup_close.svg`}
            alt="ico_popup_close"
          />
        </button>
      </span>

      <button
        type="button"
        className={cn("voting-search-btn")}
        onClick={() => {
          setIsNonACtive("active");
          setIsFilter(true);
        }}
      >
        <span className={cn("a11y")}>검색 버튼</span>
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_search.svg`}
          alt="ico_search"
        />
      </button>
    </div>
  );
}
