import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import style from "../../assets/scss/governance.module.scss";
import edStyle from "../../assets/scss/editor.module.scss";

import LandingLayout from "../../components/LandingLayout.jsx";
import WondersVoteBoard from "../../components/wait/WondersVoteBoard.jsx";
import {
  DetailTopLayout,
  DetailContentLayout,
} from "../../components/wait/WaitDetailLayout.jsx";
import WaitVotingStatus from "../../components/wait/WaitVotingStatus.jsx";
import VotingStickChart from "../../components/voting/VotingStickChart.jsx";
import OGHeader from "../../components/wait/OGHeader.jsx";
import { message } from "antd";
import {
  asyncExecuteBatch,
  refineMemo,
  refineProposalCount,
  refineProposalTitle,
  timeConverter,
} from "../../util.js";
import {
  NETWORK,
  VOTING_DURATION_SETTING,
  constants,
} from "../../constants.js";
import { GovInitCtx } from "../../contexts/GovernanceInitContext.jsx";
import { callBatchMethod, web3Instance } from "../../web3.js";
import { LandingDataStateContext } from "../../contexts/LandingDataContext.jsx";
import Loading from "../../Loading.jsx";

const cn = classNames.bind(style);

const GovernanceDetail = () => {
  const { t } = useTranslation();

  const { data, getNodeByTimestamp } = useContext(GovInitCtx);
  const { ballotBasicOriginData, proposalMemberLength } = data;

  const { search } = useLocation();
  const navigate = useNavigate();

  const [basicData, setBasicData] = useState({});
  const [nodeList, setNodeList] = useState([]);
  const [voteInfo, setVoteInfo] = useState([]); // 컨트랙트에서 가져온 투표 리스트
  const [vote, setVote] = useState({ yes: [], no: [] }); // board에 뿌릴 voter 리스트

  const { isLandingDataReady, isLandingDataError, addressNcpInfo } = useContext(
    LandingDataStateContext,
  );

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    const regex = /^[^0]\d*$/;
    if (id === null || !regex.test(id)) {
      openToast("Invalid Request.");
      navigate("/gov");
      return;
    }
    window.scrollTo(0, 0);
    if (isLandingDataReady) {
      getGovernanceData(id, addressNcpInfo);
    }
  }, [search, navigate, isLandingDataReady]);

  useEffect(() => {
    if (basicData && voteInfo) {
      // batch로 가져온 투표 리스트가 있으면 로직 실행
      if (voteInfo.length) {
        voteInfo.map(async (info) => {
          refineVoteInfo(info);
        });
      }
    }
  }, [basicData, voteInfo]);

  const openToast = (content) => {
    message.destroy();
    message.open({
      type: "warning",
      content,
      className: "voting-toast",
    });
  };

  const getGovernanceData = async (id, addressNcpInfo) => {
    const data = Object.values(ballotBasicOriginData).filter(
      (basic) => basic.id === parseInt(id),
    )[0];
    if (!data) {
      openToast("Invalid Governance Id.");
      navigate("/gov");
      return;
    }
    // revoke 된 투표일 경우 리스트로 이동
    if (data.state === "5") {
      openToast("This proposal has been cancelled.");
      navigate("/gov");
      return;
    }

    // 안건 제목 재정의
    let title = "";
    const { startTime, ballotType, state, memo } = data;

    // getNodeTimestamp 시 timestamp로 localStorage에서 저장된 값을 가져오도록 수정헀기 때문에 new Date() 값은 함수 내부엥서 처리
    const date = startTime > 0 ? startTime : 0; //parseInt(new Date().getTime() / 1000); 투표 준비 상태일 경우 현재 시간으로 처리
    const nodeList = await getNodeByTimestamp(date, addressNcpInfo);
    setNodeList(nodeList);

    try {
      const brDetail = new web3Instance.web3.BatchRequest();
      brDetail.add(
        callBatchMethod(web3Instance, "GovGatewayImp", "getBallotMember", id),
      );
      brDetail.add(
        callBatchMethod(web3Instance, "GovGatewayImp", "getVoteList", id),
      );
      const [memberData, voteInfo] = await asyncExecuteBatch(brDetail);
      setVoteInfo(voteInfo);

      // 현재 memo에 proposalTitle을 입력받고 있는 상태
      title = refineMemo(memo, "proposalTitle");
      // memo에 입력한 title이 없으면 기존 로직 (default)으로 뿌림
      if (title.replace(/(\s*)/g, "") === "") {
        // replace authority member 일 때는 newNodeName을 타이틀에 보여줌
        if (ballotType === "3") {
          const { newNodeName, oldStakerAddress, newStakerAddress } =
            memberData;
          title = refineProposalTitle(
            ballotType,
            newNodeName,
            newStakerAddress === "0x0000000000000000000000000000000000000000"
              ? oldStakerAddress
              : newStakerAddress,
            oldStakerAddress,
          );
        } else {
          title = refineProposalTitle(ballotType);
        }
      }

      let readyTempData;
      const startTimeConverted = timeConverter(
        refineMemo(memo, "proposalStartTime"),
        false,
        true,
        false,
        true,
      );
      const endTimeConverted = timeConverter(
        refineMemo(memo, "proposalEndTime"),
        false,
        true,
        false,
        true,
      );
      if (state === constants.ballotState.Ready) {
        readyTempData = {
          startTimeConverted,
          endTimeConverted,
        };
      }

      const proposalCount =
        refineMemo(memo, "proposalNumber") || refineProposalCount(id);
      let basicData = {
        ...data,
        title,
        proposalCount,
        stateType:
          state === constants.ballotState.InProgress ||
          (state === constants.ballotState.Ready &&
            new Date(startTimeConverted) <= new Date() &&
            new Date(endTimeConverted) >= new Date())
            ? "InProgress"
            : constants.ballotStateArr[state],
      };

      // ! state ready 상태일 경우 inprogress 로 수정 (투표 시간도 수정)
      // ! inprogress 상태일 때도 하드 코딩 시간으로 뿌림
      if (NETWORK === "mainnet" && VOTING_DURATION_SETTING[id] !== undefined) {
        if (
          state === constants.ballotState.Ready ||
          state === constants.ballotState.InProgress
        ) {
          const { startTime, startTimeConverted, endTime, endTimeConverted } =
            VOTING_DURATION_SETTING[id];
          readyTempData = {
            state: constants.ballotState.InProgress,
            stateType: "InProgress",
            startTime,
            startTimeConverted,
            endTime,
            endTimeConverted,
          };
        }
      }
      basicData = {
        ...basicData,
        ...readyTempData,
      };

      setBasicData(basicData);
    } catch (e) {
      openToast("There is no information. Please Check Governance Id");
      navigate("/gov");
      return;
    }
  };

  // 투표 정보 재정의
  const refineVoteInfo = (info) => {
    const { voter, decision } = info;
    // 해당 안건의 투표 (yes/no) 리스트에 멤버 번호를 push
    const type = decision === "1" ? "yes" : "no";

    // ncp의 투표 정보를 저장
    setVote((prev) => {
      let vote = prev;
      // 중복 렌더링 방지
      if (vote[type].length && vote[type].filter((v) => v === voter).length)
        return prev;

      vote[type].push(voter);
      return vote;
    });
  };

  return (
    <LandingLayout>
      {/* 23.04.28 수정: OG 추가 */}
      <OGHeader url="gov" />
      <h1 className={cn("a11y")}>WEMIX Governance</h1>
      <DetailTopLayout theme="dark" prev={true}>
        <div className={cn("title-wrap")}>
          <h2 className={cn("title")}>
            {/* 24.01.09 수정: 안건 넘버링과 실제 타이틀을 분리 */}
            <span>{basicData.proposalCount}</span>
            <strong>{basicData.title}</strong>
          </h2>
          <dl className={cn("top-info")}>
            <div className={cn("proposer-cell")}>
              <dt>{t("votingDetail.header.proposer")}</dt>
              <dd>
                <strong className={cn("cell-title")}>
                  {addressNcpInfo[basicData.creator]?.name || "WEMIX PTE. LTD."}
                </strong>
                <span className={cn("cell-cont")}>{basicData.creator}</span>
              </dd>
            </div>
          </dl>
        </div>
      </DetailTopLayout>
      <DetailContentLayout theme="dark">
        {isLandingDataError && (
          <span style={{ fontSize: "12px", color: "#FFF" }}>
            {t("common#errorMassage")}
          </span>
        )}
        <div className={cn("board-title-wrap")}>
          {voteInfo?.length ? (
            <h3 className={cn("board-title")}>
              {t("votingDetail.votingBoard.title")} (
              {`${vote.yes.length + vote.no.length}/${nodeList.length}`})
            </h3>
          ) : (
            <></>
          )}
          {basicData.stateType && (
            <WaitVotingStatus status={basicData.stateType.toLowerCase()} />
          )}
        </div>
        {basicData.state && nodeList && vote && (
          <WondersVoteBoard
            state={basicData.stateType}
            allList={nodeList}
            voteData={vote}
          />
        )}
        <div className={cn("vote-content")}>
          <div className={cn("block-wrap")}>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.description.title")}
                </h4>
                <div
                  className={`${cn("block-cont", "editor")} ${
                    edStyle["editor-wrap"]
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: refineMemo(basicData.memo)
                      ?.replace("1. Proposal:", "<u><b>1. Proposal:</b></u>")
                      .replace(
                        "2. Node Council Partner Applicant Introduction:",
                        "<u><b>2. Node Council Partner Applicant Introduction:</b></u>",
                      )
                      .replace("Proposal:", "<u><b>Proposal:</b></u>")
                      .replace(
                        "Node Council Partner Applicant Introduction:",
                        "<u><b>Node Council Partner Applicant Introduction:</b></u>",
                      )
                      .replace(
                        "2. Background and Purpose",
                        "<u><b>2. Background and Purpose</b></u>",
                      )
                      .replace(
                        "2. Proposal Background:",
                        "<u><b>2. Proposal Background:</b></u>",
                      )
                      .replace(
                        "3. Changes and Expected Results",
                        "<u><b>3. Changes and Expected Results</b></u>",
                      )
                      .replace(
                        "3. Proposal Details:",
                        "<u><b>3. Proposal Details:</b></u>",
                      )
                      .replace(
                        "4. Schedule (UTC+9)",
                        "<u><b>4. Schedule (UTC+9)</b></u>",
                      )
                      .replace(
                        "5. Contract Security",
                        "<u><b>5. Contract Security</b></u>",
                      )
                      .replace("      3-1", "3-1")
                      .replace("      3-2", "3-2")
                      .replace("     *If", "*If"),
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className={cn("block-wrap")}>
            <div className={cn("block")}>
              <div className={cn("block-inner")}>
                <h4 className={cn("block-title")}>
                  {t("votingDetail.votingStatus.title")}
                </h4>
                <div className={cn("block-cont")}>
                  {basicData && (
                    <>
                      <VotingStickChart
                        title={t("votingDetail.votingStatus.yes")}
                        percent={
                          parseInt(basicData.state) > 1
                            ? basicData.powerOfAccepts
                            : 0
                        }
                        count={
                          parseInt(basicData.state) > 1 ? vote.yes.length : 0
                        }
                        theme="dark"
                      />
                      <VotingStickChart
                        title={t("votingDetail.votingStatus.no")}
                        percent={
                          parseInt(basicData.state) > 1
                            ? basicData.powerOfRejects
                            : 0
                        }
                        count={
                          parseInt(basicData.state) > 1 ? vote.no.length : 0
                        }
                        type="no-type"
                        theme="dark"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {basicData && (
              <div className={cn("block")}>
                <div className={cn("block-inner")}>
                  <h4 className={cn("block-title")}>
                    {t("votingDetail.votingPeriod.title")}
                  </h4>
                  <div className={cn("block-cont")}>
                    <dl className={cn("date-wrap")}>
                      <div>
                        <dt>{t("votingDetail.votingPeriod.start")}</dt>
                        <dd>
                          {parseInt(basicData.state) > 1
                            ? timeConverter(basicData.startTime, true, true)
                            : basicData.startTimeConverted?.length
                            ? basicData.startTimeConverted
                            : t("votingDetail.votingPeriod.ready")}
                        </dd>
                      </div>
                      <div>
                        <dt>{t("votingDetail.votingPeriod.end")}</dt>
                        <dd>
                          {parseInt(basicData.state) > 1
                            ? timeConverter(basicData.endTime, true, true)
                            : basicData.endTimeConverted?.length
                            ? basicData.endTimeConverted
                            : t("votingDetail.votingPeriod.ready")}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DetailContentLayout>
    </LandingLayout>
  );
};

export default GovernanceDetail;
