import React from "react";
import { Select } from "antd";
import cn from "classnames/bind";

export default function VotingSelect({
  filterData,
  className,
  dropdownClassName = "voting",
  handleSelect,
}) {
  const Option = Select.Option;
  return (
    <Select
      defaultValue={filterData[0]}
      className={cn("voting-filter", className)}
      dropdownClassName={dropdownClassName}
      suffixIcon={
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_select_arrow_drop_down.svg`}
          alt="ico_select_arrow_drop_down"
        />
      }
      onChange={(e) => handleSelect(e)}
    >
      {filterData.map((data, index) => (
        <Option value={data} key={index}>
          {data}
        </Option>
      ))}
    </Select>
  );
}
