import React from "react";
import { Pagination } from "antd";
import classNames from "classnames/bind";
import style from "./TablePagination.module.scss";

const cn = classNames.bind(style);

const TablePagination = (props) => {
  const { className, ...rest } = props;
  const lastPage = Math.ceil(props.total / props.defaultPageSize);

  const prevNextItemRender = (_, type, element) => {
    if (type === "prev") {
      return (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_bottom.svg`}
          alt="ico_arrow_bottom"
        />
      );
    }
    if (type === "next") {
      return (
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_bottom.svg`}
          alt="ico_arrow_bottom"
        />
      );
    }
    return element;
  };

  return (
    <div className={cn(`pagination-container`, className)}>
      <button
        className={cn(`btn-first`, { disabled: props.activate === 1 })}
        disabled={props.activate === 1}
        onClick={() => props.pageChangeActive(1)}
      >
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_jump.svg`}
          alt="ico_arrow_jump"
        />
      </button>
      <Pagination
        {...rest}
        className={cn("wrap")}
        defaultCurrent={1}
        current={props.activate}
        itemRender={prevNextItemRender}
        simple
        onChange={(current) => {
          props.pageChangeActive(current);
        }}
      />
      <button
        className={cn(`btn-last`, { disabled: props.activate === lastPage })}
        disabled={props.activate === lastPage}
        onClick={() => props.pageChangeActive(lastPage)}
      >
        <img
          src={`${process.env.REACT_APP_URL}/assets/images/ico_arrow_jump.svg`}
          alt="ico_arrow_jump"
        />
      </button>
    </div>
  );
};

export default TablePagination;
