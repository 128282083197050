import React, { useCallback } from "react";
import cn from "classnames/bind";
/**
 * 
 * props data 형태: 배열 받을 수 있게 작업 되어 있음
  [
    {
      icon: 'time' | 'person'
      text: string
      children: ReactNode
    }
  ]
 */

/* 23.04.20 수정: 테마 추가 props data 에 children 추가 */
const IconWithText = ({ data, theme = "light" }) => {
  const svgIcon = useCallback(
    (icon) => {
      if (theme === "light") {
        switch (icon) {
          case "time":
            return (
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_time.svg`}
                alt="ico_time"
              />
            );
          case "person":
            return (
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_person.svg`}
                alt="ico_person"
              />
            );
          default:
            return false;
        }
      } else {
        switch (icon) {
          case "time":
            return (
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_time_dark.svg`}
                alt="ico_time_dark"
              />
            );
          case "person":
            return (
              <img
                src={`${process.env.REACT_APP_URL}/assets/images/ico_person_dark.svg`}
                alt="ico_person_dark"
              />
            );
          default:
            return false;
        }
      }
    },
    [theme],
  );
  return (
    <div className={cn("icon-with-text-wrap", `${theme}`)}>
      {data.map((el, index) => {
        return (
          <div className={cn("icon-with-text")} key={`${el.text}-${index}`}>
            {svgIcon(el.icon)}
            <span>{el.text}</span>
            {el.children && el.children}
          </div>
        );
      })}
    </div>
  );
};

export default IconWithText;
